import React, { Component } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { OpenVidu } from 'openvidu-browser';
import { getWithToken, postStream } from '../../variables/apiCall';
import { getTimeDiff, getTodayDate, getUrl } from '../../Helper';
import OpenViduVideoComponent from './OvVideo';
import Loader from '../Loader';
import swal from 'sweetalert';
import ShowAdsModal from '../modal/ShowAdsModal';

class ShowStream extends Component {
    constructor(props) {
        super(props);

        // These properties are in the state's component in order to re-render the HTML whenever their values change
        this.state = {
            loading: false,
            mySessionId: this.props.str.id + '',
            session: undefined,
            subscriber: undefined,
            ads: [],
            playAds: 0,
            currAd: null,
            show: false,
        };
        this.startAd = this.startAd.bind(this);
        this.joinSession = this.joinSession.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.onbeforeunload = this.onbeforeunload.bind(this);
    }
    componentDidMount() {
        window.addEventListener('beforeunload', this.onbeforeunload);
    }

    componentDidUpdate() {
        var { str } = this.props;
        if (str.advertisement.length && str.advertisement !== this.state.ads) {
            this.setState({ ads: str.advertisement });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onbeforeunload);
    }

    onbeforeunload(event) {
        this.leaveSession();
    }

    async joinSession() {
        var res = await getWithToken("/api/stream_status_detail/" + this.props.str.id, this.props.token);
        if (res.status === 200 && res.stream_status === 1) {
            this.OV = new OpenVidu();
            this.setState(
                {
                    loading: true,
                    session: this.OV.initSession(),
                },
                () => {
                    var mySession = this.state.session;

                    // --- 3) Specify the actions when events take place in the session ---

                    // On every new Stream received...
                    mySession.on('streamCreated', (event) => {
                        // Subscribe to the Stream to receive it. Second parameter is undefined
                        // so OpenVidu doesn't create an HTML video by its own
                        var subscriber = mySession.subscribe(event.stream, undefined);
                        // Update the state with the new subscribers
                        this.setState({
                            loading: false,
                            subscriber: subscriber
                        });
                    });
                    mySession.on('reconnecting', () => console.warn('Oops! Trying to reconnect to the session', "subsni"));
                    mySession.on('reconnected', () => console.log('Hurray! You successfully reconnected to the session', "subsni"));
                    mySession.on('sessionDisconnected', (event) => {
                        if (event.reason === 'networkDisconnect') {
                            console.warn('Dang-it... You lost your connection to the session', "subsni");
                        } else {
                            // Disconnected from the session for other reason than a network drop
                            this.leaveSession();
                        }
                    });
                    // On every Stream destroyed...
                    mySession.on('streamDestroyed', (event) => {
                        if (event.reason === "unpublish") {
                            this.setState({
                                subscriber: undefined
                            });
                            this.startAd();
                        } else if (event.reason === "disconnect") {
                            this.leaveSession();
                        } else {
                            console.error(event.reason, "subsni");
                        }
                        // Remove the stream from 'subscribers' array
                    });
                    // On every asynchronous exception...
                    mySession.on('exception', (exception) => {
                        console.warn(exception, "subsni");
                    });
                    this.getToken().then((token) => {
                        mySession.connect(token, { clientData: this.props.user.name })
                            .then(async () => {
                                this.setState({
                                    loading: false
                                });
                            })
                            .catch((error) => {
                                console.log('There was an error connecting to the session:', error.code, error.message, "subsni");
                            });
                    });
                },
            );
        } else {
            swal("Oops...", "Stream is not started yet. please try again after some time.", "info")
        }
    }
    startAd() {
        if (this.state.ads.length > this.state.playAds) {
            var ad = this.state.ads[this.state.playAds];
            this.setState({
                currAd: ad,
                show: true
            })
        }
    }
    leaveSession() {
        this.OV = null;
        this.setState({
            loading: false,
            session: undefined,
            subscriber: undefined,
        });
    }
    getJoinButton() {
        var { str } = this.props;
        var date = getTodayDate();
        var time = getTimeDiff(str.time);
        var strBtn = false;
        if (date === str.date && time && time < 100) {
            strBtn = true;
        }
        return strBtn;
    }
    handleClose = () => {
        this.setState({ show: false });
    }
    render() {
        const { str } = this.props;
        const { session, subscriber, currAd, loading, show } = this.state;
        var data = [currAd];
        if (loading) {
            return <Loader />
        }
        return (
            <>
                {session === undefined ? (
                    <div className="playvideo mx-15">
                        <video autoPlay={true} controls poster={getUrl(str.trailer_thumbnail_url)}>
                            <source src={getUrl(str.trailer_video_url)} />
                        </video>
                    </div>
                ) : null}

                {session !== undefined && subscriber !== undefined && (
                    <div id="main-video" className="playvideo mx-15">
                        <div className="streamcomponent text-center">
                            <OpenViduVideoComponent streamManager={subscriber} />
                        </div>
                    </div>
                )}
                {session !== undefined && subscriber === undefined && (
                    <div id="main-video" className="playvideo mx-15">
                        <div className="streamcomponent text-center">
                            <Image width={500} src={getUrl(str.trailer_thumbnail_url)} alt={"thumbnail"} />
                        </div>
                    </div>
                )}
                <div className="startstream ">
                    <Container fluid>
                        {session === undefined && this.getJoinButton() &&
                            <Button className="starStrem mt-5" onClick={() => this.joinSession()}>{"Join Stream"}</Button>}
                    </Container>
                </div>
                <div className="watchmovie-name mx-15">
                    <Container fluid>
                        <div className="watchmovie-heading">
                            <h2 variant="primary">{str.title}</h2>
                            <p>{str.tagline}</p>
                        </div>
                        <div className="watchmovie-heading">
                            <h5 className="mb-2">Description</h5>
                            <Row>
                                <Col md={8}>
                                    <p>{str.description}</p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <ShowAdsModal show={show} data={data} handleClose={() => this.handleClose()} />
            </>
        );
    }
    async getToken() {
        var sessionId = this.state.mySessionId;
        return await this.createToken(sessionId);
    }

    async createToken(sessionId) {
        const response = await postStream('sessions/' + sessionId + '/connection', {})
        return response.token; // The token
    }
}

export default ShowStream;