
import React from 'react'
import { get } from "../../variables/apiCall";
import { Skeleton } from "@mui/material";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useState } from 'react';
import useWindowDimensions from '../../variables/useWindowDimensions';
import { useEffect } from 'react';

export default function ViewerSlider() {
    const [loading, setLoading] = useState(false);
    let { height } = useWindowDimensions();
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            var res = await get("/api/banner_list");
            setLoading(false)
            if (res.status === 200) {
                setData(res.data);
            }
        }
        fetchData();
    }, []);
    if (!loading && data.length === 0) {
        return null;
    }

    return (
        <>
            {data.length !== 0 ?
                <div className="banner-slider">
                    {data && data.map(el => {
                        return (
                            <OwlCarousel className='viewer-slider' nav={true} items={1} margin={0} autoplay={true} loop={false}>
                                <div className='innetsliderview' style={{ backgroundImage: 'url(' + el.media_base_url + '/' + el.bannerData.main_image + ')' }}></div>
                                <div className='innetsliderview' style={{ backgroundImage: 'url(' + el.media_base_url + '/' + el.bannerData.main_image + ')' }}></div>
                            </OwlCarousel>

                        )
                    })}
                </div>
                : <Skeleton variant="rectangular" width={"100%"} height={(.50 * height)} style={{ backgroundColor: "#ffffff17" }} />
            }
        </>
    )
}
