import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { get } from "../../variables/apiCall";
import { useAuth } from "../../auth/Auth";
import useWindowDimensions from "../../variables/useWindowDimensions";
import { Skeleton } from "@mui/material";
import Slider from "../Slider";

function TvShows() {
    const [loading, setLoading] = useState(false);
    let { height } = useWindowDimensions();
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const auth = useAuth();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            var res = await get("/api/viewer/video_library?item_type=tv_show");
            setLoading(false)
            if (res.status === 200) {
                setData(res.data);
            }
        }
        fetchData();
    }, []);
    const clickHandler = (id, e) => {
        e.preventDefault();
        if (auth.id) {
            navigate("/view/watch", { state: { vid: id } });
        } else {
            navigate("/auth/signin")
        }
    }
    if (!loading && data.length === 0) {
        return null;
    }
    return (
        <div className="section-slider">
            <Container fluid>
                <div className="section-heading">
                    <h5>Tv Shows</h5>
                    <Link to="/all/tv_show">See All
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </div>
                <div className="list">
                    {data.length !== 0 ?

                        <Slider data={data} fun={clickHandler} />
                        : <Skeleton variant="rectangular" width={"100%"} height={(.25 * height)} style={{ backgroundColor: "#ffffff17" }} />}
                </div>
            </Container>
        </div>
    );
}

export default TvShows;