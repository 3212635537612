import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AdvertiserFooter from "../components/Footer/advertiser_footer";
import AdvertiserHeader from "../components/Header/advertiser_header";
import Sidebar from "../components/Sidebar/sidebar";
import dashRoutes from "../routes";
import { useState } from "react";
import { RequireAuth } from "../auth/RequireAuth";
import { useAuth } from "../auth/Auth";
import Loader from "../components/Loader";
import Error404 from "../components/Errors/Error404";

export default function Advertiser() {
    const auth = useAuth();
    const { pathname } = useLocation();
    let user = auth.user;
    const getRoutes = (routes) => {
        if (user === null) {
            return <Route path="*" element={<Loader />} />
        }
        if (user.user_type === "2") {
            return routes.map((prop, key) => {
                if (prop.layout === "/adv") {
                    return (
                        <Route
                            path={prop.path}
                            element={prop.component}
                            key={key}
                        />
                    );
                }
            });
        } else {
            return <Route path="*" element={<Error404 />} />
        }
    };
    const [toggle, setToggle] = useState(false);
    const showSideBar = (e) => {
        var value = !toggle;
        setToggle(value);
    }
    return (
        <React.Fragment>
            <RequireAuth>
                <Sidebar pathname={pathname} toggle={toggle} hideSideBar={showSideBar} />
                <AdvertiserHeader showSideBar={showSideBar} toggle={toggle} />
                <AdvertiserFooter />
                <Routes>
                    {getRoutes(dashRoutes)}
                </Routes>
            </RequireAuth>
        </React.Fragment>

    );
}