import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useState } from 'react';
import { socialRegisterSchema } from '../../variables/validation_schema';
import { post } from '../../variables/apiCall';
import Loader from '../Loader';
import { useEffect } from 'react';
import { useAuth } from '../../auth/Auth';

const SocialRegisterForm = () => {
    const [resError, setResError] = useState("");
    const [loading, setLoading] = useState(false);
    const [streamerType, setStreamerType] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors }, } = useForm({
        resolver: yupResolver(socialRegisterSchema),
    });
    var navigate = useNavigate();
    let location = useLocation();
    let { data, type } = location.state;
    const auth = useAuth();
    const onSubmit = async (rdata) => {
        setLoading(true);
        var requestData = {
            ...rdata,
            verify: "email",
            provider_type: type,
            provider_id: data.id,
            social_login: 1,
            verify_status: true
        }
        var res = await post('/api/signup', requestData);
        setLoading(false);
        if (res.status === 200) {
            var user = res.user;
            auth.login(user)
            auth.setUserId(user.id)
            await auth.setToken(res.access_token);
            if (user.user_type === "2") {
                navigate('/adv/placeadlist');
            } else if (user.user_type === "1") {
                navigate('/str/library');
            } else if (user.user_type === "4") {
                navigate('/staff/streams');
            } else {
                navigate('/view/home');
            }
        } else {
            setResError(res.message);
        }
    }


    const userTypeChange = (e) => {
        if (e.target.value === "1") {
            setStreamerType(true);
        } else {
            setStreamerType(false);
        }
    }
    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setValue("name", data.name);
            setValue("email", data.email);
        }
    }, [data])
    if (loading) {
        return <Loader />
    }
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={6}>
                    <Form.Group className=" mb-4">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" {...register('name')} />
                        {errors.name && <p className='form-error'>{errors.name.message}</p>}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" {...register('email')} />
                        {errors.email && <p className='form-error'>{errors.email.message}</p>}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control type="tel" {...register('mobile')} />
                        {errors.mobile && <p className='form-error'>{errors.mobile.message}</p>}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>User Type</Form.Label>
                        <Form.Select aria-label="Default select example" placeholder="select" {...register('user_type', { onChange: (e) => { userTypeChange(e) } })}>
                            <option value={0}>Select User Type</option>
                            <option value={1}>Streamer</option>
                            <option value={2}>Advertiser</option>
                            <option value={3}>Viewer</option>
                        </Form.Select>
                        {errors.user_type && <p className='form-error'>{errors.user_type.message}</p>}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" {...register('password')} />
                        {errors.password && <p className='form-error'>{errors.password.message}</p>}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" {...register('cpassword')} />
                        {errors.cpassword && <p className='form-error'>{errors.cpassword.message}</p>}
                    </Form.Group>
                </Col>
                {streamerType && (<Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Streamer Type</Form.Label>
                        <Form.Select aria-label="Default select example" {...register('streamer_type')}>
                            <option value={0}>Select User Type</option>
                            <option value={1}>Club</option>
                            <option value={2}>Restaurant</option>
                            <option value={3}>Cafe</option>
                        </Form.Select>
                        {errors.streamer_type && <p className='form-error'>{errors.streamer_type.message}</p>}
                    </Form.Group>
                </Col>)}

                <Col md={12}>
                    <div className="mb-4 send-verification">
                        <div className="mb-4">
                            <Form.Check
                                {...register('terms')}
                                type={'checkbox'}
                                label={`I agree with the terms of use`}
                            />
                            {errors.terms && <p className='form-error'>{errors.terms.message}</p>}
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    {resError && <p className='form-error'>{resError}</p>}
                    <Button type="submit" className="login-submit-button mb-4">Sign Up</Button>
                </Col>
            </Row>
        </Form>
    );
}

export default SocialRegisterForm;