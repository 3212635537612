import React from 'react'
import { Button, Image, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BaseUrl } from '../../variables/general'
import CloseModal from "./../../assets/img/close.png"

export default function ShowOption({ video, show, modalClose, withAds }) {
    return (
        <Modal
            show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="modal-close">
                <Button type="button" onClick={modalClose}><Image src={CloseModal} /></Button>
            </div>
            <Modal.Body>
                <div className="login-heading modal-detail">
                    <div className="modal-heading">
                        <h4>You have not any active plan. Buy plan and Enjoy without ads</h4>
                    </div>
                    <div className="modal-image">
                        <Image src={BaseUrl + "/" + video.main_thumbnail_url} />
                        <p>{video.title}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Link className="banner-button mt-3" to={"/view/plan"}>Playing Without Ads</Link>
                <Button className="banner-button mt-3" onClick={withAds}>Playing With Ads</Button>
            </Modal.Footer>
        </Modal>
    )
}
