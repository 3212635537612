import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { Col, Container, Image, Row } from "react-bootstrap";
import { get } from "../../variables/apiCall";
import useWindowDimensions from "../../variables/useWindowDimensions";
import { Skeleton } from "@mui/material";


function HomeSlider() {
    const [loading, setLoading] = useState(false);
    let { height } = useWindowDimensions();
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            var res = await get("/api/banner_list");
            setLoading(false)
            if (res.status === 200) {
                setData(res.data);
            }
        }
        fetchData();
    }, []);
    if (!loading && data.length === 0) {
        return null;
    }
    return (
        <>
            {data.length !== 0 ?
                <Carousel className="banner-slider">
                    {data && data.map(el => {
                        var smi = JSON.parse(el.bannerData.small_image);
                        var btn = JSON.parse(el.bannerData.button);
                        return (
                            <Carousel.Item key={el.id} className="banner" style={{ backgroundImage: 'url(' + el.media_base_url + '/' + el.bannerData.main_image + ')' }}>
                                <Container>
                                    <Carousel.Caption>
                                        <div className="banner-content">
                                            <h6>{el.bannerData.title}</h6>
                                            <div className="banner-img">
                                                <ul>
                                                    {smi.map((ele, i) => (
                                                        <li key={i}>
                                                            <Image src={el.media_base_url + '/' + ele} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <Row>
                                                <Col md={6} className="mx-auto">
                                                    <div className="banner-para">
                                                        <p>{el.bannerData.description}</p>
                                                    </div>

                                                </Col>
                                            </Row>
                                            <div>
                                                <Link to={btn.link} className="banner-button">{btn.text}</Link>
                                            </div>
                                        </div>
                                    </Carousel.Caption>
                                </Container>

                            </Carousel.Item>
                        )
                    })}
                </Carousel>
                : <Skeleton variant="rectangular" width={"100%"} height={(.50 * height)} style={{ backgroundColor: "#ffffff17" }} />
            }
        </>
    );
}

export default HomeSlider;