import React from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'react-bootstrap'
import { ResetPasswordSchema } from '../../variables/validation_schema';
import { postWithToken } from '../../variables/apiCall';
import { useState } from 'react';
import swal from 'sweetalert';

function ResetPassword({ token }) {
    const { register, handleSubmit, reset, formState: { errors }, } = useForm({
        resolver: yupResolver(ResetPasswordSchema),
    });
    const [resError, setResError] = useState("");
    const onSubmit = async (data) => {
        var res = await postWithToken("/api/createNewPassword", token, data);
        if (res.status === 200) {
            setResError("");
            reset()
            swal("Password Changed", res.message, "success");
        } else {
            setResError(res.message);
        }
    }
    return (
        <div className="profile-bg">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={4}>
                        <Form.Group className="mb-4">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control {...register('current_password')} placeholder="Enter Current Password" type="password"></Form.Control>
                            {errors.current_password && <p className='form-error'>{errors.current_password.message}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control {...register('new_password')} placeholder="Enter New Password" type="password"></Form.Control>
                            {errors.new_password && <p className='form-error'>{errors.new_password.message}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control {...register('confirm_password')} placeholder="Enter Confirm Password" type="password"></Form.Control>
                            {errors.confirm_password && <p className='form-error'>{errors.confirm_password.message}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        {resError && <p className='form-error'>{resError}</p>}
                        <button type="submit" className="login-submit-button mt-5 btn btn-primary">Update</button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ResetPassword