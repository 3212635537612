import React from "react";
import { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import devtools from "../variables/devtools";
const AuthContext = createContext(null);

export const AuthProvide = ({ children }) => {
    let pathname = window.location.pathname;

    const [user, setUser] = useState(null);
    const [id, setId] = useState(null);

    const setToken = (token) => {
        localStorage.setItem('token', token);
    }

    const getToken = () => {
        return localStorage.getItem('token');
    }

    const login = (user) => {
        setUser(user);
    }

    const setUserId = (userId) => {
        setId(userId);
    }

    const logout = () => {
        setUser(null);
        setId(null);
        localStorage.clear();
    }
    // useEffect(() => {
    //     function handleClick(event) {
    //         if (event.detail.isOpen && pathname !== "/dev") {
    //             window.location.replace('/dev');
    //         }
    //     }
    //     window.addEventListener('devtoolschange', handleClick);

    //     return () => {
    //         window.removeEventListener('devtoolschange', handleClick);
    //     }
    // }, [])

    // if (devtools.isOpen && pathname !== "/dev") {
    //     window.location.replace('/dev');
    // }
    return (
        <AuthContext.Provider value={{ user, id, setToken, getToken, login, setUserId, logout }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}