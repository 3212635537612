import moment from "moment/moment";
import { BaseUrl } from "./variables/general";

export const getBase64 = file => {
    return new Promise(resolve => {
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

export const getLength = video => {
    return new Promise(resolve => {
        const audio = new Audio();
        audio.src = video;
        audio.onloadedmetadata = _ => {
            resolve(Math.round(audio.duration));
        };
        audio.onerror = ev => {
            resolve(0);
        };
    });
};

export const getLengthTxt = time => {
    if (time > 59) {
        time = Math.floor(time / 60) + " Minutes";
    } else {
        time = time + " Seconds";
    }
    return time;
}

export const getUrl = url => {
    return BaseUrl + "/" + url;
}

export const getTenlevelArray = val => {
    var i = 0;
    var arr = [i];
    for (let k = 0; i < val; k++) {
        var n = Math.round(val / 9);
        if (n + i <= val) {
            i += n;
            arr.push(i);
        } else if (n + i > val) {
            var t = val - i;
            i += t
        }
    }
    return arr;
}

export const getTitleName = val => {
    var str = "";
    if (val) {
        var arr = val.split("_");
        arr.map(el => {
            str += capitalizeFirstLetter(el) + " ";
        })
    }
    return str;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getTodayDate = () => {
    return moment().format('YYYY-MM-DD');
}

export const getCurrentTime = () => {
    return moment().format('h:mm:ss');
}

export const getTimeDiff = (time) => {
    var current = moment();
    var pt = moment(time, 'h:mm:ss');
    var diff = false;
    if (pt.isSameOrAfter(current)) {
        diff = Math.abs(pt - current);
        diff = Math.floor((diff / 1000) / 60)
        if (diff === 0)
            diff = true
    } else if (current.isSameOrAfter(pt)) {
        diff = Math.abs(current - pt);
        diff = Math.floor((diff / 1000) / 60)
        if (diff === 0)
            diff = true
    }
    return diff;
}