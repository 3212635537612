import React from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Container, Row } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Form, FloatingLabel } from "react-bootstrap";
import UploadVideo from "./../../assets/img/video.png"
import Upload from "./../../assets/img/upload.png"
import UploadImage from "./../../assets/img/gallery.png"
import { VideoUpload, VideoUpload1 } from "../../variables/validation_schema";
import stremermovie from "./../../assets/img/stremermovie.png"
import { useNavigate } from "react-router-dom";
import StepWizard from "react-step-wizard";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../auth/Auth";
import { useEffect } from "react";
import { getWithToken, postWithTokenForm } from "../../variables/apiCall";
import swal from "sweetalert";
import MultiSelectBox from "../../components/MultiSelectBox";
import Loader from "../../components/Loader";
import { getLength, getTitleName } from "../../Helper";

function One(props) {
    const [media, setMedia] = useState({});
    const [runTime, setRunTime] = useState("");
    const [gen, setGen] = useState([]);
    const [type, setType] = useState("tv_show");
    const { register, handleSubmit, setError, setValue, formState: { errors }, } = useForm({
        resolver: yupResolver(VideoUpload),
    });
    const changeType = (e) => {
        var val = e.target.value;
        setType(val)
    }
    const changeMedia = async (e) => {
        var name = e.target.name;
        var value = URL.createObjectURL(e.target.files[0]);
        if (name === "trailer_video_url") {
            var len = await getLength(value)
            if (type === "tv_show") {
                if (len === 90 || len < 150) {
                    setMedia({ ...media, [name]: value })
                } else {
                    setError('trailer_video_url', { type: 'custom', message: "This should be of 1.30 m & 2.30 m for TV Show" });
                }
            } else {
                if (len === 90 || len < 180) {
                    setMedia({ ...media, [name]: value })
                } else {
                    setError('trailer_video_url', { type: 'custom', message: "This should be of 1.30 m & 3 m for Movie" });
                }
            }
        } else {
            if (name === "main_video_url") {
                var time = await getLength(value);
                if (time > 59) {
                    time = Math.floor(time / 60) + " Minutes";
                } else {
                    time = time + " Seconds";
                }
                setRunTime(time)
            }
            setMedia({ ...media, [name]: value })
        }
    }
    const onSubmit = async (data) => {
        var err = false;
        if (media.trailer_video_url === undefined) {
            setError('trailer_video_url', { type: 'custom', message: "This field is required." });
            err = true;
        }
        if (media.trailer_thumbnail_url === undefined) {
            setError('trailer_thumbnail_url', { type: 'custom', message: "This field is required." });
            err = true;
        }
        if (media.main_thumbnail_url === undefined) {
            setError('main_thumbnail_url', { type: 'custom', message: "This field is required." });
            err = true;
        }
        if (media.main_video_url === undefined) {
            setError('main_video_url', { type: 'custom', message: "This field is required." });
            err = true;
        }
        if (!err) {
            props.nextStep();
            props.callBack(data, media, runTime);
        }
    }
    const changeGen = (g) => {
        setGen(g);
        setValue('genre', g.toString(), { shouldValidate: true })
    }
    return (
        <div className="addvertiser-header">
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Upload</h4>
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="place-ads">
                                    <div className="place-ads-table">
                                        <div className="networkhours mb-3">
                                            <p>Do you want to upload Network TV (12-24 hours pre-programming)</p>
                                        </div>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col md={12} className="login-form mt-0">
                                                    <div className="send-verification code mb-2 ">
                                                        <div>
                                                            <div className="verification-radio  mb-4" style={{ display: "flex", columnGap: "50px", alignItems: "center" }}>
                                                                <Form.Label>Item Type</Form.Label>
                                                                <Form.Check
                                                                    type={"radio"}
                                                                    value="tv_show"
                                                                    defaultChecked={true}
                                                                    {...register('item_type', { onChange: changeType })}
                                                                    label={`Tv Shows`}
                                                                />
                                                                <Form.Check
                                                                    type={"radio"}
                                                                    label={`Movie`}
                                                                    value="movie"
                                                                    {...register('item_type', { onChange: changeType })}
                                                                />
                                                                <Form.Check
                                                                    type={"radio"}
                                                                    label={`Network TV`}
                                                                    value="network_tv"
                                                                    {...register('item_type', { onChange: changeType })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="login-form mt-0">
                                                <Row>
                                                    <Col xl={6}>
                                                        <div className="track_heading text-lg-center"><h6>Upload Trailer</h6></div>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <div>
                                                                    <Form.Label>Upload Trailer</Form.Label>
                                                                    <Form.Group className="mb-4 upload-profile">
                                                                        <Form.Control accept="video/mp4,video/*" {...register('trailer_video_url', { onChange: changeMedia })} type="file"></Form.Control>
                                                                        {media && media.trailer_video_url ? <video src={media.trailer_video_url} controls /> :
                                                                            <Image src={UploadVideo} className="img-fluid upload_img" />}
                                                                        <div className="upload-banner">
                                                                            <p><Image src={Upload} />Upload Video</p>
                                                                        </div>
                                                                        {errors.trailer_video_url && <p className='form-error'>{errors.trailer_video_url.message}</p>}
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <div>
                                                                    <Form.Label>Upload Thumbnail of Trailer</Form.Label>
                                                                    <Form.Group className="mb-4 upload-profile">
                                                                        <Form.Control accept="image/png, image/jpeg" {...register('trailer_thumbnail_url', { onChange: changeMedia })} type="file"></Form.Control>
                                                                        {media && media.trailer_thumbnail_url ? <Image src={media.trailer_thumbnail_url} className="img-fluid imguploing" /> :
                                                                            <Image src={UploadImage} className="img-fluid upload_img" />}
                                                                        <div className="upload-banner">
                                                                            <p><Image src={Upload} />Upload Thumbnail</p>
                                                                        </div>
                                                                        {errors.trailer_thumbnail_url && <p className='form-error'>{errors.trailer_thumbnail_url.message}</p>}
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl={6}>
                                                        <div className="track_heading text-lg-center"><h6>Upload Movie / Tv Shows / Network TV</h6></div>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <div>
                                                                    <Form.Label>Upload Movie / Tv Shows / Network TV</Form.Label>
                                                                    <Form.Group className="mb-4 upload-profile">
                                                                        <Form.Control accept="video/mp4,video/*" {...register('main_video_url', { onChange: changeMedia })} type="file"></Form.Control>
                                                                        {media && media.main_video_url ? <video src={media.main_video_url} controls /> :
                                                                            <Image src={UploadVideo} className="img-fluid upload_img" />}
                                                                        <div className="upload-banner">
                                                                            <p><Image src={Upload} />Upload Video</p>
                                                                        </div>
                                                                        {errors.main_video_url && <p className='form-error'>{errors.main_video_url.message}</p>}
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <div>
                                                                    <Form.Label>Upload Thumbnail of Movie / Tv Shows</Form.Label>
                                                                    <Form.Group className="mb-4 upload-profile">
                                                                        <Form.Control accept="image/png, image/jpeg" {...register('main_thumbnail_url', { onChange: changeMedia })} type="file"></Form.Control>
                                                                        {/* <Image src={media && media.main_thumbnail_url ? media.main_thumbnail_url : UploadImage} className="img-fluid upload_img" /> */}
                                                                        {media && media.main_thumbnail_url ? <Image src={media.main_thumbnail_url} className="img-fluid imguploing" /> :
                                                                            <Image src={UploadImage} className="img-fluid upload_img" />}
                                                                        <div className="upload-banner">
                                                                            <p><Image src={Upload} />Upload Thumbnail</p>
                                                                        </div>
                                                                        {errors.main_thumbnail_url && <p className='form-error'>{errors.main_thumbnail_url.message}</p>}
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Enter Title</Form.Label>
                                                            <Form.Control {...register('title')} type="text" maxLength={40}></Form.Control>
                                                            {errors.title && <p className='form-error'>{errors.title.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Enter Tagline </Form.Label>
                                                            <Form.Control {...register('tagline')} type="text" maxLength={50}></Form.Control>
                                                            {errors.tagline && <p className='form-error'>{errors.tagline.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4 genre-width">
                                                            <Form.Label>Enter Genre</Form.Label>
                                                            <MultiSelectBox data={props.genre} personName={gen} setPersonName={changeGen} />
                                                            {errors.genre && <p className='form-error'>{errors.genre.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Video Description</Form.Label>
                                                            <FloatingLabel controlId="floatingTextarea2">
                                                                <Form.Control
                                                                    {...register('description')}
                                                                    as="textarea"
                                                                    placeholder="Leave a comment here"
                                                                    style={{ height: '100px' }}
                                                                />
                                                            </FloatingLabel>
                                                            {errors.description && <p className='form-error'>{errors.description.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12} className="text-center">
                                                        <Button type="submit" className="mx-auto mt-md-5 mt-4 mb-md-4 btn btn-primary upload-button">Next</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}
function Two(props) {
    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(VideoUpload1),
    });
    const [planType, setPlanType] = useState("svod");
    let videoData = props.videoData;
    const validate = (data) => {
        props.callBack(data)
    }
    let thumbnail_url = "";
    if (Object.keys(props.upMe).length > 0) {
        thumbnail_url = props.upMe.trailer_thumbnail_url;
    }

    return (
        <div className="addvertiser-header">
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Upload</h4>
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="place-ads">
                                    <div className="place-ads-table">
                                        <div className="streamermovie">
                                            <div className="stremerimg">
                                                <Image src={thumbnail_url ? thumbnail_url : stremermovie} />
                                            </div>
                                            <div className="titlestremer">
                                                <h4>{videoData && videoData.title}<button onClick={() => { props.previousStep() }} className="edit_icon"><FontAwesomeIcon icon={faEdit} /></button> </h4>
                                                <p>{videoData && videoData.description}</p>
                                                <ul className="typemovie">
                                                    <li>
                                                        <p>Type</p>
                                                        <span>{videoData && getTitleName(videoData.item_type)}</span>
                                                    </li>
                                                    <li>
                                                        <p>Episode run time</p>
                                                        <span>{props.rt}</span>
                                                    </li>
                                                    <li>
                                                        <p>Genres</p>
                                                        <span>{videoData && videoData.genre}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="login-form">
                                            <Form onSubmit={handleSubmit(validate)}>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Add Ad Slots</Form.Label>
                                                            <Form.Select {...register('ad_slots')} aria-label="Default select example" >
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                            </Form.Select>
                                                            {errors.ad_slots && <p className='form-error'>{errors.ad_slots.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Choose Plan</Form.Label>
                                                            <Form.Select {...register('plan_type', { onChange: e => { setPlanType(e.target.value) } })} aria-label="Default select example" >
                                                                <option value="">Choose Plan</option>
                                                                <option value="ppv">PPV</option>
                                                            </Form.Select>
                                                            {errors.plan_type && <p className='form-error'>{errors.plan_type.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    {planType === "ppv" &&
                                                        <div className="targetedaudian mb-4">
                                                            <h6 className="mb-4">Create Plan</h6>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <Form.Group className="mb-4">
                                                                        <Form.Label>Message to Viewer </Form.Label>
                                                                        <Form.Control {...register('plan_title')} type="text"></Form.Control>
                                                                        {errors.plan_title && <p className='form-error'>{errors.plan_title.message}</p>}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Form.Group className="mb-4">
                                                                        <Form.Label>Price</Form.Label>
                                                                        <Form.Control {...register('plan_price', { value: 0 })} type="number" step="0.01"></Form.Control>
                                                                        {errors.plan_price && <p className='form-error'>{errors.plan_price.message}</p>}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Form.Group className="mb-4">
                                                                        <Form.Label>Select Plan Type</Form.Label>
                                                                        <Form.Select {...register('ppv_plan_type')} aria-label="Default select example" >
                                                                            <option value={0}>Select Type</option>
                                                                            <option value={1}>One time view</option>
                                                                            <option value={2}>Seven days</option>
                                                                            <option value={3}>Monthly</option>
                                                                        </Form.Select>
                                                                        {errors.ppv_plan_type && <p className='form-error'>{errors.ppv_plan_type.message}</p>}
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                    <Col md={12} className="text-center">
                                                        {props.resError && <p className="form-error">{props.resError}</p>}
                                                        <Button type="submit" className="mx-auto mt-5 btn btn-primary upload-button">Ask for Approval</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}
function StreamerUpload() {
    const [stepWizard, setStepWizard] = useState(null);
    const [upMe, setUpMe] = useState({});
    const [runTime, setRunTime] = useState("");
    const [loading, setLoading] = useState(false);
    const [resError, setResError] = useState("");
    const [videoData, setVideoData] = useState({});
    const [genre, setGenre] = useState([]);
    let navigate = useNavigate();
    let auth = useAuth();
    var token = auth.getToken();
    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };
    const assignVideo = (data, media, runtime) => {
        if (Object.keys(media).length > 0) {
            setUpMe(media);
            setRunTime(runtime)
        }
        setVideoData(data);
    }
    const submitData = async (fData) => {
        setLoading(true)
        var reqData = {
            ...videoData,
            ...fData,
        }
        const data = new FormData();
        Object.keys(reqData).forEach(function (key) {
            if (key === "trailer_video_url") {
                data.append(key, reqData[key][0]);
            } else if (key === "trailer_thumbnail_url") {
                data.append(key, reqData[key][0]);
            } else if (key === "main_video_url") {
                data.append(key, reqData[key][0]);
            } else if (key === "main_thumbnail_url") {
                data.append(key, reqData[key][0]);
            } else {
                data.append(key, reqData[key]);
            }
        });
        var response = await postWithTokenForm('/api/upload_video', token, data);
        setLoading(false)
        if (response.status === 200) {
            setResError("");
            swal("Good job!", response.message, "success");
            navigate("/str/library");
        } else {
            setResError(response.message);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            var res = await getWithToken('/api/genre_list', token);
            if (res.status === 200) {
                var gd = [];
                res.data.map(el => {
                    gd.push({
                        name: el.title,
                        id: el.id
                    })
                    return false;
                })
                setGenre(gd);
            }
            setLoading(false)
        }
        fetchData();
    }, [token])
    return (
        <>
            {loading ? <Loader /> :
                <StepWizard className="ddddd" instance={assignStepWizard}>
                    <One videoData={videoData} genre={genre} callBack={assignVideo} />
                    <Two videoData={videoData} upMe={upMe} rt={runTime} resError={resError} callBack={submitData} />
                </StepWizard>
            }
        </>
    )
}

export default StreamerUpload;