import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import filter from "../../assets/img/filter-img.png";
import { Dropdown } from "react-bootstrap";
import DataTables from '../../components/DataTables';
import AdsModal from '../../components/modal/AdsModal';
import { getWithToken } from '../../variables/apiCall';
import { BaseUrl } from '../../variables/general';
import { useAuth } from '../../auth/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../components/Loader';

function PlaceList() {
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState({});
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    var token = auth.getToken();
    const navigate = useNavigate();
    const handleClose = () => {
        setShow(false);
        setRowData({});
    };
    const handleShow = () => setShow(true);
    const editHandler = async (e) => {
        e.preventDefault();
        var id = e.target.id;
        var response = await getWithToken('/api/ads/' + id, token);
        if (response.status === 200) {
            handleShow();
            setRowData(response.data);
        }
    }
    const addVideo = (e) => {
        e.preventDefault();
        var id = e.target.id;
        navigate("/adv/placeadstep", { state: { ad_id: id } });
    }
    const columns = [
        {
            name: "Video Ad",
            cell: (row) => (
                <div className="video-aad"><Image width={"100px"} src={BaseUrl + "/" + row.thumbnail_url} /><p>{row.title}</p></div>
            )
        },
        {
            name: "Action",
            cell: (row) => (
                <Button type="button" id={row.id} onClick={editHandler} className="action-button" >Edit</Button>
            )
        },
        {
            name: "Status",
            cell: (row) => {
                switch (row.status) {
                    case 1: return (<Button type="button" className={"active-button"}>{"active"}</Button>)
                    case 2: return (<Button type="button" className={"inactive-button"}>{"Inactive"}</Button>)
                    default: return (<Button type="button" className={"pending-button"}>{"Pending"}</Button>)
                }
            }
        },
        {
            name: "Action",
            cell: (row) => (
                <Dropdown>
                    <Dropdown.Toggle variant="success" className="filtername  dotsss-img" id="dropdown-basic">
                        <FontAwesomeIcon icon={faEllipsisV}></FontAwesomeIcon>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {row.status === 1 ?
                            <Dropdown.Item id={row.id} onClick={addVideo} href="#">Add Video</Dropdown.Item>
                            : <p>Your video is not approved by admin.</p>}
                    </Dropdown.Menu>
                </Dropdown>
            )
        }
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                var response = await getWithToken('/api/ads_list', token);
                if (response.status === 200) {
                    setLoading(false);
                    setData(response.data);
                }
            } catch (error) {
                console.log("error", error);
            }
        };
        if (!show)
            fetchData();
    }, [token, show])

    return (
        <div className="addvertiser-header">
            {loading && <Loader />}

            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Place Ad</h4>
                            <Link className="submit-button" variant="primary" onClick={handleShow}>Add Place Ads</Link>
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="place-ads">
                                    <div className="place-ads-table">
                                        <div className="table-haeding">
                                            <h6>List of Played Ads/New ads</h6>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" className="filtername" id="dropdown-basic">
                                                    <Image src={filter}></Image>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <DataTables
                                            columns={columns}
                                            data={data}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <AdsModal handleclose={handleClose} rowdata={rowData} show={show} />
        </div>
    );
}


export default PlaceList;