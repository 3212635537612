import Modal from 'react-bootstrap/Modal';
import closeicon from "../../assets/img/cloase-icon.png";
import { Image, Form } from 'react-bootstrap';
import { postWithToken } from '../../variables/apiCall';
import { useState } from 'react';

export default function StreamerVideo({ show, adData, handleClose, streamers, token, setVideo }) {
    const [videoId, setVideoId] = useState(null);
    const [avSlot, setAvSlot] = useState(0);
    const [adSlot, setAdSlot] = useState(1);
    const [type, setType] = useState("video");
    const [selected, setSelected] = useState("");
    const [videos, setVideos] = useState([]);
    const [error, setError] = useState("");
    const getVideo = async (e) => {
        let id = e.target.value;
        if (id) {
            setSelected(id);
            var rD = {
                streamer_id: id,
                type: type
            }
            var res = await postWithToken('/api/streamers_video_list', token, rD);
            if (res.status === 200) {
                setVideos(res.data);
                setVideoId(null);
            }
        }
    }
    const typeChange = e => {
        setType(e.target.value);
        setSelected("");
        setVideos([]);
        setVideoId(null);
    }
    const handleChange = (e) => {
        var id = e.target.value;
        setVideoId(id);
        var obj = videos.find((v) => {
            return v.id == id;
        })
        if (Object.keys(adData).length > 0 && id == adData.video_id) {
            setAvSlot(obj.avail_slots + adData.ad_slots);
        } else
            setAvSlot(obj.avail_slots);
    }
    const hanInpChange = (e) => {
        var val = e.target.value;
        if (avSlot >= val) {
            setError("");
            setAdSlot(e.target.value)
        } else {
            setError("value should be less then or equal to Available Slots.");
        }
    }
    const handleSubmit = () => {
        if (videoId !== null && adSlot > 0) {
            setError("");
            setVideo(videoId, adSlot, type);
            handleClose();
        } else {
            setError("Please select video Or Slots value minimum is one.");
        }
    }
    return (
        <Modal centered
            size='lg'
            show={show} onHide={handleClose} animation={false}>
            <p onClick={handleClose} className="closeiconall">
                <Image src={closeicon}></Image>
            </p>
            <Modal.Body className='login-form  text-start mt-0 '>
                <h6 className='tittle-heading mb-4 text-start'>Please select Streamers</h6>
                <div className="verification-radio serveyedit mt-2">
                    <Form.Group className="mb-4" style={{ display: "flex", columnGap: "50px", alignItems: "center" }}>
                        <Form.Label>Select Type</Form.Label>
                        <Form.Check
                            type="radio"
                            value='video'
                            onClick={typeChange}
                            checked={type === "video" ? true : false}
                            label="Videos"
                        />
                        <Form.Check
                            type="radio"
                            onClick={typeChange}
                            checked={type === "stream" ? true : false}
                            value='stream'
                            label="Stream"
                        />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Select Steamer</Form.Label>
                        <Form.Select value={selected} onChange={getVideo} aria-label="Default select example" >
                            <option value="">Select Steamer</option>
                            {streamers.map(el => (
                                <option value={el.id} key={el.id}>{el.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    {videos.length > 0 ? (
                        <Form.Group className="mb-4">
                            <Form.Label>Select Video</Form.Label>
                            <Form.Select onChange={handleChange} aria-label="Default select example" >
                                <option value="">Select Video</option>
                                {videos.map(el => (
                                    <option value={el.id} key={el.id}>{el.title}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    ) : <p>No video found of this streamer</p>}
                    {videoId && (
                        <>
                            <Form.Label>Available Slots : {avSlot}</Form.Label>
                            <Form.Group className="mb-4">
                                <Form.Label>Select Slots</Form.Label>
                                <Form.Control type="number" value={adSlot} onChange={hanInpChange} placeholder="Booking slots number"></Form.Control>
                            </Form.Group></>
                    )}
                    {error && <p className='form-error'>{error}</p>}
                </div>
                <button onClick={handleSubmit} className=" mt-5 upload-appoved" >Select</button>

            </Modal.Body>
        </Modal>
    );
}