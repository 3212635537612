import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import UpcomingTv from '../../components/Home/upcoming-live';
import { useAuth } from '../../auth/Auth';
import Loader from '../../components/Loader';
import PpvModal from '../../components/modal/PpvModal';
import ShowOption from '../../components/modal/ShowOption';
import Notification from '../../components/Notification';
import { getWithToken } from '../../variables/apiCall';
import ShowStream from '../../components/Movie/ShowStream';
import withProfileCheck from '../../components/HOC/withProfileCheck';

function ViewLiveStream() {
    const auth = useAuth();
    let token = auth.getToken();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(null);
    const [vs, setVs] = useState(false);
    const [ppvShow, setPpvShow] = useState(false);
    const [avodShow, setAvodShow] = useState(false);
    const [videoData, setVideoData] = useState({});
    const location = useLocation();
    let { sid } = location.state;
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                var res = await getWithToken('/api/viewer/stream_details/' + sid, token);
                var sts = false;
                if (res.status === 200) {
                    var video = res.data;
                    if (video.plan_type === "ppv") {
                        if (video.access) {
                            sts = true;
                        } else {
                            setPpvShow(true);
                        }
                    } else {
                        if (video.plan_type === "svod") {
                            if (video.access) {
                                sts = true;
                            } else {
                                navigate("/view/plan");
                            }
                        } else {
                            setAvodShow(true)
                        }
                    }
                    setVs(sts);
                    setVideoData(res.data);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        fetchData();
    }, [sid, token])
    const ppvModalClose = () => {
        navigate(-1)
        setPpvShow(false)
    }
    const avodModalClose = () => {
        navigate(-1)
        setAvodShow(false)
    }
    const withAds = () => {
        setVs(true);
        setAvodShow(false);
    }
    if (loading) {
        return <Loader />;
    }
    return (
        <div className='watchmovie-iiner'>
            <ShowStream str={videoData} user={auth.user} token={token} />
            <UpcomingTv />
            <PpvModal video={videoData} show={ppvShow} modalClose={ppvModalClose} />
            <ShowOption video={videoData} show={avodShow} modalClose={avodModalClose} withAds={withAds} />
            <Notification />
        </div>
    )
}

export default withProfileCheck(ViewLiveStream);
