import React, { useRef, useState, useEffect, useContext } from 'react';
import SliderContext from "../Slider/context"
import useVisibility from "../../hooks/useVisibility"
import { getUrl } from '../../Helper';
import { PlayArrow } from '@mui/icons-material';
function SliderItem(props) {
    const ref = useRef(null)
    const [inViewport, setInViewport] = useState(false)
    const [showOverlay, setShowOverlay] = useState(false)
    const sliderWrapper = useContext(SliderContext);
    const itemVisible = () => {
        setInViewport(true)
    }
    const itemNotVisible = () => {
        setInViewport(false)
    }
    const onHover = (e) => {
        if (!sliderWrapper.currentSlide) {
            props.hover(e)
            setShowOverlay(true)
        }
    }
    const onHoverLeave = (e) => {
        props.reset(e)
        setShowOverlay(false)
    }
    const isActive = sliderWrapper.currentSlide && sliderWrapper.currentSlide.id === props.title;
    useVisibility(ref, itemVisible, itemNotVisible);
    useEffect(() => {
    }, [inViewport, setInViewport])
    const translate = `translateX(${props.transform})`
    const styled = { transform: translate, "transformOrigin": props.origin, transition: "all 0.3s ease-in-out" }
    return (
        <div
            style={styled}
            data-id={props.title}
            className={`slider-item card ${inViewport ? "onScreen" : ""}`}
            ref={ref}
            onMouseLeave={(e) => onHoverLeave(e)}
            onMouseEnter={(e) => onHover(e)}
        >
            <div className="boxart-container">
                <div className="boxart">
                {showOverlay ?
                <video src={getUrl(props.data.trailer_video_url)} autoPlay={true} loop />
                : <img src={getUrl(props.data.trailer_thumbnail_url)} alt="boxart" />}
                </div>
            </div>
            <div className={`item-overlay ${showOverlay ? "open" : ""}`}>
                <div className="item-wrapper">
                    <div className="item-overview">
                        <div className="item-overview-play" onClick={(e) => props.fun(props.data.id, e)}>
                        <PlayArrow className="icon" />
                        </div>
                        <div className="item-overview-title"><h6>{props.data.title}</h6></div>
                        <div className="item-overview-metadata desc">
                          {props.data.description.length > 100 ? props.data.description.substring(0, 100) + "..." : props.data.description}
                        </div>
                        <div className="item-overview-synopsis">
                          {props.data.genre}
                        </div>
                    </div>
                    
                    <div className="item-chevron"></div>
                </div>

            </div>
            {isActive && (<div className="mark" />)}
        </div>
    );
}

export default SliderItem;
