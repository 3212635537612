import React from "react";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavDropdown, Nav } from "react-bootstrap";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./../../assets/img/logo.png";
import { useAuth } from "../../auth/Auth";
import userprofle from '../../assets/img/user-profile.png';
import Notifications from "../Notifications";


function AdvertiserHeader(props) {
    var navigate = useNavigate();
    var auth = useAuth();
    var imgSrc = userprofle;
    if (auth.user && auth.user.userprofile) {
        imgSrc = auth.user.userprofile;
    }
    return (
        <div className="addvertiser-head">
            <Navbar expand="lg" className="py-0">
                <Container fluid>
                    <Navbar id="navbarScroll" className="advertiser-header">
                        <Form className="d-flex align-items-center">
                            <div className="header-menu" onClick={props.showSideBar}>
                                <FontAwesomeIcon icon={faBars} />
                            </div>
                            <div className="advertiser-mobile-header">
                                <Link to={"/"}> <Image src={Logo} />
                                </Link>
                            </div>
                        </Form>
                        <div className="search-bar">
                            <Notifications />
                            <Nav className="nav-link">
                                <p className="userNmae">Welcome  {auth.user && auth.user.name}</p>
                                <NavDropdown className="user-profile" style={{ backgroundImage: 'url(' + imgSrc + ')' }}>
                                    <NavDropdown.Item onClick={(e) => { e.preventDefault(); navigate("/adv/profile") }} href="/">Profile</NavDropdown.Item>
                                    <NavDropdown.Item onClick={(e) => { e.preventDefault(); auth.logout() }} href="/" > Logout</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                </NavDropdown></Nav>
                        </div>
                    </Navbar>
                </Container>
            </Navbar>
        </div>
    );
}

export default AdvertiserHeader;