import React, { useCallback, useState } from 'react';
import jwt_decode from "jwt-decode";
import {
    LoginSocialFacebook,
    LoginSocialInstagram,
    IResolveParams,
} from 'reactjs-social-login';
import FaceBook from "./../../assets/img/facebook.png";
import InstaIcon from "./../../assets/img/insta.png";
import { Image } from 'react-bootstrap';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { post } from '../../variables/apiCall';
import { useAuth } from '../../auth/Auth';
import Loader from '../Loader';

const REDIRECT_URI = 'http://localhost:3000/account/login'

const SocialLogin = () => {
    const [provider, setProvider] = useState('');
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState();
    const navigate = useNavigate();
    const auth = useAuth();
    const googleSuccess = useCallback(async (credentialResponse) => {
        var decoded = jwt_decode(credentialResponse.credential);
        await authenticateUser(decoded, "google");
    }, []);
    const fbSuccess = useCallback(async ({ provider, data }) => {
        await authenticateUser(data, "facebook");
    }, []);
    const authenticateUser = async (data, type) => {
        setLoading(true);
        var res = await post("/api/social", { email: data.email });
        setLoading(false);
        if (res.status === 200) {
            var user = res.user;
            auth.login(user)
            auth.setUserId(user.id)
            await auth.setToken(res.access_token);
            if (user.user_type === "2") {
                navigate('/adv/placeadlist');
            } else if (user.user_type === "1") {
                navigate('/str/library');
            } else if (user.user_type === "4") {
                navigate('/staff/streams');
            } else {
                navigate('/view/home');
            }
        } else {
            navigate('/auth/social', { state: { data: data, type: type } });
        }
    }
    const onLogoutSuccess = useCallback(() => {
        setProfile(null);
        setProvider('');
        alert('logout success');
    }, []);

    const onLogout = useCallback(() => { }, []);
    if (loading) {
        <Loader />
    }
    return (
        <>
            {/* {provider && profile && (
                <User provider={provider} profile={profile} onLogout={onLogout} />
            )} */}
            <div className={`account-icon mt-3 App ${provider && profile ? 'hide' : ''}`}>
                <ul>
                    <li className="facebook">
                        <LoginSocialFacebook
                            appId={717367819904003}
                            fieldsProfile={
                                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                            }
                            onLogoutSuccess={onLogout}
                            onResolve={fbSuccess}
                            onReject={err => {
                                console.log(err);
                            }}
                        >
                            <Image src={FaceBook} className="img-fluid" alt="img" />
                        </LoginSocialFacebook>
                    </li>
                    <li className="gmail">
                        <GoogleLogin
                            onSuccess={googleSuccess}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                            size='medium'
                            type="icon"
                            shape='circle'
                            useOneTap
                        />
                    </li>
                    <li className="insta">
                        <LoginSocialInstagram
                            client_id={5575146119247589}
                            client_secret={"7427180723b438a332d4dfc96df81939"}
                            redirect_uri={REDIRECT_URI}
                            onLogoutSuccess={onLogoutSuccess}
                            onResolve={({ provider, data }) => {
                                console.log(provider, data);
                                setProvider(provider);
                                setProfile(data);
                            }}
                            onReject={(err) => {
                                console.log(err);
                            }}
                        >
                            <Image src={InstaIcon} className="img-fluid" alt="img" />
                        </LoginSocialInstagram>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default SocialLogin;