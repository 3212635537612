import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Tabs, Tab } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { useAuth } from '../../auth/Auth';
import { getWithToken } from '../../variables/apiCall';
import Loader from '../../components/Loader';
import { BaseUrl } from '../../variables/general';
import DataTables from '../../components/DataTables';
import { getTimeDiff, getTodayDate } from '../../Helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

function StreamTable() {
    const location = useLocation();
    const auth = useAuth();
    let token = auth.getToken();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [streams, setStreams] = useState({});
    const columns = [
        {
            name: "Title",
            cell: (row) => (
                <div className="video-ad"><Image width={"100px"} src={BaseUrl + "/" + row.trailer_thumbnail_url} /><p>{row.title}</p></div>
            )
        },
        {
            name: "Date",
            selector: row => row.date,
        },
        {
            name: "Time",
            selector: row => row.time,
        },
        {
            name: "Plan Type",
            selector: row => row.plan_type.toUpperCase(),
        },
        {
            name: "Status",
            cell: (row) => {
                if (row.status === 1 && row.paid_status === 0) {
                    var date = getTodayDate();
                    var time = getTimeDiff(row.time);
                    var strBtn = false;
                    if (date === row.date && time && time < 100) {
                        strBtn = true;
                    }
                    return (
                        <>
                            <Button type="button" className={"active-button me-2"}>{"Approved"}</Button>
                            {strBtn && <Button id={row.id} onClick={startStream} className={"active-button"}>Start Stream</Button>}
                        </>
                    )
                } else if (row.status === 1 && row.paid_status === 1) {
                    return (
                        <>
                            <Button type="button" className={"pending-button me-2"}>{"Paid"}</Button>
                            {strBtn && <Button id={row.id} onClick={startStream} className={"active-button"}>Start Stream</Button>}
                        </>
                    );
                } else {
                    if (row.status === 2) {
                        return (<Button type="button" className={"inactive-button"}>{"Rejected"}<Tooltip title={row.reason}>
                            <IconButton className='p-0 ms-2'>
                                <HelpOutline className='helpoutline'/>
                            </IconButton>
                        </Tooltip></Button>)
                    } else {
                        return (<Button type="button" className={"inactive-button"}>{"Unapproved"}</Button>)
                    }
                }
            }
        },
    ];
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                var res = await getWithToken('/api/stream_library', token);
                if (res.status === 200) {
                    var app = [];
                    var unApp = [];
                    var paid = [];
                    res.data.map(el => {
                        if (el.status === 1 && el.paid_status === 0) {
                            app.push(el);
                        } else if (el.status === 1 && el.paid_status === 1) {
                            paid.push(el);
                        } else {
                            unApp.push(el);
                        }
                        return false;
                    })
                    setStreams({
                        approved: app,
                        unapproved: unApp,
                        paid: paid
                    });
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        fetchData();
    }, [token]);

    const startStream = e => {
        let { pathname } = location;
        if (pathname === "/staff/streams") {
            navigate('/staff/start-stream', { state: { sid: e.target.id } });
        } else {
            navigate('/str/start-stream', { state: { sid: e.target.id } });
        }
    }
    return (
        <div className="addvertiser-header">
            {loading && <Loader />}
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile ">
                        <div className="advertiser_heading">
                            <h4>Stream</h4>
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="place-ads basicplan streamingtabing">
                                    <Tabs justify variant="pills" defaultActiveKey="tab-1" className="tabs-items">
                                        <Tab eventKey="tab-1" title="Approved List">
                                            <div className="place-ads-table">
                                                <div className="table-haeding">
                                                    <h6>Video Library List</h6>
                                                </div>
                                                <DataTables
                                                    columns={columns}
                                                    data={streams.approved}
                                                />
                                            </div>
                                        </Tab>
                                        <Tab eventKey="tab-2" title="Unapproved List">
                                            <div className="place-ads-table">
                                                <div className="table-haeding">
                                                    <h6>Video Library List</h6>
                                                </div>
                                                <DataTables
                                                    columns={columns}
                                                    data={streams.unapproved}
                                                />
                                            </div>
                                        </Tab>
                                        <Tab eventKey="tab-3" title="Paid List">
                                            <div className="place-ads-table">
                                                <div className="table-haeding">
                                                    <h6>Video Library List</h6>
                                                </div>
                                                <DataTables
                                                    columns={columns}
                                                    data={streams.paid}
                                                />
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}


export default StreamTable;