import React from 'react';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Nav, Dropdown } from "react-bootstrap";
import { getWithToken } from "../../variables/apiCall";
import { useAuth } from "../../auth/Auth";
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Notifications() {
    var auth = useAuth();
    let token = auth.getToken();
    const [notiList, setNotiList] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            var res = await getWithToken('/api/notification', token);
            if (res.status === 200) {
                setNotiList(res.data);
            }
        }
        fetchData();
    }, [token]);
    const viewMore = () => {
        var user = auth.user;
        var link = "";
        if (user) {
            if (user.user_type === 1) {
                link = "/str/notifications";
            } else if (user.user_type === 2) {
                link = "/adv/notifications";
            } else {
                link = "/view/notifications";
            }
        }
        return <Link to={link}>View More</Link>;
    }
    return (

        <Nav>
            <Dropdown className="">
                <Dropdown.Toggle className='title-dropdown'>
                    <FontAwesomeIcon icon={faBell} className="bellicon" />
                </Dropdown.Toggle>
                <Dropdown.Menu className='notificataion-drop'>
                    {notiList && notiList.user.length > 0 && (
                        <div className='firstnotification'>
                            <div className="h6-title-noti">
                                <h6>User Notifications</h6>
                                {viewMore()}
                            </div>
                            <div>
                                <ul>
                                    {notiList.user.map((not, index) => {
                                        if (index < 3) {
                                            return (
                                                <div key={not.id} >
                                                    <h5>{not.title}</h5>
                                                    <p>{not.sort_description}</p>
                                                </div>
                                            )
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                    {notiList && notiList.systemnotification.length > 0 && (
                        <div className='firstnotification'>
                            <div className="h6-title-noti">
                                <h6>System Notifications</h6>
                                {viewMore()}
                            </div>
                            <div>
                                <ul>
                                    {notiList.systemnotification.map((not, index) => {
                                        if (index < 3) {
                                            return (
                                                <div key={not.id} >
                                                    <h5>{not.title}</h5>
                                                    <p>{not.sort_description}</p>
                                                </div>
                                            )
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                </Dropdown.Menu>


            </Dropdown>
        </Nav>

    )
}

export default Notifications
