import React, { useRef, useState } from 'react';
import { Button, Col, Form, Image, Modal, Row, Spinner } from 'react-bootstrap';
import Save from "./../../assets/img/save.png"
import ModalProfile from "./../../assets/img/user-icon.png";
import ModalMessage from "./../../assets/img/message.png";
import ModalLike from "./../../assets/img/like.png";
import ModalShare from "./../../assets/img/share.png";
import { getUrl } from '../../Helper';
import swal from 'sweetalert';
import { getWithToken, postWithToken } from '../../variables/apiCall';
import { useAuth } from '../../auth/Auth';
import { toast } from 'react-toastify';
import ShareModal from './ShareModal';

const btnStyle = {
    backgroundColor: 'transparent',
    border: 'none'
}

export default function ShowAdsModal({ show, data, handleClose }) {
    const auth = useAuth();
    let token = auth.getToken();
    const videoRef = useRef(null);
    const [showVideoLoader, setShowVideoLoader] = useState(false);
    const [video, setVideo] = useState(false);
    const [sharePopup, setSharePopup] = useState(false)
    const [showSur, setShowSur] = useState(false);
    const [curQue, setCurQue] = useState(0);
    const [curAns, setCurAns] = useState(0);
    const [surErr, setSurErr] = useState("");
    const [survey, setSurvey] = useState([]);
    const [surveyDone, setSurveyDone] = useState(false);
    const [que, setQue] = useState([]);
    let ad = data.length > 0 ? data[0] : null;

    const playAd = async (e) => {
        setShowVideoLoader(true);
        await postWithToken('/api/impression', token, { ad_id: ad.id });
        setVideo(true);
    }
    const close = () => {
        setVideo(false);
        setSurveyDone(false);
        handleClose();
    }
    const goToWeb = async (e) => {
        await postWithToken('/api/click_count', token, { ad_id: ad.id });
        window.open(ad.website_link, "_blank");
    }
    const surve = e => {
        e.preventDefault();
        if (video) {
            if (!surveyDone) {
                videoRef.current.pause();
                setQue(ad.questions);
                setShowSur(true)
            } else {
                toast.info("You already answered survey");
            }
        } else {
            toast.info("Please Start Advertisement.");
        }
    }
    const saveQueAns = async () => {
        var nq = curQue + 1;
        if (nq === que.length) {
            if (curAns) {
                var arr = [
                    ...survey,
                    {
                        "question_id": que[curQue].id,
                        "option_id": curAns
                    }
                ];
                var reqData = {
                    ad_id: ad.id,
                    data: arr
                }
                var res = await postWithToken('/api/add_survey', token, reqData);
                if (res.status === 200) {
                    toast.success(res.message);
                    videoRef.current.play();
                    setSurvey([]);
                    setSurveyDone(true);
                    setShowSur(false);
                    setCurQue(0)
                    setCurAns(0)
                    setSurErr("");
                }
            } else {
                setSurErr("Please Choose Answer")
            }
        } else {
            if (curAns) {
                setSurvey([...survey, {
                    "question_id": que[curQue].id,
                    "option_id": curAns
                }]);
                setSurErr("");
                setCurAns(0);
                setCurQue(nq);
            } else {
                setSurErr("Please Choose Answer")
            }
        }
    }
    const likeUpdate = async () => {
        var res = await getWithToken('/api/posted_ad_like/' + ad.id, token)
        if (res.status === 200) {
            swal("Good Job", res.message, "success");
        }
    }
    const share = () => {
        setSharePopup(true);
    }
    const getBtn = () => {
        var n = curQue + 1;
        var btn = "Next";
        if (n === que.length) {
            btn = "Submit"
        }
        return <Button onClick={() => { saveQueAns() }} className=" mt-5 upload-appoved">{btn}</Button>;
    }
    const saveAds = async () => {
        var res = await postWithToken('/api/user_save_ad', token, { ad_id: ad.id });
        if (res.status === 200) {
            toast.success(res.message);
        }
    }
    const saveBtn = () => {
        if (surveyDone) {
            return null
        }
        return (
            <div className="modal-money">
                <div className="save-icon">
                    <Image src={Save} />
                </div>
                <Button className="money-button" onClick={saveAds}>
                    Submit and save to make extra money
                </Button>
            </div>
        )
    }
    const shareClose = (pop = false) => {
        setSharePopup(false);
        if (pop)
            swal("Good Job", "Shared successfully.", "success");
    }
    const handleLoadedData = () => {
        setShowVideoLoader(false);
    }
    if (ad === null) {
        return false;
    }
    return (
        <Modal backdrop="static" keyboard={false} show={show} onHide={close} animation={false} size="lg" centered >
            {ad.website_link &&
                <div className="modal-profile">
                    <Button style={btnStyle} onClick={goToWeb}>
                        <Image src={ModalProfile} />
                    </Button>
                </div>
            }
            <div className="modal-message">
                <Button style={btnStyle} onClick={surve}>
                    <Image src={ModalMessage} />
                </Button>
            </div>
            {ad.share_by_user === "yes" &&
                <div className="modal-like">
                    <Button style={btnStyle} onClick={likeUpdate}>
                        <Image src={ModalLike} />
                    </Button>
                </div>
            }
            {ad.user_confirm_view === "yes" &&
                <div className="modal-share">
                    <Button style={btnStyle} onClick={share}>
                        <Image src={ModalShare} />
                    </Button>
                </div>
            }
            <Modal.Body className='modal-position'>
                {showSur &&
                    <div className='positionaboutte surveycard'>
                        <h1>Start Survey </h1>
                        <div className="verification-radio mt-2">
                            <Form.Label className='questionlabel'>{que[curQue].question_text}</Form.Label>
                            <Row>
                                {que[curQue].options.map((ele) => (
                                    <Col md={6}>
                                        <Form.Check
                                            type={"radio"}
                                            checked={ele.id === curAns ? true : false}
                                            onClick={() => { setCurAns(ele.id) }}
                                            name={`ans-${que[curQue].id}`}
                                            label={ele.option_text}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            {surErr && <p className='form-error'>{surErr}</p>}
                            {/* <Row>
                                <Col md="12 mt-4">
                                    <Form.Group className="mb-4">
                                        <FloatingLabel controlId="floatingTextarea2">
                                            <Form.Control
                                                label="Leave a Comment"
                                                as="textarea"
                                                placeholder="Leave a comment here"
                                                style={{ height: '100px' }}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                            </Row> */}
                        </div>
                        {getBtn()}
                    </div>
                }
                {video ? (
                    <>
                        <div className="login-heading modal-detail mt-0">
                            <div className="modal-servey-image mt-0">
                                {showVideoLoader && (<Spinner animation="border" />)}
                                <video
                                    onEnded={close}
                                    onCanPlay={handleLoadedData}
                                    ref={videoRef}
                                    src={getUrl(ad.ads.video_url)}
                                    autoPlay={true} />
                            </div>
                        </div>
                        {saveBtn()}
                    </>
                )
                    : (
                        <>
                            <div className="login-heading modal-detail">
                                <div className="modal-servey-image">
                                    {ad && <Image src={getUrl(ad.ads.thumbnail_url)} />}
                                    <p>{ad && ad.ads.title}</p>
                                </div>
                            </div>
                            <div className="modal-advertisement modal-movie-watch mt-2 mb-5">
                                <Button onClick={playAd} className="banner-button mt-3">Advertisement Start</Button>
                            </div>
                            {saveBtn()}
                        </>
                    )
                }

            </Modal.Body>
            <ShareModal show={sharePopup} sc={shareClose} id={ad.id} token={token} />
        </Modal>
    )
}
