import React from "react";
import { Route, Routes } from "react-router-dom";
import dashRoutes from "../routes";
import StreamerFooter from "../components/Footer/streamer_footer";
import { RequireAuth } from "../auth/RequireAuth";
import { useAuth } from "../auth/Auth";
import Loader from "../components/Loader";
import Error404 from "../components/Errors/Error404";
import StaffHeader from "../components/Header/StaffHeader";

export default function Staff() {
    const auth = useAuth();
    let user = auth.user;
    const getRoutes = (routes) => {
        if (user === null) {
            return <Route path="*" element={<Loader />} />
        }
        if (user.user_type === "4") {
            return routes.map((prop, key) => {
                if (prop.layout === "/staff") {
                    return (
                        <Route
                            path={prop.path}
                            element={prop.component}
                            key={key}
                        />
                    );
                }
                return false;
            });
        } else {
            return <Route path="*" element={<Error404 />} />
        }
    };
    return (
        <React.Fragment>
            <RequireAuth>
                <StaffHeader />
                <StreamerFooter />
                <Routes>
                    {getRoutes(dashRoutes)}
                </Routes>
            </RequireAuth>
        </React.Fragment>
    );
}