import React, { useRef, useEffect } from 'react';
import SliderContext from "./context"
import useSlider from "../../hooks/useSlide"
import useWindowDimensions from '../../variables/useWindowDimensions';
import SeeAllItem from '../SliderItem/seeAllItem';

function SeeAllGrid({ data, fun }) {
    const { width } = useWindowDimensions();
    const ref = useRef(null)
    const {
        moveSection,
        scaleTiles,
        resetSize,
        sliderPages,
        slideProps,
        hasPrev,
        hasNext,
        content,
        currentSlide,
        paginationIndicator
    } = useSlider(width, ref, 2, data);
    const contextValue = {
        currentSlide
    };

    useEffect(() => { }, [width])
    return (
        <SliderContext.Provider value={contextValue}>
            <div className="sliderBox">
                <div className="slider-container">
                    <div className="slider px-0">
                        <div className="sliderMask showPeek">
                            <div className={`slider-content ${currentSlide ? "open" : ""}`} ref={ref} {...slideProps} >
                                {content && content.map(item => {
                                    return <div className='coll-itemss'><SeeAllItem
                                        key={item.id}
                                        title={item.id}
                                        data={item}
                                        hover={scaleTiles}
                                        reset={resetSize}
                                        transform={item.transform}
                                        origin={item.origin}
                                        fun = {fun}
                                    />
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SliderContext.Provider>
    );
}

export default SeeAllGrid;
