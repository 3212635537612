import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Loader from "../../components/Loader";
import QRCode from "react-qr-code";
import { useEffect } from "react";
import { useAuth } from "../../auth/Auth";
import { getWithToken } from "../../variables/apiCall";


function Track() {
    const auth = useAuth();
    let token = auth.getToken();
    const [loading, setLoading] = useState(false);
    const [planId, setPlanId] = useState(null);
    const [enterMsgVal, setEnterMsgVal] = useState("");
    const [purchaseMsgVal, setPurchaseMsgVal] = useState("");
    const [locError, setLocError] = useState("");
    const [plans, setPlans] = useState([]);
    let initialLocation = {
        val: ""
    }
    const [locations, setLocations] = useState([initialLocation]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                var res = await getWithToken('/api/locationplan_list', token);
                if (res.status === 200) {
                    setPlans(res.data);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        fetchData();
    }, [token]);
    const planSelect = (e) => {
        setPlanId(e.target.id);
    }
    const addMore = () => {
        if (planId) {
            setLocError("")
            var arr = plans.find(i => {
                return i.id == planId;
            });
            var loCount = locations.length;
            if (arr.location_quantity > loCount)
                setLocations([...locations, initialLocation]);
            else
                setLocError("Maximum limit is reached. Please upgrade plan.");
        } else {
            setLocError("please selct plan first.")
        }
    }
    const handleChange = (i, e) => {
        let newlocations = [...locations];
        newlocations[i][e.target.name] = e.target.value;
        setLocations(newlocations);
    }
    return (
        <div className="addvertiser-header">
            {loading && <Loader />}
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Track & Confirm</h4>
                        </div>
                        <Row>
                            <Col md={12} lg={10}>
                                <div className="track-bg">
                                    <div className="track_heading">
                                        <h6>Selct Location Plan</h6>
                                    </div>
                                    <div className="track-plan login-form">
                                        <Form>
                                            <Row>
                                                <Col md={12}>
                                                    <Row>
                                                        {plans && plans.map((el, i) => (
                                                            <Col key={i}>
                                                                <div className={el.id == planId ? "track-plan-item active mb-4" : "track-plan-item mb-4"}>
                                                                    <div className="track-plan-number">
                                                                        <p>{el.name}</p>
                                                                    </div>
                                                                    <div className="track-plan-location">
                                                                        <p>{"0 - " + el.location_quantity + " locations"}</p>
                                                                    </div>
                                                                    <div className="track-plan-amount">
                                                                        <p>{el.price}</p>
                                                                    </div>
                                                                    <Button type="button" id={el.id} onClick={planSelect} className="planbtn-button">
                                                                        Select
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    {locError && <p className="form-error">{locError}</p>}
                                                </Col>
                                                <Col md={12}>
                                                    {locations && locations.map((ele, i) => (
                                                        <Form.Group key={i} className="mb-4">
                                                            <Form.Label>Enter Location</Form.Label>
                                                            <Form.Control value={ele.val || ""} onChange={e => handleChange(i, e)} type="text"></Form.Control>
                                                        </Form.Group>
                                                    ))}
                                                    <div onClick={addMore} className="advertiser-add-location">
                                                        <p>Add more <span>
                                                            <FontAwesomeIcon icon={faPlus} />
                                                        </span></p>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-4 user-price">
                                                        <p>Price/User = $1</p>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label>Select Radius</Form.Label>
                                                        <Form.Select aria-label="Default select example" >
                                                            <option value={1}>1 km</option>
                                                            <option value={2}>2 km</option>
                                                            <option value={5}>5 km</option>
                                                            <option value={10}>10 km</option>
                                                            <option value={20}>20 km</option>
                                                            <option value={50}>50 km</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label>Radius Message </Form.Label>
                                                        <FloatingLabel controlId="floatingTextarea2">
                                                            <Form.Control
                                                                as="textarea"
                                                                placeholder="Leave a comment here"
                                                                style={{ height: '100px' }}
                                                            />
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="track_heading mt-4 mb-5">
                                                        <h6>Scan Code</h6>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={6} className="mb-4">
                                                    <div className="qr-code">
                                                        <div className="qr-details">
                                                            <p>Please scan the QR CODE while entering</p>
                                                            <div className="qr-code-code">
                                                                <QRCode
                                                                    size={180}
                                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                    value={enterMsgVal} />
                                                            </div>
                                                            <Link to="">
                                                                <div className="share">
                                                                    <FontAwesomeIcon icon={faShareAlt} />
                                                                    <p>Share Code</p>
                                                                </div>
                                                                <span>Share with Store Manager</span>
                                                            </Link>
                                                            <Form.Group>
                                                                <Form.Label className="d-flex justify-content-strat">Qr Code Message </Form.Label>
                                                                <FloatingLabel controlId="floatingTextarea2">
                                                                    <Form.Control
                                                                        value={enterMsgVal}
                                                                        onChange={e => setEnterMsgVal(e.target.value)}
                                                                        as="textarea"
                                                                        placeholder="Leave a comment here"
                                                                        style={{ height: '100px' }}
                                                                    />
                                                                </FloatingLabel>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={6} className="mb-4">
                                                    <div className="qr-code">
                                                        <div className="qr-details">
                                                            <p> Please scan the QR CODE while purchase</p>
                                                            <div className="qr-code-code">
                                                                <QRCode
                                                                    size={180}
                                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                    value={purchaseMsgVal} />
                                                            </div>
                                                            <Link to="">
                                                                <div className="share">
                                                                    <FontAwesomeIcon icon={faShareAlt} />
                                                                    <p>Share Code</p>
                                                                </div>
                                                                <span>Share with Store Manager</span>
                                                            </Link>
                                                            <Form.Group>
                                                                <Form.Label className="d-flex justify-content-strat">Qr Code Message </Form.Label>
                                                                <FloatingLabel controlId="floatingTextarea2">
                                                                    <Form.Control
                                                                        value={purchaseMsgVal}
                                                                        onChange={e => setPurchaseMsgVal(e.target.value)}
                                                                        as="textarea"
                                                                        placeholder="Leave a comment here"
                                                                        style={{ height: '100px' }}
                                                                    />
                                                                </FloatingLabel>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={12} className="mt-4 mb-3">
                                                    <button type="button" className="login-submit-button mt-md-5 mt-0 btn btn-primary">Pay Now & Share</button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}


export default Track;