import React from 'react'
import { useState } from 'react'
import { Button, Col, Image, Modal, Row } from 'react-bootstrap'
import swal from 'sweetalert'
import { useAuth } from '../../auth/Auth'
import { getUrl } from '../../Helper'
import CloseModal from "./../../assets/img/close.png"
import PaypalModal from './PaypalModal'

export default function PpvModal({ video, show, modalClose, vType }) {
    const auth = useAuth();
    let token = auth.getToken();
    const [paypal, setPaypal] = useState(false);
    const close = () => {
        modalClose();
    }
    const getPlanText = (type) => {
        var txt = "";
        if (type === 1) {
            txt = " For One Time View";
        } else if (type === 2) {
            txt = " For Seven Days";
        } else if (type === 3) {
            txt = " For Monthly";
        }
        return txt;
    }
    const purchasePlan = () => {
        setPaypal(true);
    }
    const payClose = (payment = false) => {
        setPaypal(false);
        if (payment) {
            swal("Good Job", "Now you can access this video.", "success").then(val => {
                modalClose(true);
            })
        }
    }
    if (Object.keys(video).length === 0) {
        return null;
    }
    return (
        <Modal
            show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="modal-close">
                <Button type="button" onClick={close}><Image src={CloseModal} /></Button>
            </div>
            <Modal.Body>
                <div className="login-heading modal-detail">
                    <div className="modal-heading">
                        <h4>PPV (Pay Per View) </h4>
                    </div>
                    <div className="modal-image">
                        <Image src={getUrl(video.main_thumbnail_url)} />
                        <p>{video.title}</p>
                    </div>
                </div>
                {video.streamerPlan && (
                    <div className="plan-choose">
                        <Row>
                            <Col md={10} className="mx-auto">
                                <div className="plan-choose1 active mb-4">
                                    <div className="plan-details">
                                        <div className="plan-days">
                                            <h6>{video.streamerPlan.title}</h6>
                                        </div>
                                        <div className="plan-price">
                                            <h6>${video.streamerPlan.price + getPlanText(video.streamerPlan.ppv_plan_type)}</h6>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button className="banner-button mt-3" onClick={purchasePlan}>Proceed To Pay</Button>
            </Modal.Footer>
            {video.streamerPlan &&
                <PaypalModal
                    show={paypal}
                    amnt={video.streamerPlan.price}
                    pc={payClose}
                    plan={video.streamerPlan.id}
                    token={token}
                    type={"ppv"}
                    vType={vType}
                    vId={video.id}
                />
            }
        </Modal>
    )
}
