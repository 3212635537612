import React from "react";
// import { Link } from "react-router-dom";
import HomeFooter from "../components/Footer/home_footer";
import HomeHeader from "../components/Header/home_header";
import HomeSlider from "../components/Home/home-slider";
import MovieSlider from "../components/Home/movie-slider";
import TvShows from "../components/Home/tv-shows";
import LiveTv from "../components/Home/live-tv";
import UpcomingTv from "../components/Home/upcoming-live";
import NetworkTv from "../components/Home/network-tv";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }
    render() {
        return (
            <div className="out-homejs">
                <div className="overfilo-div">
                    <HomeHeader />
                    <HomeSlider />
                    <MovieSlider />
                    <TvShows />
                    <LiveTv />
                    <UpcomingTv />
                    <NetworkTv />
                    <HomeFooter />
                </div>
            </div>
        );
    }
}

export default Home