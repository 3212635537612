import React, { useState } from 'react'
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { postWithToken } from '../../variables/apiCall';
import { StaffSchema } from '../../variables/validation_schema';
import { useAuth } from '../../auth/Auth';
import CloseModal from "./../../assets/img/close.png"
import swal from 'sweetalert';
import { useEffect } from 'react';

export default function StaffModal({ show, onhide, sdata }) {
    const [resError, setResError] = useState("");
    const auth = useAuth();
    const [edit, setEdit] = useState(false);
    let token = auth.getToken();
    const { register, handleSubmit, setValue, clearErrors, formState: { errors }, } = useForm({
        resolver: yupResolver(StaffSchema),
    });
    const onSubmit = async (data) => {
        var res;
        if (edit) {
            res = await postWithToken('/api/streamer_staff_update/' + sdata.id, token, data);
        } else {
            res = await postWithToken('/api/streamer_staff_add', token, data);
        }
        if (res.status === 200) {
            setResError("");
            setValue("name", "");
            setValue("mobile", "");
            setValue("email", "");
            setValue("password", "");
            setValue("cpassword", "");
            swal("Good job!", res.message, "success");
            onhide();
        } else {
            setResError(res.message);
        }
    }
    useEffect(() => {
        if (Object.keys(sdata).length > 0) {
            setEdit(true);
            setValue("name", sdata.name);
            setValue("mobile", sdata.mobile);
            setValue("email", sdata.email);
        } else {
            clearErrors();
            setEdit(false);
            setValue("name", "");
            setValue("mobile", "");
            setValue("email", "");
            setValue("password", "");
            setValue("cpassword", "");
        }
    }, [sdata])

    return (
        <Modal show={show} onHide={onhide} animation={false} size="md" centered className='modla-add-staff' >
            <div className="modal-close">
                <Button className='p-0' type="button" onClick={onhide}><Image src={CloseModal} /></Button>
            </div>
            <Modal.Header className=' pb-2 p-0'>
                <Modal.Title className='titlh4'>{edit ? "Update Staff" : "Add Staff"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-position mt-4'>
                
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="profile-form login-form mt-0">
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Enter Staff Name</Form.Label>
                                        <Form.Control {...register('name')} type="text"></Form.Control>
                                        {errors.name && <p className='form-error'>{errors.name.message}</p>}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Contact Number</Form.Label>
                                        <Form.Control {...register('mobile')} type="text"></Form.Control>
                                        {errors.mobile && <p className='form-error'>{errors.mobile.message}</p>}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control readOnly={edit} {...register('email')} type="email"></Form.Control>
                                        {errors.email && <p className='form-error'>{errors.email.message}</p>}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Enter password</Form.Label>
                                        <Form.Control {...register('password')} type="password"></Form.Control>
                                        {errors.password && <p className='form-error'>{errors.password.message}</p>}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Enter Confirm password</Form.Label>
                                        <Form.Control {...register('cpassword')} type="password"></Form.Control>
                                        {errors.cpassword && <p className='form-error'>{errors.cpassword.message}</p>}
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    {resError && <p className='form-error'>{resError}</p>}
                                    <button type="submit" className="login-submit-button mt-4 btn btn-primary">{edit ? "Update" : "Add"}</button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
             
            </Modal.Body>
        </Modal>
    )
}
