import React from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { Image } from "react-bootstrap";
import stremermovie from "./../../assets/img/stremermovie.png";
import arrow1 from "./../../assets/img/arrow1.png";
import arrow2 from "./../../assets/img/arrow2.png";
import arrow3 from "./../../assets/img/arrow3.png";
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, ArcElement, Legend, LinearScale, Title, Tooltip, Chart as ChartJS, Colors } from "chart.js";
import DoughnutChart from "../../components/Chart/DoughnutChart";
import { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../../auth/Auth";
import { getWithToken } from "../../variables/apiCall";
import { Skeleton } from "@mui/material";
import useWindowDimensions from "../../variables/useWindowDimensions";

function Analytic() {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        Colors,
        ArcElement
    );
    let { height } = useWindowDimensions();
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
            },
        },
    };
    const auth = useAuth();
    let token = auth.getToken();
    const [loading, setLoading] = useState({
        secGen: false,
        secMar: false,
        secReg: false,
        secSal: false,
        secAge: false
    });
    const [genderData, setGenderData] = useState({});
    const [maritalData, setMaritalData] = useState({});
    const [religinLabel, setReliginLabel] = useState([]);
    const [salLabel, setSalLabel] = useState([]);
    const [ageLabel, setAgelabel] = useState([]);
    const [religinData, setReliginData] = useState([]);
    const [salData, setSalData] = useState([]);
    const [ageData, setAgeData] = useState([]);
    const data = {
        labels: religinLabel,
        datasets: [
            {
                Color: 'white',
                borderRadius: '100',
                barPadding: '0.5',
                borderSkipped: false,
                label: 'Religin',
                data: religinData,
                backgroundColor: '#FFB91D',
                borderColor: 'white',
                borderWidth: '0',
                columnWidth: '20'
            },
        ],
    };

    const changeLoad = (sec, val) => {
        var l = loading;
        l[sec] = val;
        setLoading(l);
    }
    useEffect(() => {
        const fetchData = async () => {
            changeLoad("secReg", true);
            var fres = await getWithToken("/api/user_religion_count", token);
            changeLoad("secReg", false);
            if (fres.status === 200) {
                setReliginLabel(fres.data.labels);
                setReliginData(fres.data.values)
            }
        }
        fetchData();
    }, [token])
    useEffect(() => {
        const fetchData = async () => {
            changeLoad("secSal", true);
            var sres = await getWithToken("/api/user_salary_count", token);
            changeLoad("secSal", false);
            if (sres.status === 200) {
                setSalLabel(sres.data.keys);
                setSalData(sres.data.values)
            }
        }
        fetchData();
    }, [token])
    useEffect(() => {
        const fetchData = async () => {
            changeLoad("secAge", true);
            var tres = await getWithToken("/api/user_age_count", token);
            changeLoad("secAge", false);
            if (tres.status === 200) {
                setAgelabel(tres.data.keys);
                setAgeData(tres.data.values)
            }
        }
        fetchData();
    }, [token])
    useEffect(() => {
        const fetchData = async () => {
            changeLoad("secGen", true);
            var fres = await getWithToken("/api/user_gender_count", token);
            changeLoad("secGen", false);
            if (fres.status === 200) {
                setGenderData(fres.data);
            }
        }
        fetchData();
    }, [token])
    useEffect(() => {
        const fetchData = async () => {
            changeLoad("secMar", true);
            var res = await getWithToken("/api/user_marital_count", token);
            changeLoad("secMar", false);
            if (res.status === 200) {
                console.log(res.data);
                setMaritalData(res.data);
            }
        }
        fetchData();
    }, [token])
    return (
        <div className="addvertiser-header">
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Analytic</h4>
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="place-ads-table">
                                    <div className="streamermovie about-anylituc mb-4">
                                        <div className="stremerimg">
                                            <Image src={stremermovie} />
                                        </div>
                                        <div className="titlestremer">
                                            <h4 className="mb-3">Lost In Space</h4>
                                            <p>The mission to save Scarecrow takes an unexpected turn, throwing the Resolute into chaos. Judy hatches a plan to get a ship to Alpha a. The mission to save Scarecrow takes an unexpected turn, throwing the Resolute into chaos. Judy hatches a plan to get a ship to Alpha Centauri.</p>
                                        </div>
                                    </div>
                                    <Row className="mb-4">
                                        <Col sm="4" className="mb-md-0 mb-4">
                                            <div className="no-revenue">
                                                <Image src={arrow1} />
                                                <h3>Revenues</h3>
                                                <p>$ 13,500</p>
                                            </div>
                                        </Col>
                                        <Col sm="4" className="mb-md-0 mb-4">
                                            <div className="no-revenue">
                                                <Image src={arrow2} />
                                                <h3>Revenues</h3>
                                                <p>$ 13,500</p>
                                            </div>
                                        </Col>
                                        <Col sm="4" className="mb-md-0 mb-4">
                                            <div className="no-revenue">
                                                <Image src={arrow3} />
                                                <h3>Revenues</h3>
                                                <p>$ 13,500</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" className="mb-4">
                                            <div className="out-chartall bbar-chart">
                                                <h4>Religion</h4>
                                                {loading.secReg ? <Skeleton variant="rectangular" width={"100%"} height={(.25 * height)} style={{ backgroundColor: "#ffffff17" }} /> :
                                                    <Bar options={options} data={data} />}
                                            </div>
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <Row>
                                                <Col md="12" className="mb-3">
                                                    {Object.keys(genderData).length > 0 ?
                                                        <div className="out-chartall mb-4">
                                                            <h4>Gender</h4>
                                                            <div className="progresbar-outer mb-4">

                                                                <div className="pro-anlytic">
                                                                    <p>{genderData.female}%</p>
                                                                    <ProgressBar className="bluecolor" variant="success" now={genderData.female} />
                                                                </div>
                                                                <h6>Female</h6>
                                                            </div>
                                                            <div className="progresbar-outer mb-4">

                                                                <div className="pro-anlytic">
                                                                    <p>{genderData.male}%</p>
                                                                    <ProgressBar className="greencolor" variant="success" now={genderData.male} />
                                                                </div>
                                                                <h6>Male</h6>
                                                            </div>
                                                            <div className="progresbar-outer">

                                                                <div className="pro-anlytic">
                                                                    <p>{genderData.other}%</p>
                                                                    <ProgressBar className="graycolor" variant="success" now={genderData.other} />
                                                                </div>
                                                                <h6>Other gender</h6>
                                                            </div>
                                                        </div>
                                                        : <Skeleton variant="rectangular" width={"100%"} style={{ backgroundColor: "#ffffff17" }} />}
                                                    {Object.keys(maritalData).length > 0 ?
                                                        <div className="out-chartall">
                                                            <h4>Marital Status</h4>
                                                            <div className="progresbar-outer mb-4">
                                                                <div className="pro-anlytic">
                                                                    <p>{maritalData.married}%</p>
                                                                    <ProgressBar className="bluecolor" variant="success" now={maritalData.married} />
                                                                </div>
                                                                <h6>Married</h6>
                                                            </div>
                                                            <div className="progresbar-outer mb-4">
                                                                <div className="pro-anlytic">
                                                                    <p>{maritalData.unmarried}%</p>
                                                                    <ProgressBar className="greencolor" variant="success" now={maritalData.unmarried} />
                                                                </div>
                                                                <h6>Unmarried</h6>
                                                            </div>
                                                        </div>
                                                        : <Skeleton variant="rectangular" width={"100%"} height={(.44 * height)} style={{ backgroundColor: "#ffffff17" }} />}
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col md="6" className="mb-3">
                                            <div className="out-chartall mb-4">
                                                <h4>Salary</h4>
                                                {loading.secSal ? <Skeleton variant="rectangular" width={"100%"} height={(.5 * height)} style={{ backgroundColor: "#ffffff17" }} /> :
                                                    <DoughnutChart labels={salLabel} data={salData} text={"Salary"} />}
                                            </div>
                                        </Col>
                                        <Col md="6" className="mb-3">
                                            <div className="out-chartall mb-4">
                                                <h4>Age</h4>
                                                {loading.secAge ? <Skeleton variant="rectangular" width={"100%"} height={(.5 * height)} style={{ backgroundColor: "#ffffff17" }} /> :
                                                    <DoughnutChart labels={ageLabel} data={ageData} text={"Age"} />}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}


export default Analytic;