import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoginForm from "../../components/Form/login";

class SignIn extends React.Component {
    render() {
        return (
            <div className="login-form-layout">
                <Container>
                    <Row>
                        <Col md={8} sm={10} xs={12} xl={5} lg={6} className="mx-auto">
                            <div className="login">
                                <div className="login-heading">
                                    <h4>Login</h4>
                                    <p>Login to continue enjoying uninterrupted video and <br></br>personalised experience.</p>
                                </div>
                                <div className="login-form">
                                    <LoginForm />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default SignIn;