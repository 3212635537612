import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import { getWithToken } from "../../variables/apiCall";
import DataTables from "../../components/DataTables";
import { useAuth } from "../../auth/Auth";
import Loader from "../../components/Loader";
import { getUrl } from "../../Helper";


function PlaceAds() {
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();
    const editBtnHandler = (e) => {
        var id = e.target.id;
        navigate("/adv/placeadstep", { state: { id: id } });
    }
    const columns = [
        {
            name: "Video Ad",
            cell: (row) => (
                <div className="video-ad"><Image width={"100px"} src={getUrl(row.add_thumbnail_url)} /><p>{row.add_title}</p></div>
            )
        },
        {
            name: "Viewed",
            selector: row => row.view,
        },
        {
            name: "Impressions",
            selector: row => row.impression,
        },
        {
            name: "Clicks",
            selector: row => row.clicks,
        },
        {
            name: "Survey",
            selector: row => row.survey,
        },
        {
            name: "Video On Which Ad Placed",
            selector: row => (
                <div className="video-ad"><Image width={"100px"} src={getUrl(row.video_thumbnail_url)} /><p>{row.video_title}</p></div>
            )
        },
        {
            name: "Action",
            cell: (row) => (
                <Button type="button" className="action-button" id={row.id} onClick={editBtnHandler} >Edit</Button>
            )
        },
        {
            name: "Status",
            cell: (row) => (
                <Button type="button" className={row.status ? "action-button" : "inactive-button"}>{row.status ? "active" : "Inactive"}</Button>)
        }
    ];
    const [data, setData] = useState([]);
    var token = auth.getToken();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                var response = await getWithToken('/api/posted_ads_list', token);
                setLoading(false)
                if (response.status === 200) {
                    setData(response.data);
                }
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [token])
    return (
        <div className="addvertiser-header">
            {loading && <Loader />}
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Place Ad</h4>
                            {/* <Link to="" className="submit-button">Place a new ad.</Link> */}
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="place-ads">
                                    <div className="place-ads-table">
                                        <div className="table-haeding">
                                            <h6>List of Played Ads/New ads</h6>
                                        </div>
                                        <DataTables
                                            columns={columns}
                                            data={data}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}


export default PlaceAds;