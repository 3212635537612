import React, { useState } from "react";
import { Container, Row, Col, Button, Image, Form, Modal } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import message from "../../assets/img/message-post.png"
import like from "../../assets/img/likelike.png"
import share from "../../assets/img/share-share.png"
import links from "../../assets/img/links.png"
import lisksrotate from "../../assets/img/lisksrotate.png"
import commentss from "../../assets/img/commentss.png"
import sharerotate from "../../assets/img/sharerotate.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faEdit, faTrash, } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import closeicon from "../../assets/img/cloase-icon.png";
import { useAuth } from "../../auth/Auth";
import { useEffect } from "react";
import { getWithToken, postWithToken } from "../../variables/apiCall";
import { BaseUrl } from "../../variables/general";
import StreamerVideo from "../../components/modal/StreamerVideo";
import swal from "sweetalert";
import Loader from "../../components/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PlaceAdsStepTwo } from "../../variables/validation_schema";
import { Slider } from "@mui/material";



const ActionButtons = (props) => {
    const handleBack = () => {
        props.previousStep();
    };

    const handleNext = () => {
        props.nextStep();
    };

    const handleFinish = () => {
        props.lastStep();
    };
    return (
        <div>
            <div className="d-flex justify-content-between mt-5 pt-4">
                {props.currentStep > 1 && (
                    <Button className="login-submit-button" onClick={handleBack}>Back</Button>

                )}
                {props.currentStep < props.totalSteps && (
                    <Button className="login-submit-button" onClick={handleNext}>Next</Button>
                )}
                {props.currentStep === props.totalSteps && (
                    <Button className="login-submit-button" onClick={handleFinish}>Finish</Button>
                )}
            </div>
        </div>
    );
};

const One = (props) => {
    const [webLink, setWebLink] = useState("");
    const [shareUser, setShareUser] = useState(false);
    const [viewUser, setViewUser] = useState(false);
    const [linkError, setLinkError] = useState("");
    const [resError, setResError] = useState("");
    const [queEdit, setQueEdit] = useState(null);
    const [qError, setQError] = useState("");
    const [oError, setoError] = useState("");
    const [queTxt, setQueTxt] = useState("");
    const [type, setType] = useState("video");
    const [adData, setAdData] = useState({});
    const [adOnVideo, setAdOnVideo] = useState(null);
    const [adSlot, setAdSlot] = useState(1);
    var blankOption = [
        { text: "" },
        { text: "" },
        { text: "" },
        { text: "" }
    ]

    const [option, setOption] = useState(blankOption);
    const [fillQue, setFillQue] = useState([]);
    const [show, setShow] = useState(false);
    const [videoShow, setVideoShow] = useState(false);
    if (props.adDetails) {
        if (adData !== props.adDetails) {
            var detail = props.adDetails;
            setAdData(detail)
            setWebLink(detail.website_link)
            setAdOnVideo(detail.video_id)
            setType(detail.type)
            setAdSlot(detail.ad_slots)
            setShareUser(detail.share_by_user == "yes" ? true : false);
            setViewUser(detail.user_confirm_view == "yes" ? true : false);
            let insfillQue = [];
            if (detail.questions) {
                detail.questions.map(i => {
                    var q = i.question_text;
                    var ans = [];
                    i.options.map(j => {
                        ans.push({ text: j.option_text });
                        return false;
                    })
                    insfillQue.push({
                        "question_text": q,
                        "question_options": ans
                    });
                    return false;
                })
            }
            setFillQue(insfillQue)
        }
    }
    let handleChange = (e, i = null) => {
        console.log(fillQue);
        if (i !== null) {
            let newOption = [...option];
            newOption[i].text = e.target.value;
            setOption(newOption);
        } else {
            setQueTxt(e.target.value);
        }
    }
    const validate = async () => {
        if (webLink === "") setLinkError("Website Link is mandatory field");
        if (adOnVideo === null) setResError("please select Video");
        else {
            setLinkError("");
            var reqData = {
                ad_id: props.adid ? props.adid : props.adDetails.ad_id,
                website_link: webLink,
                share_by_user: shareUser ? "yes" : "no",
                user_confirm_view: viewUser ? "yes" : "no",
                questions: fillQue,
                video_id: adOnVideo,
                type: type,
                ad_slots: adSlot
            }
            if (props.adDetails) {
                reqData.id = props.adDetails.id
            }
            var response = await postWithToken('/api/place_add_step1', props.token, reqData);
            if (response.status === 200) {
                setResError("");
                props.nextStep();
                props.userCallback(response.data);
            } else {
                setResError(response.message);
            }
        }
    };
    const addMoreOpt = () => {
        setOption([...option, blankOption[0]]);
    }
    const addQuestion = (e) => {
        e.preventDefault();
        if (queTxt === "") {
            setQError("Please add Question.");
            return
        } else {
            setQError("");
        }
        var fillAnsCount = 0;
        var queOption = [];
        option.map((e) => {
            if (e.text !== "") {
                fillAnsCount++;
                queOption.push(e);
            }
            return true;
        })
        if (fillAnsCount < 2) {
            setoError("Please Add Minimum 2 Answers.");
            return
        } else {
            setoError("");
        }
        var QueData = {
            "question_text": queTxt,
            "question_options": queOption
        }
        if (queEdit !== null) {
            let newfillQue = [...fillQue];
            newfillQue[queEdit] = {
                "question_text": queTxt,
                "question_options": queOption
            }
            setFillQue(newfillQue)
        } else {
            setFillQue((fillQue) => ([
                ...fillQue,
                QueData
            ]));
        }
        handleClose();
    }
    const editQuestion = i => {
        setQueEdit(i);
        setQueTxt(fillQue[i].question_text);
        setOption(fillQue[i].question_options);
        handleShow();
    }
    const deleteQuestion = i => {
        let newfillQue = [...fillQue];
        newfillQue.splice(i, 1);
        setFillQue(newfillQue)
    }
    const handleClose = () => {
        setOption(blankOption);
        setoError("");
        setQError("");
        setQueTxt("");
        setQueEdit(null);
        setShow(false)
    };
    const handleVClose = () => {
        setVideoShow(false)
    }
    const addVideoId = (id, as, ty) => {
        setResError("");
        setType(ty);
        setAdOnVideo(id);
        setAdSlot(as);
    }
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="innerCard mb-4">
                <Row>
                    <Col lg="6" className="mb-4">
                        <div className="liskwebstis">
                            <Image src={links}></Image>
                            <Form.Control type="text" name="website_link" value={webLink} onChange={e => setWebLink(e.target.value)} placeholder="Link of the website"></Form.Control>
                        </div>
                        {linkError && <p className="form-error">{linkError}</p>}
                    </Col>
                    <Col lg="6" className="mb-4">
                        <div className="liskwebstis addsurvey" >
                            <Image src={commentss}></Image>
                            <p onClick={handleShow}>Add Survey </p>
                        </div>
                    </Col>
                    <Col lg="6" className="mb-md-0 mb-4">
                        <div className="liskwebstis">
                            <Image src={sharerotate}></Image>
                            <div className="switch">
                                <Form.Label>Do you want users to share your ad</Form.Label>
                                <Form.Check type="switch" id="custom-switch" onChange={e => { setShareUser(!shareUser) }} checked={shareUser} name="share_by_user" />
                            </div>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="liskwebstis justify-content-lg-end">
                            <Image src={lisksrotate}></Image>
                            <div className="switch">
                                <Form.Label>Do you want users to confirm their views?</Form.Label>
                                <Form.Check type="switch" id="custom-switch" onChange={e => { setViewUser(!viewUser) }} checked={viewUser} name="user_confirm_view" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="surveycard mt-4">
                    <ul>
                        {fillQue.map((elem, index) => (
                            <li>
                                <div className="quetsiondiv">
                                    <h5>{elem.question_text}</h5>
                                    <div className="icon_survey">
                                        <button onClick={() => editQuestion(index)} className="edit_icon"><FontAwesomeIcon icon={faEdit} /></button>
                                        <button onClick={() => deleteQuestion(index)} className="delete_icon"><FontAwesomeIcon icon={faTrash} /></button>
                                    </div>
                                </div>
                                <div className="verification-radio">
                                    <Row>
                                        {elem.question_options.map(el => (

                                            <Col md={6}>
                                                <Form.Check
                                                    type={"radio"}
                                                    label={el.text}
                                                    name={elem.question_text}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="innerCard">
                <div className="selct-veido p-0">
                    Select video on which you want to place Ad
                    <Button onClick={() => setVideoShow(true)}>Add</Button>
                </div>
            </div>
            {resError && <p className="form-error">{resError}</p>}
            <ActionButtons {...props} nextStep={validate} />
            <Modal centered
                size='lg'
                show={show} onHide={handleClose} animation={false}>
                <p onClick={handleClose} className="closeiconall">
                    <Image src={closeicon}></Image>
                </p>
                <Modal.Body className='login-form  text-start mt-0 '>
                    <h6 className='tittle-heading mb-4 text-start'>Add Survey Questions</h6>
                    <Form>
                        <div className="verification-radio serveyedit mt-2">
                            <Form.Control className="mb-4"
                                onChange={handleChange}
                                name={"question_text"}
                                placeholder="Survey Question"
                                value={queTxt}
                            />
                            {qError && <p className="form-error">{qError}</p>}
                            <Row>
                                {option.map((ele, i) => (
                                    <Col md={6}>
                                        <div className="seuveyouter">
                                            <Form.Control id={"edit" + i}
                                                placeholder="Add answer here."
                                                name={ele.text + i}
                                                onChange={(e) => handleChange(e, i)}
                                                value={ele.text}
                                            />
                                        </div>
                                    </Col>
                                ))}
                                {oError && <p className="form-error">{oError}</p>}
                            </Row>
                        </div>
                        <div className="advertiser-add-location" onClick={addMoreOpt}><p>Add more <span><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" className="svg-inline--fa fa-plus " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg></span></p></div>
                        <button type="submit" onClick={addQuestion} className=" mt-5 upload-appoved" >Save</button>
                    </Form>

                </Modal.Body>
            </Modal>
            <StreamerVideo show={videoShow} adData={adData} token={props.token} setVideo={addVideoId} streamers={props.streamers} handleClose={handleVClose} />
        </>
    );
};


const Two = (props) => {
    const { register, handleSubmit, setValue, formState: { errors }, } = useForm({
        resolver: yupResolver(PlaceAdsStepTwo),
    });
    var sliderInitial = {
        min: 20,
        max: 60,
        val: [20, 60]
    }
    const [salary, setSalary] = useState(sliderInitial);
    const [age, setAge] = useState(sliderInitial);
    const [resError, setResError] = useState("");
    const [adData, setAdData] = useState({});
    if (props.adDetails) {
        if (adData !== props.adDetails) {
            var detail = props.adDetails;
            setAdData(detail)
            setAge({
                min: detail.min_age,
                max: detail.max_age,
                val: [detail.min_age, detail.max_age]
            });
            setSalary({
                min: detail.min_salary,
                max: detail.max_salary,
                val: [detail.min_salary, detail.max_salary]
            });
            setValue("location", detail.location);
            setValue("gender", detail.gender);
            setValue("marital_status", detail.marital_status);
            setValue("religion", detail.religion);
            setValue("student_working", detail.student_working);
            setValue("industry", detail.industry);
            setValue("parental_status", detail.parental_status);
        }
    }
    const validate2 = async (data) => {
        var reqData = {
            ...data,
            id: props.adDetails.id,
            min_salary: salary.min,
            max_salary: salary.max,
            min_age: age.min,
            max_age: age.max
        }
        var response = await postWithToken('/api/place_add_step2', props.token, reqData);
        if (response.status === 200) {
            setResError("");
            props.nextStep();
            props.userCallback(response.data);
        } else {
            setResError(response.message);
        }
    };

    const handleSalChange = (event, val) => {
        var arr = {
            min: val[0],
            max: val[1],
            val: val
        }
        setSalary(arr);
    };
    const handleAgeChange = (event, val) => {
        var arr = {
            min: val[0],
            max: val[1],
            val: val
        }
        setAge(arr);
    };
    return (
        <div className="targetedaudian mt-5">
            <div className="profile-form login-form mt-0">
                <h6 className="mb-5">Select Targeted Audience</h6>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-4">
                            <Form.Label>Location</Form.Label>
                            <Form.Select {...register('location')} aria-label="Default select example" >
                                <option value="">Select</option>
                                <option value="1">location</option>
                            </Form.Select>
                            {errors.location && <p className='form-error'>{errors.location.message}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-4">
                            <Form.Label>Gender</Form.Label>
                            <Form.Select {...register('gender')} aria-label="Default select example" >
                                <option value="">Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </Form.Select>
                            {errors.gender && <p className='form-error'>{errors.gender.message}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-4">
                            <Form.Label>Marital Status</Form.Label>
                            <Form.Select {...register('marital_status')} aria-label="Default select example" >
                                <option value="">Select</option>
                                <option value="married">Married</option>
                                <option value="unmarried">Unmarried</option>
                                <option value="divorced">Divorced</option>
                                <option value="widowed">Widowed</option>
                            </Form.Select>
                            {errors.marital_status && <p className='form-error'>{errors.marital_status.message}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-4">
                            <Form.Label>Religion</Form.Label>
                            <Form.Select {...register('religion')} aria-label="Default select example" >
                                <option value="">Select</option>
                                <option value="1">Hindus</option>
                                <option value="2">Jains</option>
                                <option value="3">Christians</option>
                                <option value="4">Muslims</option>
                                <option value="5">Sikhs</option>
                                <option value="6">Buddhists</option>
                                <option value="7">Christians</option>
                                <option value="8">Others</option>
                            </Form.Select>
                            {errors.religion && <p className='form-error'>{errors.religion.message}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-4">
                            <Form.Label>Student/Working</Form.Label>
                            <Form.Select {...register('student_working')} aria-label="Default select example" >
                                <option value="">Select</option>
                                <option value="student">Student</option>
                                <option value="working">Working</option>
                            </Form.Select>
                            {errors.student_working && <p className='form-error'>{errors.student_working.message}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-4">
                            <Form.Label>Industry</Form.Label>
                            <Form.Select {...register('industry')} aria-label="Default select example" >
                                <option value="">Select</option>
                                <option value="1">Banking</option>
                                <option value="2">Healthcare</option>
                                <option value="3">Chemicals</option>
                                <option value="4">Agriculture and Allied Industries</option>
                            </Form.Select>
                            {errors.industry && <p className='form-error'>{errors.industry.message}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-4">
                            <Form.Label>Parental Status</Form.Label>
                            <Form.Select {...register('parental_status', { value: 0 })} aria-label="Default select example" >
                                <option value={0}>Select</option>
                                <option value={1}>Yes</option>
                                <option value={2}>No</option>
                            </Form.Select>
                            {errors.parental_status && <p className='form-error'>{errors.parental_status.message}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-4">
                            <Form.Label>Salary</Form.Label>
                            <div className="sliderlabel">{salary.min} - {salary.max}k</div>
                            <Slider className="thumbslidedouble"
                                value={salary.val}
                                height="30"
                                onChange={handleSalChange}
                                valueLabelDisplay="auto"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-4">
                            <Form.Label>Age</Form.Label>
                            <div className="sliderlabel">{age.min} - {age.max}</div>
                            <Slider className="thumbslidedouble"
                                value={age.val}
                                height="30"
                                onChange={handleAgeChange}
                                valueLabelDisplay="auto"
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col md={12}>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-4">
                                    <Form.Label>List of videos on the basis of filters</Form.Label>
                                    <Form.Select aria-label="Default select example" >
                                        <option>Select</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col> */}
                </Row>
            </div>
            {resError && <p className="form-error">{resError}</p>}
            <ActionButtons {...props} nextStep={handleSubmit(validate2)} />
        </div>
    );
};


const Three = (props) => {
    const [planId, setPlanId] = useState(null);
    const [error, setError] = useState("");
    const handleLastStep = async () => {
        if (planId) {
            var reqData = {
                id: props.adDetails.id,
                plan_id: planId
            }
            var resp = await postWithToken('/api/place_add_step3', props.token, reqData);
            if (resp.status === 200) {
                setError("");
                props.lastStep();
                props.completeCallback(resp.message);
            } else {
                setError(resp.message);
            }
        } else {
            setError("Please Selct Plan");
        }
    };

    return (
        <div className="targetedaudian mt-5">
            <h6 className="mb-5">Select Plan</h6>
            <Row>
                {props.plans && props.plans.map(el => (
                    <Col key={el.id}>
                        <div className={planId === el.id ? "track-plan-item active mb-4" : "track-plan-item mb-4"}>
                            <div className="track-plan-number">
                                <p>{el.title}</p>
                            </div>
                            <div className="track-plan-location">
                                <p>100 Users</p>
                            </div>
                            <div className="track-plan-number">
                                <p>CPM</p>
                            </div>
                            <div className="track-plan-location">
                                <p>${el.price}</p>
                            </div>
                            <Button type="button" onClick={() => { setPlanId(el.id) }} className="planbtn-button">
                                Select
                            </Button>
                        </div>
                    </Col>
                ))}
                {error && <p className='form-error'>{error}</p>}
            </Row>
            <ActionButtons {...props} lastStep={handleLastStep} />
        </div >
    );
};


function PlaceAdsStep() {
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [stepWizard, setStepWizard] = useState(null);
    const [ads, setAds] = useState({});
    const [adsPlan, setAdsPlan] = useState([]);
    const [adDetails, setAdDetails] = useState({});
    const [streamers, setStreamers] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    let { id, ad_id } = location.state;
    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };
    const assignDetails = (data) => {
        setAdDetails(data);
    };
    var token = auth.getToken();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                if (ad_id) {
                    var res = await getWithToken('/api/ads/' + ad_id, token);
                    if (res.status === 200) {
                        setAds(res.data);
                    }
                }
                if (id) {
                    var response = await getWithToken('/api/posted_ads/' + id, token);
                    if (response.status === 200) {
                        setAdDetails(response.data);
                        setAds({ thumbnail_url: response.data.ads.thumbnail_url })
                    }
                }
                var strRes = await getWithToken('/api/streamers_list', token);
                if (strRes.status === 200) {
                    setStreamers(strRes.data);
                }
                var planRes = await getWithToken('/api/plan_list/advertiser', token);
                if (planRes.status === 200) {
                    setAdsPlan(planRes.data);
                }
                setLoading(false);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [id, ad_id, token])
    const handleStepChange = (e) => {
        setActiveStep(e.activeStep - 1);
    };

    const handleComplete = (msg) => {
        swal("Good job!", msg, "success")
        navigate('/adv/placeads');
    };
    return (
        <div className="addvertiser-header">
            {loading && <Loader />}
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Place An Ad</h4>
                        </div>
                        <Row>
                            <Col lg={10}>
                                <div className="OuterdivStep">
                                    <Row>
                                        <Col lg={8} className="mx-auto ">
                                            <Stepper activeStep={activeStep} className="countouter">
                                                <Step className="stepcount" label="Place An Ad" />
                                                <Step className="stepcount" label="Market Your Ad" />
                                                <Step className="stepcount" label="Select Plan" />
                                            </Stepper>
                                            <Row>
                                                <Col lg={8} className="mx-auto mt-5 mb-5 pb-4">
                                                    <div className="boxcard ">
                                                        <Image className="firstImage" src={links}></Image>
                                                        <Image className="senonImages" src={message}></Image>
                                                        <Image className="thirdImage" src={share}></Image>
                                                        <Image className="forthImage" src={like}></Image>
                                                        <Image className="thumbnailImage" src={BaseUrl + "/" + ads.thumbnail_url}></Image>

                                                        <button className="savebutton mt-5"> <FontAwesomeIcon icon={faBookmark} /><span>Submit and save to make extra money</span></button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <StepWizard className="ddddd" instance={assignStepWizard} onStepChange={handleStepChange}>
                                        <One adid={ad_id} streamers={streamers} adDetails={adDetails} token={token} userCallback={assignDetails} />
                                        <Two adDetails={adDetails} token={token} userCallback={assignDetails} />
                                        <Three adDetails={adDetails} plans={adsPlan} token={token} completeCallback={handleComplete} />
                                    </StepWizard>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}


export default PlaceAdsStep;