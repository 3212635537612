import { Autocomplete, TextField } from '@mui/material';
import { hasGrantedAllScopesGoogle, useGoogleLogin } from '@react-oauth/google';
import React from 'react'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { postWithToken } from '../../variables/apiCall';

export default function ShareModal({ show, sc, id, token }) {
    const [importEmail, setImportEmail] = useState([]);
    const [mails, setMails] = useState([]);
    const responseCallback = async (response) => {
        const hasAccess = hasGrantedAllScopesGoogle(
            response,
            'https://www.googleapis.com/auth/contacts.readonly'
        );
        if (hasAccess) {
            // var res = await fetch('https://people.googleapis.com/v1/otherContacts?readMask=emailAddresses', {
            var res = await fetch('https://people.googleapis.com/v1/people/me/connections?personFields=emailAddresses', {
                headers: { Authorization: 'Bearer ' + response.access_token },
            });
            var contacts = await res.json();
            var conArr = [];
            if (Object.keys(contacts).length > 0) {
                contacts.connections.map(el => {
                    conArr.push(el.emailAddresses[0].value);
                })
                setImportEmail(conArr);
            } else {
                toast.error("You have not any contact in your gmail contacts.");
            }
        }
    }
    const login = useGoogleLogin({
        onSuccess: responseCallback,
        scope: "https://www.googleapis.com/auth/contacts.readonly"
    });

    const handleChange = (e, val) => {
        setMails(val);
    }
    const close = async (e) => {
        if (mails.length > 0) {
            var reqData = {
                link: `${window.location.origin.toString()}/ad/watch/${btoa(id)}`,
                emails: mails
            }
            var res = await postWithToken("/api/send_ad_link_mails", token, reqData);
            if (res.status === 200) {
                sc(true);
            }
        }
    }
    return (
        <Modal show={show} onHide={sc} animation={false} size="lg" centered >
            <Modal.Body className='modal-position'>
                <div className="login-heading modal-detail">
                    <div className="modal-servey-image">
                        <Autocomplete
                            className='muilabel-input'
                            multiple
                            id="tags-filled"
                            options={importEmail}
                            onChange={handleChange}
                            freeSolo
                            renderInput={(params) => (
                                <TextField
                                    className='textautomcom'
                                    {...params}
                                    variant="filled"
                                    label="Enter Email Addresses"
                                    placeholder="Emails"
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="modal-advertisement modal-movie-watch">
                    <Button onClick={() => login()}>Import From Google</Button>
                    <Button onClick={close} className="banner-button mt-3">Send Mail</Button>
                    <Button onClick={() => sc()} className="banner-button mt-3">Close</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
