import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { loginSchema } from '../../variables/validation_schema';
import { post } from '../../variables/apiCall';
import { useState } from 'react';
import { useAuth } from '../../auth/Auth';
import Loader from '../Loader';
import SocialLogin from '../SocialLogin';

const LoginForm = () => {
    const [resError, setResError] = useState("");
    const [loading, setLoading] = useState(false);
    var navigate = useNavigate();
    const { register, handleSubmit,reset, formState: { errors }, } = useForm({
        resolver: yupResolver(loginSchema),
    });
    var auth = useAuth();
    const onSubmit = async (data) => {
        setLoading(true);
        var res = await post('/api/login', { ...data });
        setLoading(false);
        if (res.status === 200) {
            var user = res.user;
            auth.login(user)
            auth.setUserId(user.id)
            await auth.setToken(res.access_token);
            if (user.user_type === "2") {
                navigate('/adv/placeadlist');
            } else if (user.user_type === "1") {
                navigate('/str/library');
            } else if (user.user_type === "4") {
                navigate('/staff/streams');
            } else {
                navigate('/view/home');
            }
        } else {
            setResError(res.message);
        }
    }

    const handleReset = () => {
        reset({ email: '' });
    };
    if (loading) {
        return <Loader />
    }
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                {resError && <p className='form-error'>{resError}</p>}
                <Col md={12}>
                    <Form.Group className=" mb-0">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" {...register('email')} maxLength={255}/>
                        {errors.email && <p className='form-error'>{errors.email.message}</p>}
                    </Form.Group >
                    <div className='text-end mt-1'>
                    <button className='py-0 mt-0 mb-3' type='button' onClick={handleReset}>Reset</button>
                    </div>
                </Col>
                <Col md={12}>
                    <Form.Group className=" mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" {...register('password')} maxLength={30}/>
                        {errors.password && <p className='form-error'>{errors.password.message}</p>}
                    </Form.Group >
                </Col>
                <Col md={12}>
                    <div className="forget-password mb-3">
                        <div className="remmber-checked form-check ps-0">
                            <div className="check-label">
                                <Form.Check type={"checkbox"} label={`Remember Me ?`} />
                            </div>
                        </div>
                        <div className="password mb-3">
                            <Link to={"/auth/resetpassword"}>Forgot Password</Link>
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    <Button type="submit" className="login-submit-button mb-3">Submit</Button>
                </Col>
                <Col md={12}>
                    <div className="sign-other-account mb-3">
                        <p>or sign in with other accounts?</p>
                        <SocialLogin />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="sign-up-account mb-4">
                        <p>Don’t have an account? Click here to <Link to={"/auth/signup"}>Sign Up</Link>.</p>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default LoginForm;