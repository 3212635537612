import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useAuth } from '../../../auth/Auth';
import DataTables from '../../../components/DataTables';
import Loader from '../../../components/Loader';
import StaffModal from '../../../components/modal/StaffModal';
import { getWithToken } from '../../../variables/apiCall';
import swal from 'sweetalert';
export default function Staff() {
    const [loading, setLoading] = useState(false);
    const [staffData, setStaffData] = useState({});
    const [show, setShow] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const auth = useAuth();
    var token = auth.getToken();
    const columns = [
        {
            name: "Name",
            selector: row => row.name
        },
        {
            name: "Email",
            selector: row => row.email,
        },
        {
            name: "Phone",
            selector: row => row.mobile,
        },
        {
            name: "Action",
            cell: (row) => (
                <div className='outBtn'>
                    <Button type="button" className={"editt-icon"} onClick={() => editHandler(row.id)}><FontAwesomeIcon icon={faEdit} /></Button>
                    <Button type="button" variant='danger' className={"delete-con"} onClick={() => deleteHandler(row.id)}><FontAwesomeIcon icon={faTrash} className="bellicon" /></Button>
                </div>
            )
        },
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                var response = await getWithToken('/api/streamer_staff_list', token);
                setLoading(false);
                if (response.status === 200) {
                    setData(response.data);
                }
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [token, show, refresh])
    const modalClose = () => {
        setShow(false);
        setStaffData({});
    }
    const modalOpen = () => {
        setShow(true);
    }
    const editHandler = async (id) => {
        setLoading(true)
        var res = await getWithToken("/api/streamer_staff_edit/" + id, token);
        setLoading(false)
        if (res.status === 200) {
            setStaffData(res.data);
            modalOpen();
        } else {
            alert("something went wrong");
        }
    }
    const deleteHandler = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                var res = await getWithToken("/api/streamer_staff_delete/" + id, token);
                if (res.status === 200) {
                    setRefresh(!refresh);
                    swal("Poof! Your record has been deleted!", {
                        icon: "success",
                    });
                }
            }
        });
    }
    return (
        <div className="addvertiser-header">
            {loading && <Loader />}
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Staff</h4>
                            <Button className="submit-button" onClick={modalOpen} variant="primary">Add Staff</Button>
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="place-ads">
                                    <div className="place-ads-table">
                                        <div className="table-haeding">
                                            <h6>Staff List</h6>
                                        </div>
                                        <DataTables
                                            columns={columns}
                                            data={data}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <StaffModal show={show} onhide={modalClose} sdata={staffData} />
        </div>
    );
}
