import React from "react";
import { Route, Routes } from "react-router-dom";
import dashRoutes from "../routes";
import HomeHeader from "../components/Header/home_header";
import HomeFooter from "../components/Footer/home_footer";
import { useAuth } from "../auth/Auth";
import Error404 from "../components/Errors/Error404";
import Loader from "../components/Loader";
import { RequireAuth } from "../auth/RequireAuth";

export default function ViewerLayout(props) {
    const auth = useAuth();
    let user = auth.user;
    const getRoutes = (routes) => {
        if (user === null) {
            return <Route path="*" element={<Loader />} />
        }
        if (user.user_type === "3") {
            return routes.map((prop, key) => {
                if (prop.layout === "/viewer") {
                    return (
                        <Route
                            path={prop.path}
                            element={prop.component}
                            key={key}
                        />
                    );
                }
            });
        } else {
            return <Route path="*" element={<Error404 />} />
        }
    };
    return (
        <React.Fragment>
            <RequireAuth>
                <div className="overfilo-div">
                    <HomeHeader />
                    <Routes>
                        {getRoutes(dashRoutes)}
                    </Routes>
                    <HomeFooter />
                </div>
            </RequireAuth>
        </React.Fragment>
    );
}
