import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Otpinput from "../../components/Otp";
import { post } from "../../variables/apiCall";
import swal from "sweetalert";
import { useAuth } from "../../auth/Auth";

class Verification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }
    verifyOtp = async (code) => {
        var auth = this.props.auth;
        var navigate = this.props.navigate;
        var otp = atob(this.props.reqData.otp)
        if (otp === code) {
            var requestData = {
                ...this.props.reqData,
                "social_login": 0,
                "verify_status": true
            }
            var res = await post('/api/signup', requestData);
            if (res.status === 200) {
                swal("Registered Successfully", {
                    icon: "success",
                }).then(async () => {
                    var user = res.user;
                    auth.login(user)
                    auth.setUserId(user.id)
                    await auth.setToken(res.access_token);
                    if (user.user_type === "2") {
                        navigate('/adv/placeadlist');
                    } else if (user.user_type === "1") {
                        navigate('/str/library');
                    } else if (user.user_type === "4") {
                        navigate('/staff/streams');
                    } else {
                        navigate('/view/home');
                    }
                });
            }
        }
    }
    render() {
        let { reqData } = this.props;
        return (
            <div className="login-form-layout">
                <Container>
                    <Row>
                        <Col md={8} sm={10} xs={12} xl={5} lg={6} className="mx-auto">
                            <div className="login">
                                <div className="login-heading">
                                    <h4>Validate {reqData.verify_by === "email" ? "Email address" : "Phone"}</h4>
                                    <p>Verification code has been sent to you on your {reqData.verify_by === "email" ? "Email address" : "Phone"}. <br></br> Please enter it below</p>
                                </div>
                                <div className="login-form">
                                    <Otpinput verify={this.verifyOtp} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default function useHook(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();
    let { data } = location.state;
    return <Verification {...props} navigate={navigate} reqData={data} auth={auth} />;
}