// export const BaseUrl = "http://192.168.29.172/giant-slayer/backend"; //Anand sir pc
// export const BaseUrl = "http://localhost/giant-slayer/backend";
export const BaseUrl = "https://giant-slayer.itworkshop.in/backend";

export const apiKey = "34c5c311ac13d8712863d508d7b61fed";
export const StreamUrl = "https://ec2-35-74-244-232.ap-northeast-1.compute.amazonaws.com/openvidu/api/";
export const DafaultCurrency = "USD";
export const PaypalClientId = "AawXG2RavcdgXbGtCBaxmDMVWa6sKvUKc0C6fiBuMH7Y-JMT9MfYCA1bx_NIC6epIMfXw97-UQROrkzL";
export const SliderOption = {
    stagePadding: 30,
    margin: 10,
    nav: true,

    responsive: {
        0: {
            items: 1,
        },
        300: {
            items: 2,
            stagePadding: 0,
        },
        575: {
            items: 3,
            stagePadding: 0,
        },
        767: {
            items: 4,
            stagePadding: 0,
        },
        768: {
            items: 4,
        },
        1000: {
            items: 6,
        },
        1200: {
            items: 8,
        }
    },

};

const offensiveWords = [
    // English
    "ass", "asshole", "bastard", "bitch", "bloody", "bollocks", "bugger", "bullshit", "cock", "cocksucker",
    "crap", "cunt", "damn", "dick", "dickhead", "douche", "dumb", "fag", "faggot", "fuck", "fucker", "fucking",
    "goddamn", "hell", "idiot", "jerk", "moron", "motherfucker", "nigger", "nigga", "piss", "prick", "pussy",
    "shit", "shithead", "slut", "son of a bitch", "spastic", "spaz", "stupid", "twat", "wanker",
    // Hindi
    "bhosdike", "chutiya", "gandu", "gaand", "harami", "kamina", "kutti", "kutte", "madarchod", "randi", "saala",
    "saali", "teri maa ki", "teri behen ki",
    // Tamil
    "poda", "podi", "thevidiya", "thevidiya paiya", "saniyan", "sombu", "mangatha",
    // Bengali
    "bokachoda", "chodna", "chodni", "gandu", "khanki", "magi", "paagol", "shala", "shali", "haraamjaada"
];

const violentWords = [
    "abuse", "aggression", "assault", "attack", "battery", "beat", "bloodshed", "brutality", "brawl", "choke",
    "clobber", "confrontation", "crime", "crush", "cut", "destroy", "devastate", "execute", "fight", "harm",
    "hit", "homicide", "hurt", "injury", "kill", "lacerate", "lynch", "maim", "manslaughter", "murder", "mutilate",
    "punch", "rape", "ravage", "riot", "shoot", "slash", "slaughter", "smack", "smash", "stab", "strangle",
    "strike", "terrorism", "torture", "trauma", "violate", "war", "wound", "beheading", "bombing", "burning",
    "cannibalism", "decapitate", "dismember", "genocide", "impale", "incinerate", "massacre", "poison", "stone",
    "suffocate", "arrow", "axe", "bayonet", "blade", "bomb", "bullet", "cannon", "club", "crossbow", "dagger",
    "firearm", "grenade", "gun", "knife", "landmine", "mace", "missile", "pistol", "revolver", "rifle", "saber",
    "shotgun", "spear", "sword", "tank", "torpedo"
];
const discriminatoryWords = [
    // General Discriminatory Terms
    "chink", "gook", "redskin", "wetback",
    "dyke", "faggot", "tranny", "slut", "whore",
    "infidel", "pagan", "heretic", "blasphemer",
    "crippled", "retarded", "spastic",
    "fatass", "skinny bitch",
    
    // Specific Examples
    "nigger", "paki", "jap", "beaner", "redneck", 
    "towelhead", "cunt", "bitch", "slut",
    "queer", "homophobe",
    "christ-killer", "mooslim", 
    "retard", "cripple"
    // Add more discriminatory words as needed
];

const drugWords = [
    // General Terms
    "heroin", "cocaine", "methamphetamine", "mdma", "ecstasy", "lsd", "pcp", "crack", "meth", "fentanyl", "ghb",
    "ketamine", "opium", "morphine", "oxycodone", "hydrocodone",
    
    // Drug Slang
    "weed", "pot", "grass", "mary jane", "dope", "blow", "snow", "e", "x", "shrooms", "acid", "speed", "crystal",
    "h", "smack",
    
    // Drug Paraphernalia
    "pipe", "bong", "needle", "syringe", "vape", "hookah", "roach", "grinder", "scale", "baggie",
    
    // Other Terms
    "narcotics", "speedball", "junkie", "dealer", "trip", "high", "buzz"
    // Add more drug-related words as needed
];
// const notAllowWords = [...offensiveWords, ...violentWords, ...discriminatoryWords, ...drugWords];
// const createRegex = (words) => {
//     const escapedWords = words.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')); 
//     const pattern = `\\b(${escapedWords.join('|')})\\b`;
//     return new RegExp(pattern, 'i');
// };

export const sensitiveWords = [...offensiveWords, ...violentWords, ...discriminatoryWords, ...drugWords];