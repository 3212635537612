import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/Auth";
import Loader from "../../components/Loader";
import LiveStream from "../../components/Movie/LiveStream";
import { getUrl } from "../../Helper";
import { getWithToken } from "../../variables/apiCall";

function StreamVideo() {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    let token = auth.getToken();
    const [stData, setStData] = useState({});
    const [loading, setLoading] = useState(false);
    const [sid, setSid] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            var res = await getWithToken('/api/stream/' + sid, token);
            setLoading(false);
            if (res.status === 200) {
                setStData(res.data);
            }
        }
        if (sid)
            fetchData();
    }, [sid, token])
    if (location.state === null) {
        return navigate('/str/stream-list');
    } else {
        if (location.state.sid !== sid)
            setSid(location.state.sid)
    }
    if (loading) {
        return <Loader />;
    }
    return (
        <div className="addvertiser-header">
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Stream</h4>
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="place-ads">
                                    <div className="place-ads-table">
                                        <div className="streamermovie stremingpvideo">
                                            <div className="stremerimg">
                                                <Image src={getUrl(stData.trailer_thumbnail_url)} />
                                            </div>
                                            <div className="titlestremer">

                                                <h4 className="mb-3">{stData.title}</h4>
                                                <ul className="typemovie">
                                                    <li>
                                                        <p>Type</p>
                                                        <span>Live Stream</span>
                                                    </li>
                                                    <li>
                                                        <p>Time</p>
                                                        <span>--:--</span>
                                                    </li>
                                                    <li>
                                                        <p>Plan Choosen</p>
                                                        <span>{stData.plan_name}</span>
                                                    </li>
                                                    <li>
                                                        <p>Status</p>
                                                        <span className="paid_item">Paid</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <LiveStream str={stData} user={auth.user} token={token} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}


export default StreamVideo;