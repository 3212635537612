import React, { useEffect } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Image } from "react-bootstrap";
import filterImg from "../../assets/img/filter-img.png";
import { Dropdown } from "react-bootstrap";
import { useState } from 'react';
import { useAuth } from '../../auth/Auth';
import { getWithToken } from '../../variables/apiCall';
import { BaseUrl } from '../../variables/general';
import DataTables from '../../components/DataTables';
import Loader from '../../components/Loader';
import { Link } from 'react-router-dom';
import { HelpOutline } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { getTitleName } from '../../Helper';

function Videolibrary() {
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(true);
    const [pending, setPending] = useState(true);
    const [reject, setReject] = useState(true);
    const auth = useAuth();
    var token = auth.getToken();
    const columns = [
        {
            name: "Title",
            cell: (row) => (
                <div className="video-ad"><Image width={"100px"} src={BaseUrl + "/" + row.trailer_thumbnail_url} /><p>{row.title}</p></div>
            )
        },
        {
            name: "Views",
            selector: row => row.user_view,
        },
        {
            name: "Type",
            selector: row => getTitleName(row.item_type),
        },
        {
            name: "Plan Type",
            selector: row => row.plan_type,
        },
        {
            name: "Available Ad slots",
            selector: row => row.avail_slots,
        },
        {
            name: <p className='plansTh'>PPV <Tooltip title="Pay Per View">
                <IconButton>
                    <HelpOutline />
                </IconButton>
            </Tooltip></p>,
            selector: row => row.ppv,
        },
        {
            name: <p className='plansTh'>AVOD <Tooltip title="Advertise Video on Demand">
                <IconButton>
                    <HelpOutline />
                </IconButton>
            </Tooltip></p>,
            selector: row => row.avod,
        },
        {
            name: <p className='plansTh'>SVOD <Tooltip title="Subscription Video On Demand">
                <IconButton>
                    <HelpOutline />
                </IconButton>
            </Tooltip></p>,
            selector: row => row.svod,
        },
        {
            name: "Status",
            cell: (row) => {
                switch (row.status) {
                    case 1: return (<Button type="button" className={"active-button"}>{"Active"}</Button>)
                    case 2: return (<Button type="button" className={"inactive-button"}>{"Rejected"}</Button>)
                    default: return (<Button type="button" className={"pending-button"}>{"Pending"}</Button>)
                }
            }
        },
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                var response = await getWithToken('/api/video_library', token);
                setLoading(false);
                if (response.status === 200) {
                    setData(response.data);
                }
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [token])
    const getTableData = () => {
        var fd = [];
        data.map(el => {
            if (el.status === 1 && active) {
                fd.push(el);
            } else if (el.status === 2 && reject) {
                fd.push(el);
            } else if (el.status === 0 && pending) {
                fd.push(el);
            }
            return false;
        });
        return (
            <DataTables
                columns={columns}
                data={fd}
            />
        )
    }
    return (
        <div className="addvertiser-header">
            {loading && <Loader />}
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Video Library</h4>
                            <Link className="submit-button" variant="primary" to={"/str/upload"}>Add Video</Link>
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="place-ads">
                                    <div className="place-ads-table">
                                        <div className="table-haeding">
                                            <h6>Video Library List</h6>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" className="filtername" id="dropdown-basic">
                                                    <Image src={filterImg}></Image>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item type={"active"} active={active} onClick={() => setActive(!active)}>Active</Dropdown.Item>
                                                    <Dropdown.Item type={"pending"} active={pending} onClick={() => setPending(!pending)}>Pending</Dropdown.Item>
                                                    <Dropdown.Item type={"reject"} active={reject} onClick={() => setReject(!reject)}>Rejected</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        {active && pending && reject ?
                                            <DataTables
                                                columns={columns}
                                                data={data}
                                            /> : getTableData()}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}


export default Videolibrary;