import React from "react";
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../../components/Loader";
import PaypalModal from "../../components/modal/PaypalModal";
import { getWithToken } from "../../variables/apiCall";
import planImage from './../../assets/img/plan.png';

class BasicPlan extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			basicData: [],
			premiumData: [],
			resData: [],
			amnt: 0,
			loading: false,
			paypal: false,
			planId: null,
			paySuccess: false,
			token: ""
		};
	}
	async componentDidMount() {
		var token = localStorage.getItem("token");
		this.setState({
			loading: true,
			token
		})
		var res = await getWithToken("/api/plan_list/subscription", token);
		if (res.status === 200) {
			var bd = [];
			var pd = [];
			res.data.map(el => {
				if (el.plan_type === "basic") {
					bd.push(el);
				} else if (el.plan_type === "premium") {
					pd.push(el);
				}
				return false;
			})
			this.setState({
				loading: false,
				resData: res.data,
				basicData: bd,
				premiumData: pd
			});
		}
	}
	paymentHandler = (e) => {
		var id = parseInt(e.target.id);
		var result = this.state.resData.filter(obj => {
			return obj.id === id
		})
		this.setState({
			amnt: result[0].price,
			paypal: true,
			planId: e.target.id
		})
	}
	modalClose = (payment = false) => {
		if (payment) {
			swal("Good Job", "Plan purchase successfully.", "success");
			this.setState({
				paySuccess: payment,
				paypal: false
			})
		} else {
			swal("Error", "Something went wrong.", "error");
			this.setState({
				paySuccess: payment,
				paypal: false
			})
		}
	}
	render() {
		const { loading, basicData, premiumData, paypal, token, planId, amnt, paySuccess } = this.state;
		return (
			<div className="plan">
				{loading && <Loader />}
				{paySuccess && <Navigate to={-1} replace={true} />}
				<div className="login-heading">
					<Container>

						<h4>Choose Your Plan</h4>
						<p>We have several powerful plans to showcase your business and get discovered<br></br>
							as a creative entrepreneurs. Everything you need.</p>
					</Container>
				</div>
				<div className="basicplan">
					<Container>
						<Row className="justify-content-center">
							<Col md={12}>
								<Tabs justify variant="pills" defaultActiveKey="tab-1" className="tabs-items">
									<Tab eventKey="tab-1" title="Basic Plan">
										<Row className="justify-content-center">
											{basicData && basicData.map((e, i) => (
												<Col key={i} lg={4} md={6} sm={6} className="text-center mb-4">
													<div className="basicplan-item1" >
														<div className="plan-heading plan-pb-50">
															<h4>{e.title}</h4>
														</div>
														<div className="plan-image plan-pb-50">
															<Image src={planImage} />
														</div>
														<div className="plan-description plan-pb-50">
															<p>{e.description}</p>
														</div>
														<div className="plan-amount">
															<h2>${e.price} <span>/{e.time_type}</span></h2>
														</div>
														<div className="plan-button">
															<Button id={e.id} onClick={this.paymentHandler} className="plan-submit-button">Select</Button>
														</div>
													</div>
												</Col>
											))}
										</Row>
									</Tab>
									<Tab eventKey="tab-2" title="Premium Plan">
										<Row className="justify-content-center">
											{premiumData && premiumData.map((e, i) => (
												<Col key={i} lg={4} md={6} sm={6} className="text-center mb-4">
													<div className="basicplan-item1">
														<div className="plan-heading plan-pb-50">
															<h4>{e.title}</h4>
														</div>
														<div className="plan-image plan-pb-50">
															<Image src={planImage} />
														</div>
														<div className="plan-description plan-pb-50">
															<p>{e.description}</p>
														</div>
														<div className="plan-amount">
															<h2>${e.price} <span>/{e.time_type}</span></h2>
														</div>
														<div className="plan-button">
															<Button id={e.id} onClick={this.paymentHandler} className="plan-submit-button">Select</Button>
														</div>
													</div>
												</Col>
											))}
										</Row>
									</Tab>
								</Tabs>
							</Col>
						</Row>
					</Container>
				</div>
				<PaypalModal show={paypal} amnt={amnt} pc={this.modalClose} plan={planId} token={token} type={"svod"} />
			</div>
		);
	}
}

export default BasicPlan;