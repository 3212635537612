import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useState } from 'react';
import { registerSchema } from '../../variables/validation_schema';
import { post } from '../../variables/apiCall';
import Loader from '../Loader';
import SocialLogin from '../SocialLogin';

const RegisterForm = (props) => {
    const [resError, setResError] = useState("");
    const [loading, setLoading] = useState(false);
    const [streamerType, setStreamerType] = useState(false);
    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(registerSchema),
    });
    var navigate = useNavigate();
    const onSubmit = async (data) => {
        setLoading(true);
        var requestData = {
            ...data,
            "social_login": 0,
            "verify_status": false
        }
        var res = await post('/api/signup', requestData);
        setLoading(false);
        if (res.status === 200) {
            navigate('/auth/verification', { state: { data: res.data } });
        } else {
            setResError(res.message);
        }
    }
    const userTypeChange = (e) => {
        if (e.target.value === "1") {
            setStreamerType(true);
        } else {
            setStreamerType(false);
        }
    }
    if (loading) {
        return <Loader />
    }
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {resError && <p className='form-error'>{resError}</p>}
            <Row>
                <Col md={6}>
                    <Form.Group className=" mb-4">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" {...register('name')} maxLength={26}/>
                        {errors.name && <p className='form-error'>{errors.name.message}</p>}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" {...register('email')} />
                        {errors.email && <p className='form-error'>{errors.email.message}</p>}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control type="tel" {...register('mobile')} maxLength={10}/>
                        {errors.mobile && <p className='form-error'>{errors.mobile.message}</p>}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>User Type</Form.Label>
                        <Form.Select aria-label="Default select example" placeholder="select" {...register('user_type', { onChange: (e) => { userTypeChange(e) } })}>
                            <option value={0}>Select User Type</option>
                            <option value={1}>Streamer</option>
                            <option value={2}>Advertiser</option>
                            <option value={3}>Viewer</option>
                        </Form.Select>
                        {errors.user_type && <p className='form-error'>{errors.user_type.message}</p>}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" {...register('password')} maxLength={18}/>
                        {errors.password && <p className='form-error'>{errors.password.message}</p>}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" {...register('cpassword')} />
                        {errors.cpassword && <p className='form-error'>{errors.cpassword.message}</p>}
                    </Form.Group>
                </Col>
                {streamerType && (<Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Streamer Type</Form.Label>
                        <Form.Select aria-label="Default select example" {...register('streamer_type')}>
                            <option value={0}>Select User Type</option>
                            <option value={1}>Club</option>
                            <option value={2}>Restaurant</option>
                            <option value={3}>Cafe</option>
                        </Form.Select>
                        {errors.streamer_type && <p className='form-error'>{errors.streamer_type.message}</p>}
                    </Form.Group>
                </Col>)}
                <Col md={12}>
                    <div className="send-verification code mb-2 ">
                        <p>Send Verification Code Over</p>
                        <div>
                            <div className="verification-radio">
                                <Row>
                                    <Col md={4}>
                                        <Form.Check
                                            type={"radio"}
                                            value={"phone"}
                                            id={"Phone"}
                                            defaultChecked={true}
                                            {...register('verify')}
                                            label={`Phone`}
                                        />

                                    </Col>
                                    <Col md={4}>
                                        <Form.Check
                                            type={"radio"}
                                            value={"email"}
                                            id={"Email"}
                                            label={`Email`}
                                            {...register('verify')}
                                        />
                                    </Col>
                                    {errors.verify && <p className='form-error'>{errors.verify.message}</p>}
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col md={12}>
                    <div className="mb-4 send-verification">
                        <div className="mb-4">
                            <Form.Check
                                {...register('terms')}
                                type={'checkbox'}
                                id={`I agree with the terms of use`}
                                label={`I agree with the terms of use`}
                            />
                            {errors.terms && <p className='form-error'>{errors.terms.message}</p>}
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    <Button type="submit" className="login-submit-button mb-4">Sign Up</Button>
                </Col>
                <Col md={12}>
                    <div className="sign-other-account mb-3">
                        <p>or sign in with other accounts?</p>
                        <SocialLogin />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="sign-up-account">
                        <p>Don’t have an account? Click here to <Link to={"/auth/signin"}>Login</Link>.</p>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

export default RegisterForm;