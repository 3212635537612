import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import BreadCrumb from "../../components/Breadcrumb/breadcrumb";
import Editprofile from './../../assets/img/editprofile.png';
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../auth/Auth";
import { EditProfileViewerSchema } from "../../variables/validation_schema";
import { getWithToken, postWithToken } from "../../variables/apiCall";
import swal from "sweetalert";
import { getBase64 } from "../../Helper";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import ResetPassword from "../../components/Form/ResetPassword";

function Profile() {
    const auth = useAuth();
    let token = auth.getToken();
    let navigate = useNavigate();
    var completeProfileStatus = auth.user.profile_status;
    const { register, handleSubmit, setValue, formState: { errors }, } = useForm({
        resolver: yupResolver(EditProfileViewerSchema),
    });
    const [data, setData] = useState({});
    const [saveAd, setSaveAd] = useState(false);
    const [earnMoney, setEarnMoney] = useState(false);
    const [loading, setLoading] = useState(false);
    const [photo, setPhoto] = useState("");
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [religion, setReligion] = useState([]);
    const [resError, setResError] = useState("");
    const getState = async (cid) => {
        var response = await getWithToken('/api/countryStateList/' + cid, token);
        if (response.status === 200) {
            setStates(response.data);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                var res = await getWithToken('/api/religion_list', token);
                if (res.status === 200) {
                    setReligion(res.data);
                }
                var conRes = await getWithToken('/api/countryList', token);
                if (conRes.status === 200) {
                    setCountries(conRes.data)
                }
                var response = await getWithToken('/api/user_profile/' + auth.id, token);
                if (response.status === 200) {
                    var user = response.data;
                    setData(user);
                    setValue("name", user.name);
                    setValue("mobile", user.mobile);
                    if (user.get_user_details !== "" && user.get_user_details !== null) {
                        if (user.get_user_details.country !== null) {
                            getState(user.get_user_details.country)
                            setValue("country", parseInt(user.get_user_details.country));
                            setValue("state", parseInt(user.get_user_details.state));
                        }
                        setValue("location", user.get_user_details.location ? user.get_user_details.location : "");
                        setValue("age", user.get_user_details.age ? parseInt(user.get_user_details.age) : 0);
                        setValue("gender", user.get_user_details.gender ? user.get_user_details.gender : "");
                        setValue("marital_status", user.get_user_details.marital_status ? user.get_user_details.marital_status : "");
                        setValue("student_working", user.get_user_details.student_working);
                        setValue("salary", user.get_user_details.salary ? parseInt(user.get_user_details.salary) : 0);
                        setValue("total_earnings", user.get_user_details.total_earnings ? parseInt(user.get_user_details.total_earnings) : 0);
                        setValue("religion", parseInt(user.get_user_details.religion));
                        setValue("address", user.get_user_details.address ? user.get_user_details.address : "");
                        setSaveAd(user.get_user_details.saved_ads ? true : false)
                        setEarnMoney(user.get_user_details.want_earn_money ? true : false)
                        setPhoto(user.get_user_details.profile_image_path);
                    }
                }
                setLoading(false)
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [token])
    const changeCheckBox = (e) => {
        var val = e.target.id;
        if (val === "save") {
            setSaveAd(!saveAd);
        }
        if (val === "earn") {
            setEarnMoney(!earnMoney);
        }
    }
    const onSubmit = async (rData) => {
        if (rData.profile_image.length > 0) {
            rData.profile_image = {
                base64: await getBase64(rData.profile_image[0])
            }
        } else {
            delete rData.profile_image;
        }
        setLoading(true)
        var reqData = {
            role: data.role,
            user_type: data.user_type,
            saved_ads: saveAd ? 1 : 0,
            want_earn_money: earnMoney ? 1 : 0,
            ...rData
        }
        var res = await postWithToken('/api/updateProfile', token, reqData);
        setLoading(false)
        if (res.status === 200) {
            setResError("");
            auth.login(res.user_data);
            swal("Good job!", res.message, "success");
            if (completeProfileStatus === 0) {
                navigate('/view/home');
            }
        } else {
            setResError(res.message);
        }
    }
    return (
        <>
            {loading && <Loader />}
            <BreadCrumb title={"Profile"} />
            <div className="profile">
                <Container>
                    <div className="profile-bg">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <Form.Group className="mb-4 edit-profile">
                                            <Form.Control accept="image/png, image/jpeg" {...register('profile_image', { onChange: (e) => { setPhoto(URL.createObjectURL(e.target.files[0])) } })} type="file"></Form.Control>
                                            <Image src={photo ? photo : Editprofile} className="img-fluid" />
                                            <div className="edit-icon">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="profile-form login-form">
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Enter Name</Form.Label>
                                                    <Form.Control {...register('name')} type="text" maxLength={26}></Form.Control>
                                                    {errors.name && <p className='form-error'>{errors.name.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Contact Number</Form.Label>
                                                    <Form.Control {...register('mobile')} type="text" maxLength={10}></Form.Control>
                                                    {errors.mobile && <p className='form-error'>{errors.mobile.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Select Country</Form.Label>
                                                    <Form.Select {...register('country', { onChange: (e) => { getState(e.target.value) } })} aria-label="Default select example" >
                                                        <option value={0}>Select Your Country</option>
                                                        {countries && countries.map(i => (
                                                            <option key={i.id} selected={data && data.get_user_details && parseInt(data.get_user_details.country) === i.id ? true : false} value={i.id}>{i.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors.country && <p className='form-error'>{errors.country.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Select State</Form.Label>
                                                    <Form.Select {...register('state')} aria-label="Default select example" >
                                                        <option value={0}>Select Your State</option>
                                                        {states && states.map(i => (
                                                            <option key={i.id} selected={data && data.get_user_details && parseInt(data.get_user_details.state) === i.id ? true : false} value={i.id}>{i.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors.state && <p className='form-error'>{errors.state.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Total Earning</Form.Label>
                                                    <Form.Control {...register('total_earnings', { value: 0 })} type="number"></Form.Control>
                                                    {errors.total_earnings && <p className='form-error'>{errors.total_earnings.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Enable Location</Form.Label>
                                                    <Form.Control {...register('location')} type="text"></Form.Control>
                                                    {errors.location && <p className='form-error'>{errors.location.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Enter Age</Form.Label>
                                                    <Form.Control {...register('age')} type="text" maxLength={2}></Form.Control>
                                                    {errors.age && <p className='form-error'>{errors.age.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Gender</Form.Label>
                                                    <Form.Select {...register('gender')} aria-label="Default select example" >
                                                        <option value="">Select</option>
                                                        <option value="m">Male</option>
                                                        <option value="f">Female</option>
                                                    </Form.Select>
                                                    {errors.gender && <p className='form-error'>{errors.gender.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Marital Status</Form.Label>
                                                    <Form.Select {...register('marital_status')} aria-label="Default select example" >
                                                        <option value="">Select</option>
                                                        <option value="married">Married</option>
                                                        <option value="unmarried">Unmarried</option>
                                                        <option value="divorced">Divorced</option>
                                                        <option value="widowed">Widowed</option>
                                                    </Form.Select>
                                                    {errors.marital_status && <p className='form-error'>{errors.marital_status.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Student/Working</Form.Label>
                                                    <Form.Select {...register('student_working')} aria-label="Default select example" >
                                                        <option value="">Select</option>
                                                        <option value="student">Student</option>
                                                        <option value="working">Working</option>
                                                    </Form.Select>
                                                    {errors.student_working && <p className='form-error'>{errors.student_working.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Salary</Form.Label>
                                                    <Form.Control {...register('salary')} type="number"></Form.Control>
                                                    {errors.salary && <p className='form-error'>{errors.salary.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Religion</Form.Label>
                                                    <Form.Select {...register('religion')} aria-label="Default select example" >
                                                        <option value="">Select</option>
                                                        {religion && religion.map(el => (
                                                            <option key={el.id} value={el.id}>{el.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors.religion && <p className='form-error'>{errors.religion.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Address</Form.Label>
                                                    <FloatingLabel controlId="floatingTextarea2">
                                                        <Form.Control
                                                            {...register('address')}
                                                            as="textarea"
                                                            placeholder="Leave a comment here"
                                                            style={{ height: '100px' }}
                                                        />
                                                    </FloatingLabel>
                                                    {errors.address && <p className='form-error'>{errors.address.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={12} className="my-md-5 my-4">
                                                <ul className="justify-content-center switch-button">
                                                    <li >
                                                        <div className="switch">
                                                            <Form.Label>Saved Ads</Form.Label>
                                                            <Form.Check checked={saveAd} onChange={changeCheckBox} type="switch" id="save" />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="switch">
                                                            <Form.Label>Want to Earn Money?</Form.Label>
                                                            <Form.Check checked={earnMoney} onChange={changeCheckBox} type="switch" id="earn" />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col md={12}>
                                                {resError && <p className="form-error">{resError}</p>}
                                                <button type="submit" className="login-submit-button mb-4 btn btn-primary">Save</button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="advertiser_heading">
                        <h4>Password</h4>
                    </div>
                    <div className="reset-pass">
                        <ResetPassword token={token} />
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Profile;