import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Container, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import Editprofile from './../../assets/img/editprofile.png';
import { EditProfileStrSchema } from "../../variables/validation_schema";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
// import UploadImage from "./../../assets/img/gallery.png"
import closeicon from "../../assets/img/cloase-icon.png";
import { useAuth } from "../../auth/Auth";
import { getWithToken, postWithToken } from "../../variables/apiCall";
import { getBase64 } from "../../Helper";
import swal from "sweetalert";
import Loader from "../../components/Loader";
import ResetPassword from "../../components/Form/ResetPassword";


function StreamerProfile() {
    var auth = useAuth();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [photo, setPhoto] = useState("");
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [resError, setResError] = useState("");
    const { register, handleSubmit, setValue, formState: { errors }, } = useForm({
        resolver: yupResolver(EditProfileStrSchema),
    });
    var token = auth.getToken();
    const [show, setShow] = useState(false);
    const onSubmit = async (rData) => {
        if (rData.profile_image.length > 0) {
            rData.profile_image = {
                base64: await getBase64(rData.profile_image[0])
            }
        } else {
            delete rData.profile_image;
        }
        var reqData = {
            role: data.role,
            user_type: data.user_type,
            streamer_type: data.get_streamer_details.streamer_type,
            ...rData
        }
        var res = await postWithToken('/api/updateProfile', token, reqData);
        if (res.status === 200) {
            setResError("");
            swal("Good job!", res.message, "success");
        } else {
            setResError(res.message);
        }
    }
    const getState = async (cid) => {
        var response = await getWithToken('/api/countryStateList/' + cid, token);
        if (response.status === 200) {
            setStates(response.data);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                var response = await getWithToken('/api/user_profile/' + auth.id, token);
                if (response.status === 200) {
                    var user = response.data;
                    setData(user);
                    setValue("name", user.name);
                    setValue("mobile", user.mobile);
                    if (user.get_user_details !== "" && user.get_user_details !== null) {
                        getState(user.get_user_details.country)
                        setValue("location", user.get_user_details.location);
                        setValue("country", parseInt(user.get_user_details.country));
                        setValue("state", parseInt(user.get_user_details.state));
                        setValue("business_description", user.get_user_details.business_description);
                        setValue("total_earnings", user.get_user_details.total_earnings);
                        setPhoto(user.get_user_details.profile_image_path);
                    }
                }
                var conRes = await getWithToken('/api/countryList', token);
                if (conRes.status === 200) {
                    setCountries(conRes.data)
                }
                setLoading(false)
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [token])
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    return (
        <div className="addvertiser-header">
            {loading && <Loader />}
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Profile</h4>
                        </div>
                        <Row>
                            <Col md={10}>
                                <div className="profile-bg">
                                    <Row>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <div>
                                                <Form.Group className="mb-4 edit-profile">
                                                    <Form.Control accept="image/png, image/jpeg" {...register('profile_image', { onChange: (e) => { setPhoto(URL.createObjectURL(e.target.files[0])) } })} type="file"></Form.Control>
                                                    <Image src={photo ? photo : Editprofile} className="img-fluid" />
                                                    <div className="edit-icon">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            <div className="profile-form login-form">
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Enter Streamer’s Name</Form.Label>
                                                            <Form.Control {...register('name')} type="text" maxLength={26}></Form.Control>
                                                            {errors.name && <p className='form-error'>{errors.name.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Contact Number</Form.Label>
                                                            <Form.Control {...register('mobile')} type="text" maxLength={10}></Form.Control>
                                                            {errors.mobile && <p className='form-error'>{errors.mobile.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Select Country</Form.Label>
                                                            <Form.Select {...register('country', { onChange: (e) => { getState(e.target.value) } })} aria-label="Default select example" >
                                                                <option value={0}>Select Your Country</option>
                                                                {countries && countries.map(i => (
                                                                    <option key={i.id} selected={data && data.get_user_details && parseInt(data.get_user_details.country) === i.id ? true : false} value={i.id}>{i.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                            {errors.country && <p className='form-error'>{errors.country.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Select State</Form.Label>
                                                            <Form.Select {...register('state')} aria-label="Default select example" >
                                                                <option value={0}>Select Your State</option>
                                                                {states && states.map(i => (
                                                                    <option key={i.id} selected={data && data.get_user_details && parseInt(data.get_user_details.state) === i.id ? true : false} value={i.id}>{i.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                            {errors.state && <p className='form-error'>{errors.state.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Total Earning</Form.Label>
                                                            <Form.Control {...register('total_earnings', { value: 0 })} type="number"></Form.Control>
                                                            {errors.total_earnings && <p className='form-error'>{errors.total_earnings.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Enter Location</Form.Label>
                                                            <Form.Control {...register('location')} type="text"></Form.Control>
                                                            {errors.location && <p className='form-error'>{errors.location.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Business Description</Form.Label>
                                                            <FloatingLabel controlId="floatingTextarea2">
                                                                <Form.Control
                                                                    {...register('business_description')}
                                                                    as="textarea"
                                                                    placeholder="Leave a comment here"
                                                                    style={{ height: '100px' }}
                                                                />
                                                            </FloatingLabel>
                                                            {errors.business_description && <p className='form-error'>{errors.business_description.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    {/* <Col md={12}>
                                                        <div className="addbanner">
                                                            <Form.Label className="addBannerlink mb-0">Add banner to the slider
                                                                <div class="advertiser-add-location" ><p onClick={handleShow}>Add more <span><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg></span></p></div>
                                                            </Form.Label>
                                                            <Col md={4}>
                                                                <Form.Group className="mb-3 upload-profile">

                                                                    <Form.Control type="file"></Form.Control>
                                                                    <Image src={UploadImage} className="img-fluid" />
                                                                    <div className="upload-banner">
                                                                        <p>Upload Banner</p>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <p className="notebanner">Note: The banner size must be 1920X750.</p>
                                                        </div>
                                                    </Col> */}
                                                    <Col md={12}>
                                                        {resError && <p className='form-error'>{resError}</p>}
                                                        <button type="submit" className="login-submit-button mt-4 mb-4 btn btn-primary">Save</button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <div className="advertiser_heading">
                            <h4>Password</h4>
                        </div>
                        <Row>
                            <Col md={10}>
                                <div className="reset-pass">
                                    <ResetPassword token={token} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Modal centered
                size='lg'
                show={show} onHide={handleClose} animation={false}>
                <p onClick={handleClose} className="closeiconall">
                    <Image src={closeicon}></Image>
                </p>
                <Modal.Body className='login-form  mt-0 '>
                    <h6 className='tittle-heading mb-5'>Choose plans</h6>
                    <Row>
                        <Col md={6} className="mx-auto">
                            <div className="planbanner">
                                <div className="planbannerBox mb-4">
                                    <Form.Check
                                        type={"radio"}
                                        value={"phone"}
                                        label={`5-Day `}
                                    />
                                    <p className="bannerprice">$10 </p>
                                </div>
                                <div className="planbannerBox mb-4">
                                    <Form.Check
                                        type={"radio"}
                                        value={"phone"}
                                        label={`10-Days `}
                                    />
                                    <p className="bannerprice">$10 </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <button type="submit" className=" mt-5 upload-appoved" >Choose Plan</button>
                </Modal.Body>
            </Modal>
        </div>
    );
}


export default StreamerProfile;