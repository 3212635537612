import React from "react";
import { Image } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./../../assets/img/logo.png";
import GooglePlay from "./../../assets/img/google-play.png";
import playStore from "./../../assets/img/app-stroe.png";
import {FaTwitter,  FaFacebookF, FaLinkedinIn, FaInstagram} from 'react-icons/fa';



function HomeFooter(props) {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <Container>
                <div className="footer-section">
                    <Row>
                        <Col md={6} sm={6} xs={12} lg={5} className="mb-4">
                            <div className="footer-logo">
                                <Link to="#">
                                    <Image src={Logo} />
                                </Link>
                            </div>
                            <div className="footer-para">
                                <p>Lorem Ipsum is simply dummy text of the printing<br></br>
                                    and typesetting industry. Lorem Ipsum has been the industry's</p>
                            </div>
                            <div className="footer-social-media footer-heading mb-4 mb-sm-0">
                                <h5>Social Media</h5>
                                <div className="footer-icon">
                                    <ul>
                                    <li>
                                            <Link to="#">
                                           <FaFacebookF />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                            <FaTwitter />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <FaLinkedinIn />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <FaInstagram />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={12} lg={2} className="mb-4">
                            
                            <div className="footer-heading">
                                <h5>Pages</h5>
                                <div className="footer-nav-link">
                                    <ul>
                                        <li>
                                            <Link to="/all/movie">Movie</Link>
                                        </li>
                                        <li>
                                            <Link to="/all/tv_show">Tv Shows</Link>
                                        </li>
                                        <li>
                                            <Link to="/page/about">About Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/page/contact_us">Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        {/* <Col md={6} sm={6} xs={12} lg={2} className="mb-4">
                            <div className="footer-heading">
                                <h5>Service</h5>
                                <div className="footer-nav-link">
                                    <ul>
                                        <li>
                                            <Link to="">FAQ</Link>
                                        </li>
                                        <li>
                                            <Link to="">Help Center</Link>
                                        </li>
                                        <li>
                                            <Link to="">Privacy Policy</Link>
                                        </li>
                                        <li>
                                            <Link to="/">Terms And Condition</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col> */}

                        <Col md={6} sm={6} xs={12} lg={3} className="mb-4">
                            <div className="footer-heading">
                                <h5>Download Apps</h5>
                                <div className="download-app">
                                    <div className="google-play">
                                    <Image src={GooglePlay} />
                                    </div>
                                    <div className="play-store">
                                        <Image src={playStore} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                
            </Container>
            <div className="copyright">
                <Container>
                    <p>Copyright ©  {currentYear}  <Link to="/">Giant Slayer</Link>. All Rights Reserved.</p>
                </Container>    
            </div>
        </footer>
    );
}

export default HomeFooter;