import React from "react";
import DataTable, { createTheme } from "react-data-table-component";

export default function DataTables({ columns, data }) {
    createTheme('solarized', {
        borderRadius: {
            
        },
        text: {
            primary: '#aeaeae',
            secondary: '#2aa198',
        },
        background: {
            default: '#191919',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#303030',
        },
    }, 'dark');
    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            theme={"solarized"}
        />
    )
}