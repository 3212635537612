import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import { getUrl } from '../Helper';
import { get } from '../variables/apiCall';
import ModalMessage from "./../assets/img/message.png";

const btnStyle = {
    backgroundColor: 'transparent',
    border: 'none'
}

export default function WatchAd() {
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [adData, setAdData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            var res = await get('/api/viewer/ad_detail/' + atob(id));
            setLoading(false);
            if (res.status === 200) {
                setAdData(res.data);
            }
        }
        fetchData();
    }, [id])
    if (loading) {
        return <Loader />
    }
    const surve = e => {
        e.preventDefault();
    }
    return (
        <div className="watchmovie">
            <Container fluid>
                <div className="modal-message">
                    <Button style={btnStyle} onClick={surve}>
                        <Image src={ModalMessage} />
                    </Button>
                </div>
                <div className="playvideo mx-15">
                    <video autoPlay={true} controls poster={getUrl(adData.ads.thumbnail_url)}>
                        <source src={getUrl(adData.ads.video_url)} />
                    </video>
                </div>
                <div className="watchmovie-name mx-15">
                    <div className="watchmovie-heading">
                        <h2 variant="primary">{adData.ads.title}</h2>
                    </div>
                </div>
            </Container>
        </div>
    )
}
