import React, { useState } from 'react'
import { useEffect } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { useAuth } from '../../auth/Auth';
import { getWithToken } from '../../variables/apiCall';
import BreadCrumb from '../Breadcrumb/breadcrumb'
import Loader from '../Loader';

export default function AllNotifications() {
    const auth = useAuth();
    let token = auth.getToken();
    const [loading, setLoading] = useState(true);
    const [notiList, setNotiList] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            var res = await getWithToken('/api/notification', token);
            setLoading(false);
            if (res.status === 200) {
                setNotiList(res.data);
            }
        }
        fetchData();
    }, [token]);
    if (loading) {
        return <Loader />
    }
    return (
        <div className="watchmovie">
            <BreadCrumb title="Notifications" />
            <Container className="transaction-inner">
                <Row className='mt-5 mb-5 pt-4'>
                    <Col md="12">
                        <Tabs
                            defaultActiveKey="user"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="user" title="User">
                                <h1>hello this is task</h1>
                            </Tab>
                            <Tab eventKey="system" title="System">
                                <h1>hello this is tab</h1>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
