import React from 'react'
import { Doughnut } from 'react-chartjs-2';

export default function DoughnutChart({ labels, data, text }) {
    const fillData = {
        labels: labels,
        datasets: [
            {
                label: 'No. of Persons',
                data: data,
                borderWidth: '0',
                backgroundColor: [
                    '#9747FF',
                    '#FCA997',
                    '#B91293',
                    '#C3E1FF',
                    '#FB4E22'
                ],
            },
        ],
    };
    const option = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
                borderRadius: '100px',
                width: '20px',
                height: '20px'
            },
            colors: ['#546E7A', '#E91E63'],
            title: {
                display: true,
                position: 'right',
                text: text,
            },
        },
    };
    return (
        <Doughnut data={fillData} options={option} />
    )
}
