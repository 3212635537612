import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthHeader from "../components/Header/auth_header";
import dashRoutes from "../routes";
import AuthBg from "../assets/img/body-bg.png";

export default function AuthLayout() {
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/auth") {
                return (
                    <Route
                        path={prop.path}
                        element={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    return (
        <React.Fragment>
             {/* <style>
                {`

                html, body, #root {
                    height: 100vh
                    
                  }
                `}
            </style> */}
            <div className="auth-outer">
            <div className="auth-outer-background" style={{ backgroundImage: 'url(' + AuthBg + ')' }}></div>
                <AuthHeader />
                <Routes>
                    {getRoutes(dashRoutes)}
                </Routes>
            </div>
        </React.Fragment>
    );
}
