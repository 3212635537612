import React from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Container, FloatingLabel, Form, Image, Row } from "react-bootstrap";
import { Slider } from "@mui/material";
import UploadVideoImage from "./../../assets/img/video.png"
import Upload from "./../../assets/img/upload.png"
import { useState } from "react";
import { BlastSchema } from "../../variables/validation_schema";
import { useEffect } from "react";
import { useAuth } from "../../auth/Auth";
import { getWithToken, postWithToken, postWithTokenForm } from "../../variables/apiCall";
import Loader from "../../components/Loader";
import swal from "sweetalert";
import { getTenlevelArray } from "../../Helper";
import PaypalModal from "../../components/modal/PaypalModal";

function Blast() {
    const { register, watch, handleSubmit, setError, formState: { errors }, } = useForm({
        resolver: yupResolver(BlastSchema),
    });
    var sliderInitial = {
        min: 20,
        max: 60,
        val: [20, 60]
    }
    const auth = useAuth();
    let token = auth.getToken();
    const [UploadVideo, setUploadVideo] = useState(null);
    const [salary, setSalary] = useState(sliderInitial);
    const [age, setAge] = useState(sliderInitial);
    const [loading, setLoading] = useState(false);
    const [religion, setReligion] = useState([]);
    const [perUserPrice, setPerUserPrice] = useState(1);
    const [marks, setMarks] = useState([]);
    const [totalUser, setTotalUser] = useState(0);
    const [user, setUser] = useState(0);
    const [resError, setResError] = useState("");
    const handleSalChange = (event, val) => {
        var arr = {
            min: val[0],
            max: val[1],
            val: val
        }
        setSalary(arr);
    };
    const handleAgeChange = (event, val) => {
        var arr = {
            min: val[0],
            max: val[1],
            val: val
        }
        setAge(arr);
    };
    const changeMedia = (e) => {
        setUploadVideo(URL.createObjectURL(e.target.files[0]));
    }
    const onSubmit = async (data) => {
        if (UploadVideo === undefined || UploadVideo === null) {
            setError('video', { type: 'custom', message: "This field is required." });
            return true;
        }
        setLoading(true)
        var reqData = {
            ...data,
            min_salary: salary.min,
            max_salary: salary.max,
            min_age: age.min,
            max_age: age.max
        }
        const dataa = new FormData();
        Object.keys(reqData).forEach(function (key) {
            if (key === "video") {
                dataa.append(key, reqData[key][0]);
            } else {
                dataa.append(key, reqData[key]);
            }
        });
        var response = await postWithTokenForm('/api/add_blast', token, dataa);
        setLoading(false);
        if (response.status === 200) {
            setResError("");
            swal("Good Job", response.message, "success");
        } else {
            setResError(response.message);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                var res = await getWithToken('/api/religion_list', token);
                if (res.status === 200) {
                    setReligion(res.data);
                }
                var respo = await getWithToken('/api/setting/per_user_price', token);
                if (respo.status === 200) {
                    setPerUserPrice(respo.data);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        fetchData();
    }, [token]);
    const getFilterUser = async () => {
        let filterData = watch();
        delete filterData.message;
        delete filterData.video;
        var reqData = {
            ...filterData,
            min_salary: salary.min,
            max_salary: salary.max,
            min_age: age.min,
            max_age: age.max
        }
        var respons = await postWithToken('/api/filter_viewer', token, reqData);
        if (respons.status === 200) {
            var c = respons.data;
            setTotalUser(c)
            setUser(c)
            var arr;
            if (c < 10) {
                arr = [0, c];
            } else {
                arr = getTenlevelArray(c);
            }
            var bArr = [];
            arr.map(el => {
                var a = {
                    value: el,
                    label: el
                }
                bArr.push(a);
                return false;
            })
            setMarks(bArr);
        }
    }
    const sliderChange = (e, val) => {
        setUser(val);
    }
    return (
        <div className="addvertiser-header">
            {loading && <Loader />}
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Blast</h4>
                        </div>
                        <Row>
                            <Col md={12} lg={10}>
                                <div className="place-ads">
                                    <div className="place-ads-table">
                                        <div className="login-form mt-0">
                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="row">
                                                    <Col xl={4}  sm={6}>
                                                        <div>
                                                            <Form.Label>Upload Video (Optional)</Form.Label>
                                                            <Form.Group className="mb-4 upload-profile">
                                                                <Form.Control accept="video/mp4,video/*" {...register('video', { onChange: changeMedia })} type="file"></Form.Control>
                                                                {UploadVideo ? <video src={UploadVideo} controls /> :
                                                                    <Image src={UploadVideoImage} className="img-fluid upload_img" />}
                                                                <div className="upload-banner">
                                                                    <p><Image src={Upload} />Upload Video</p>
                                                                </div>
                                                                {errors.video && <p className='form-error'>{errors.video.message}</p>}
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Radius Message </Form.Label>
                                                            <FloatingLabel controlId="floatingTextarea2">
                                                                <Form.Control
                                                                    {...register('message')}
                                                                    as="textarea"
                                                                    placeholder="Leave a comment here"
                                                                    style={{ height: '100px' }}
                                                                />
                                                            </FloatingLabel>
                                                            {errors.message && <p className='form-error'>{errors.message.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                </div>
                                                <div className="targetedaudian mt-2">
                                                    <div className="profile-form login-form mt-0">
                                                        <h6 className="mb-4">Select Targeted Audience</h6>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <Form.Label>Location</Form.Label>
                                                                    <Form.Select {...register('location')} aria-label="Default select example" >
                                                                        <option value="">Select</option>
                                                                        <option value="1">location</option>
                                                                    </Form.Select>
                                                                    {errors.location && <p className='form-error'>{errors.location.message}</p>}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <Form.Label>Gender</Form.Label>
                                                                    <Form.Select {...register('gender')} aria-label="Default select example" >
                                                                        <option value="">Select</option>
                                                                        <option value="m">Male</option>
                                                                        <option value="f">Female</option>
                                                                    </Form.Select>
                                                                    {errors.gender && <p className='form-error'>{errors.gender.message}</p>}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <Form.Label>Marital Status</Form.Label>
                                                                    <Form.Select {...register('marital_status')} aria-label="Default select example" >
                                                                        <option value="">Select</option>
                                                                        <option value="married">Married</option>
                                                                        <option value="unmarried">Unmarried</option>
                                                                        <option value="divorced">Divorced</option>
                                                                        <option value="widowed">Widowed</option>
                                                                    </Form.Select>
                                                                    {errors.marital_status && <p className='form-error'>{errors.marital_status.message}</p>}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <Form.Label>Religion</Form.Label>
                                                                    <Form.Select {...register('religion')} aria-label="Default select example" >
                                                                        <option value="">Select</option>
                                                                        {religion && religion.map(el => (
                                                                            <option key={el.id} value={el.id}>{el.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    {errors.religion && <p className='form-error'>{errors.religion.message}</p>}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <Form.Label>Student/Working</Form.Label>
                                                                    <Form.Select {...register('student_working')} aria-label="Default select example" >
                                                                        <option value="">Select</option>
                                                                        <option value="student">Student</option>
                                                                        <option value="working">Working</option>
                                                                    </Form.Select>
                                                                    {errors.student_working && <p className='form-error'>{errors.student_working.message}</p>}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <Form.Label>Salary</Form.Label>
                                                                    <div className="sliderlabel">{salary.min} k - {salary.max} k</div>
                                                                    <Slider className="thumbslidedouble"
                                                                        value={salary.val}
                                                                        height="30"
                                                                        onChange={handleSalChange}
                                                                        valueLabelDisplay="auto"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <Form.Label>Age</Form.Label>
                                                                    <div className="sliderlabel">{age.min} - {age.max}</div>
                                                                    <Slider className="thumbslidedouble"
                                                                        value={age.val}
                                                                        height="30"
                                                                        onChange={handleAgeChange}
                                                                        valueLabelDisplay="auto"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Button type="button" onClick={getFilterUser} className=" pe-4  ps-4 login-submit-button mt-md-5 mb-md-0 mb-4">Filter Values</Button>
                                                            </Col>
                                                            {totalUser && (
                                                                <>
                                                                    <Col md={12}>
                                                                        <Form.Group className="mb-4">
                                                                            <Form.Label>Based on selection, number will seen here</Form.Label>
                                                                            <Form.Select aria-label="Default select example" >
                                                                                <option>{totalUser} Candidates Added</option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="total-prices mt-2">
                                                                            <div className="border-border1-1">
                                                                                <div className="row">
                                                                                    <Col md="10">
                                                                                        <Form.Label>Total Price</Form.Label>
                                                                                        <Slider className="thumbslidedouble"
                                                                                            key="slider-price"
                                                                                            aria-label="Custom marks"
                                                                                            value={user}
                                                                                            step={1}
                                                                                            min={0}
                                                                                            max={totalUser}
                                                                                            onChange={sliderChange}
                                                                                            valueLabelDisplay="auto"
                                                                                            marks={marks}
                                                                                        />
                                                                                    </Col>
                                                                                </div>
                                                                            </div>
                                                                            <div className="border-border1">
                                                                                <div className="priceuser">
                                                                                    <h5>Price/User</h5>
                                                                                    <p>${perUserPrice} / User</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="border-border1">
                                                                                <div className="priceuser">
                                                                                    <h5>Total Price</h5>
                                                                                    <p>${perUserPrice * user}</p>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </Col>
                                                                </>
                                                            )}
                                                            <Col md={12} className="mt-5 mb-5">
                                                                {resError && <p className="form-error">{resError}</p>}
                                                                <button type="submit" className="login-submit-button mt-2 btn btn-primary">Pay Now & Share</button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <PaypalModal />
        </div>
    );
}


export default Blast;