import React from "react";
import MovieSlider from "../../components/Home/movie-slider";
import TvShows from "../../components/Home/tv-shows";
import LiveTv from "../../components/Home/live-tv";
import UpcomingTv from "../../components/Home/upcoming-live";
import NetworkTv from "../../components/Home/network-tv";
import NightLife from "../../components/Home/nightlife";
import ViewerSlider from "../../components/Home/ViewerSlider";
import withProfileCheck from "../../components/HOC/withProfileCheck";

class ViewerHomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }
    render() {
        return (
            <div className="overfilo-div">
                <ViewerSlider />
                <MovieSlider />
                <TvShows />
                <LiveTv />
                <UpcomingTv />
                <NetworkTv />
                <NightLife />
            </div>
        );
    }
}

export default withProfileCheck(ViewerHomePage);