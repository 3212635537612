import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import HomeFooter from '../components/Footer/home_footer';
import HomeHeader from '../components/Header/home_header';
import Loader from '../components/Loader';
import { getTitleName } from "../Helper";
import BreadCrumb from "../components/Breadcrumb/breadcrumb";
import { get } from '../variables/apiCall';

export default function StaticPage() {
    const [pageData, setPageData] = useState(null);
    const params = useParams();
    let { slug } = params;
    useEffect(() => {
        const fetchData = async () => {
            if (slug === undefined || slug === null || slug === "") {
                setPageData({
                    title: "Error 404",
                    description: "Page Not found"
                });
            } else {
                var res = await get("/api/page/" + slug);
                if (res.status === 200) {
                    setPageData(res.data);
                }
            }
        }
        fetchData();
    }, [slug])
    if (pageData === null) {
        return <Loader />
    }
    return (
        <div className="overfilo-div">
            
            <HomeHeader  />
            <BreadCrumb title={getTitleName(slug)} />
            <Container className='mt-5 mb-md-5'>
            <div className="single-content">
                <h2>{pageData.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: pageData.description }}></div>
            </div>
            </Container>
            <HomeFooter />
        </div>
    )
}
