import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';

const useProfileCheck = () => {
    const auth = useAuth();
    let user = auth.user;
    if (user.profile_status) {
        return true;
    } else {
        return false;
    }
}

const withProfileCheck = Component => {
    return (props) => {
        const isProfileComplete = useProfileCheck(); // You need to implement this function to check whether the profile is complete or not
        if (!isProfileComplete) {
            return <Navigate to='/view/profile' />
        }
        return <Component {...props} />
    }
};
export default withProfileCheck;