import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import BreadCrumb from '../../components/Breadcrumb/breadcrumb';
import DataTables from '../../components/DataTables';
import withProfileCheck from '../../components/HOC/withProfileCheck';
import Loader from '../../components/Loader';
import { getWithToken } from '../../variables/apiCall';

function Transaction() {
    const auth = useAuth();
    let token = auth.getToken();
    const [loading, setLoading] = useState(true);
    const [adData, setAdData] = useState([]);
    const columns = [
        {
            name: "Plan Title",
            selector: row => row.plan
        },
        {
            name: "Plan Type",
            selector: row => row.plan_type.toUpperCase(),
        },
        {
            name: "Transaction Id",
            selector: row => row.transaction_id,
        },
        {
            name: "Start Date",
            selector: row => row.start_date,
        },
        {
            name: "End Date",
            selector: row => row.end_date,
        },
        {
            name: "Status",
            cell: (row) => {
                if (row.status === 1)
                    return <Button type="button" className={"active-button"}>{"Success"}</Button>
                else
                    return <Button type="button" className={"inactive-button"}>{"Expired"}</Button>
            }
        },
        {
            name: "Action",
            cell: (row) => {
                if (row.plan_type === "ppv") {
                    if (row.type === "video") {
                        return <Link to={"/view/watch"} className={"active-button"} state={{ vid: row.video_id }}>Show Video</Link>
                    } else {
                        return <Link to={"/view/live"} className={"active-button"} state={{ sid: row.video_id }}>Show Stream</Link>
                    }
                }
            }
        }
    ];
    useEffect(() => {
        const fetchData = async () => {
            var res = await getWithToken('/api/viewer/list_transaction', token);
            setLoading(false);
            if (res.status === 200) {
                setAdData(res.data);
            }
        }
        fetchData();
    }, [token])
    if (loading) {
        return <Loader />
    }
    return (
        <div className="watchmovie">
            <BreadCrumb title="Transactions" />
            <Container className="transaction-inner">
                <Row className='mt-5 mb-5 pt-4'>
                    <Col md="12">
                        <DataTables
                            columns={columns}
                            data={adData}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withProfileCheck(Transaction);
