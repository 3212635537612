import { useAuth } from "../auth/Auth";
import { BaseUrl, StreamUrl } from "./general";
const Header = {
    'Content-Type': 'application/json'
}

const StreamHeader = {
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa("OPENVIDUAPP:giantslayer")}`
}

export async function get(url) {
    const response = await fetch(BaseUrl + url, {
        headers: new Headers(Header),
    });
    return await response.json();
}

export async function post(url, body = {}) {
    const response = await fetch(BaseUrl + url, {
        method: 'POST',
        headers: new Headers(Header),
        body: JSON.stringify(body)
    });
    return await response.json();
}

export async function getStream(url) {
    try {
        const response = await fetch(StreamUrl + url, {
            headers: new Headers(StreamHeader),
        });
        return await response.json();
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function postStream(url, body = {}) {
    const response = await fetch(StreamUrl + url, {
        method: 'POST',
        headers: new Headers(StreamHeader),
        body: JSON.stringify(body)
    });
    return await response.json();
}

export async function getWithToken(url, token) {
    var secureHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    }
    const response = await fetch(BaseUrl + url, {
        headers: new Headers(secureHeaders),
    });
    var res = await response.json();
    if (res.status === 400 && "logout" in res && res.logout) {
        Logout();
    }
    return res;
}

export async function postWithToken(url, token, body = {}) {
    var secureHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    }
    const response = await fetch(BaseUrl + url, {
        method: 'POST',
        headers: new Headers(secureHeaders),
        body: JSON.stringify(body)
    });
    var res = await response.json();
    if (res.status === 400 && "logout" in res && res.logout) {
        Logout();
    }
    return res;
}

async function Logout() {
    var auth = useAuth();
    auth.logout();
}

export async function postWithTokenForm(url, token, body = {}) {
    const secureHeadersFormdata = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    }
    const response = await fetch(BaseUrl + url, {
        method: 'POST',
        headers: new Headers(secureHeadersFormdata),
        body: body
    });
    ;
    return await response.json();
}