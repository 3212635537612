import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container } from "react-bootstrap";
import 'react-owl-carousel';
import { Link } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";





function BreadCrumb({ title }) {
    return (
        <div className="breadcrumb">
            <Container fluid>
                <div className="breadcrumb-banner mx-15">
                    <div className="banner-heading">
                        <h4>{title}</h4>
                    </div>
                    <div className="breadcrumb-item">
                        <ul>
                            <li><Link to={"/"}>Home</Link></li>
                            <li>
                                <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                            </li>
                            <li>{title}</li>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default BreadCrumb;