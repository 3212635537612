import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import RegisterForm from "../../components/Form/register";

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }
    render() {
        return (
            <div className="login-form-layout">
                <Container>
                    <Row>
                        <Col md={8} sm={10} xs={12} xl={5} lg={6}  className="mx-auto">
                            <div className="register">
                                <div className="login-heading ">
                                    <h4>Sign Up</h4>
                                    <p>Create an account to continue enjoying uninterrupted<br></br>
                                        video and personalised experience</p>
                                </div>
                                <div className="login-form">
                                    <RegisterForm />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default SignUp;