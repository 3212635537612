import React from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import Loader from "../Loader";
import { useEffect } from 'react';

const style = { "layout": "vertical" };

export default function Paypal({ success, currency, showSpinner, amount }) {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);
    return (
        <>
            {(showSpinner && isPending) && <Loader />}
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: amount,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            console.log(orderId, "orderid");
                            return orderId;
                        });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then(function (details) {
                        success(details);
                    });
                }}
            />
        </>
    );
}