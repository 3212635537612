import React, { useEffect } from 'react'
import { Col, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import imgError from "../../assets/img/error-page.png";

export default function Error500() {
    const navigate = useNavigate();
    useEffect(() => {
        function handleClick(event) {
            if (!event.detail.isOpen) {
                navigate(-1);
            }
        }
        window.addEventListener('devtoolschange', handleClick);
        return () => {
            window.removeEventListener('devtoolschange', handleClick);
        }
    }, [])

    return (
        <div className="errorPage">
            <Row className='algin-items-center justify-content-center'>
                <Col md="8" className='mx-auto'>
                    <Image className="img-errow" src={imgError} />
                    <h2>Please close devtools to view content</h2>
                </Col>
            </Row>
        </div>
    )
}
