import React from "react";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown, Nav } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./../../assets/img/logo.png";
import { useAuth } from "../../auth/Auth";
import userprofle from '../../assets/img/user-profile.png';


function StaffHeader() {
    var auth = useAuth();
    return (
        <div className="addvertiser-head">
            <Navbar expand="lg" className="py-0">
                <Container fluid>
                    <Navbar id="navbarScroll" className="advertiser-header">
                        <Form className="d-flex align-items-center">
                            <div className="advertiser-mobile-header">
                                <Link to={"/"}> <Image src={Logo} />
                                </Link>
                            </div>
                        </Form>
                        <div className="search-bar">
                            <Nav className="nav-link">
                            <p className="userNmae">Welcome  {auth.user && auth.user.name}</p>
                                <NavDropdown className="user-profile" style={{ backgroundImage: 'url(' + userprofle + ')' }}>
                                    <NavDropdown.Item onClick={(e) => { e.preventDefault(); auth.logout() }} href="/" > Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </div>
                    </Navbar>
                </Container>
            </Navbar>
        </div>
    );
}

export default StaffHeader;
