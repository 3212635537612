import React from 'react';
import { Col, Image, Row, Button } from 'react-bootstrap';
import imgError from "../../assets/img/error-page.png";
import { useNavigate } from "react-router-dom";

export default function Error404() {
    const navigate = useNavigate();
    const goHome = (e) => {
        e.preventDefault();
        navigate("/");
    }
    return (
        <div className="errorPage">
            <Row className='algin-items-center justify-content-center'>
                <Col md="8" className='mx-auto'>
                    <Image className="img-errow" src={imgError} />
                    <h2>Oops! This Page is Not Found.</h2>
                    <p>The requested page dose not exist or you do not have right permissions to access this page.</p>
                    <Button onClick={goHome} className="banner-button">Back to Home</Button>
                </Col>
            </Row>
        </div>
    )
}

