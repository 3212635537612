import React, { useEffect } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Container, Row, FloatingLabel } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Form } from "react-bootstrap";
import UploadVideo from "./../../assets/img/video.png"
import UploadImage from "./../../assets/img/gallery.png"
import Upload from "./../../assets/img/upload.png"
import { Link, useNavigate } from "react-router-dom";
import { StreamUpload } from "../../variables/validation_schema";
import { useState } from "react";
import { getWithToken, postWithTokenForm } from "../../variables/apiCall";
import { useAuth } from "../../auth/Auth";
import swal from "sweetalert";
import MultiSelectBox from "../../components/MultiSelectBox";
import Loader from "../../components/Loader";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from "@mui/material";
function Stream() {
    const [loading, setLoading] = useState(false);
    const [nightLife, setNightLife] = useState(false);
    const [resError, setResError] = useState("");
    const [gen, setGen] = useState([]);
    const [media, setMedia] = useState({});
    const [planType, setPlanType] = useState("svod");
    const [genre, setGenre] = useState([]);
    let auth = useAuth();
    let navigate = useNavigate();
    let token = auth.getToken();
    const { register, handleSubmit, setValue, setError, formState: { errors }, } = useForm({
        resolver: yupResolver(StreamUpload),
    });
    const changeMedia = (e) => {
        var name = e.target.name;
        setMedia({ ...media, [name]: URL.createObjectURL(e.target.files[0]) })
    }
    const [timeVal, setTimeVal] = useState(null);
    const handleChange = (newValue) => {
        setTimeVal(newValue);
    };
    const onSubmit = async (data) => {
        var err = false;
        if (media.trailer_video_url === undefined) {
            setError('trailer_video_url', { type: 'custom', message: "This field is required." });
            err = true;
        }
        if (media.trailer_thumbnail_url === undefined) {
            setError('trailer_thumbnail_url', { type: 'custom', message: "This field is required." });
            err = true;
        }
        if (timeVal === null) {
            setError('time', { type: 'custom', message: "This field is required." });
            err = true
        }
        if (timeVal.isValid() === false) {
            setError('time', { type: 'custom', message: "Time is not valid. Please choose valid time format." });
            err = true
        }
        if (!err) {
            setLoading(true)
            var reqData = new FormData();
            reqData.append('time', timeVal.format('HH:mm:00'));
            Object.keys(data).forEach(function (key) {
                if (key === "trailer_video_url") {
                    reqData.append(key, data[key][0]);
                } else if (key === "trailer_thumbnail_url") {
                    reqData.append(key, data[key][0]);
                } else {
                    reqData.append(key, data[key]);
                }
            });
            var res = await postWithTokenForm("/api/upload_stream", token, reqData)
            setLoading(false)
            if (res.status === 200) {
                setResError("")
                swal("Good job!", res.message, "success");
                navigate("/str/stream-list");
            } else {
                setResError(res.message)
            }
        }
    }
    const changeGen = (g) => {
        setGen(g);
        setValue('genre', g.toString(), { shouldValidate: true })
    }
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            var res = await getWithToken('/api/genre_list', token);
            if (res.status === 200) {
                var gd = [];
                res.data.map(el => {
                    gd.push({
                        name: el.title,
                        id: el.id
                    })
                    return false;
                })
                setGenre(gd);
            }
            setLoading(false)
        }
        fetchData();
    }, [token])
    return (
        <div className="addvertiser-header">
            {loading && <Loader />}
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Upload</h4>
                            <Link to={"/str/stream-list"} className="submit-button">View Upcomming Streams</Link>
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="place-ads">
                                    <div className="place-ads-table">
                                        <div className="login-form mt-0">
                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                <Row>
                                                    <Col md="12" className="mb-md-4 mb-4">
                                                        <div className="liskwebstis">
                                                            <div className="switch">
                                                                <Form.Label>Night Life Stream</Form.Label>
                                                                <Form.Check type="switch" id="custom-switch" checked={nightLife} {...register('night_life', { onChange: () => { setNightLife(!nightLife) } })} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <Form.Label>Upload Trailer Thumbnail</Form.Label>
                                                            <Form.Group className="mb-4 upload-profile">
                                                                <Form.Control accept="image/png, image/jpeg" {...register('trailer_thumbnail_url', { onChange: changeMedia })} type="file"></Form.Control>
                                                                {media && media.trailer_thumbnail_url ? <Image src={media.trailer_thumbnail_url} className="img-fluid imguploing" /> :
                                                                    <Image src={UploadImage} className="img-fluid upload_img" />}
                                                                <div className="upload-banner">
                                                                    <p><Image src={Upload} />Upload Image</p>
                                                                </div>
                                                                {errors.trailer_thumbnail_url && <p className='form-error'>{errors.trailer_thumbnail_url.message}</p>}
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <Form.Label>Upload Trailer</Form.Label>
                                                            <Form.Group className="mb-4 upload-profile">
                                                                <Form.Control accept="video/mp4,video/*" {...register('trailer_video_url', { onChange: changeMedia })} type="file"></Form.Control>
                                                                {media && media.trailer_video_url ? <video src={media.trailer_video_url} width="300" controls /> :
                                                                    <Image src={UploadVideo} className="img-fluid upload_img" />}
                                                                <div className="upload-banner">
                                                                    <p><Image src={Upload} />Upload Video</p>
                                                                </div>
                                                                {errors.trailer_video_url && <p className='form-error'>{errors.trailer_video_url.message}</p>}
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Enter Title</Form.Label>
                                                            <Form.Control {...register('title')} type="text"></Form.Control>
                                                            {errors.title && <p className='form-error'>{errors.title.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Enter Tagline </Form.Label>
                                                            <Form.Control {...register('tagline')} type="text"></Form.Control>
                                                            {errors.tagline && <p className='form-error'>{errors.tagline.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4 genre-width">
                                                            <Form.Label>Enter Genre</Form.Label>
                                                            <Form.Control type='hidden' {...register('genre')}></Form.Control>
                                                            <MultiSelectBox data={genre} personName={gen} setPersonName={changeGen} />
                                                            {errors.genre && <p className='form-error'>{errors.genre.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Choose Date for Live Stream </Form.Label>
                                                            <Form.Control {...register('date')} type="date"></Form.Control>
                                                            {errors.date && <p className='form-error'>{errors.date.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Choose Time for Live Stream</Form.Label>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <TimePicker className="time-picker"
                                                                    label="Choose Time for Live Stream"
                                                                    value={timeVal}
                                                                    onChange={handleChange}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                            {errors.time && <p className='form-error'>{errors.time.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Stream Description</Form.Label>
                                                            <FloatingLabel controlId="floatingTextarea2">
                                                                <Form.Control
                                                                    {...register('description')}
                                                                    as="textarea"
                                                                    placeholder="Leave a comment here"
                                                                    style={{ height: '100px' }}
                                                                />
                                                            </FloatingLabel>
                                                            {errors.description && <p className='form-error'>{errors.description.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Add Ad Slots</Form.Label>
                                                            <Form.Select {...register('ad_slots')} aria-label="Default select example" >
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                            </Form.Select>
                                                            {errors.ad_slots && <p className='form-error'>{errors.ad_slots.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Choose Plan</Form.Label>
                                                            <Form.Select {...register('plan_type', { onChange: e => { setPlanType(e.target.value) } })} aria-label="Default select example" >
                                                                <option value="svod">SVOD</option>
                                                                <option value="avod">AVOD</option>
                                                                <option value="ppv">PPV</option>
                                                            </Form.Select>
                                                            {errors.plan_type && <p className='form-error'>{errors.plan_type.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    {planType === "ppv" &&
                                                        <div className="targetedaudian mb-4">
                                                            <h6 className="mb-4">Create Plan</h6>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <Form.Group className="mb-4">
                                                                        <Form.Label>Message to Viewer </Form.Label>
                                                                        <Form.Control {...register('plan_title')} type="text"></Form.Control>
                                                                        {errors.plan_title && <p className='form-error'>{errors.plan_title.message}</p>}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Form.Group className="mb-4">
                                                                        <Form.Label>Price</Form.Label>
                                                                        <Form.Control type="tel" pattern="^[0-9]*[.,]?[0-9]*$" {...register('plan_price', {
                                                                            valueAsNumber: true,
                                                                        })} min="0" step="0.01"></Form.Control>
                                                                        {errors.plan_price && <p className='form-error'>{errors.plan_price.message}</p>}
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </div>}
                                                    <Col md={12} className="text-center">
                                                        {resError && <p className="form-error">{resError}</p>}
                                                        <Button type="submit" className="mx-auto mt-5 btn btn-primary upload-button">Ask for Approval</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}


export default Stream;