import React, { Component } from 'react'
import { Button, Image } from 'react-bootstrap';
import { OpenVidu } from 'openvidu-browser';
import { getStream, postStream, postWithToken } from '../../variables/apiCall';
import { getUrl } from '../../Helper';
import OpenViduVideoComponent from './OvVideo';
import Loader from '../Loader';
import { FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

class LiveStream extends Component {
    constructor(props) {
        super(props);

        // These properties are in the state's component in order to re-render the HTML whenever their values change
        this.state = {
            loading: false,
            deviceList: [],
            mySessionId: this.props.str.id + '',
            session: undefined,
            ads: [],
            pause: false,
            playAds: 0,
            currAd: null,
            currentVideoDevice: {},
            mainStreamManager: undefined,  // Main video of the page. Will be the 'publisher' or one of the 'subscribers'
        };
        this.updateAds = this.updateAds.bind(this);
        this.switchCamera = this.switchCamera.bind(this);
        this.joinSession = this.joinSession.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.pauseSession = this.pauseSession.bind(this);
        this.onbeforeunload = this.onbeforeunload.bind(this);
    }
    componentDidMount() {
        window.addEventListener('beforeunload', this.onbeforeunload);
    }

    componentDidUpdate() {
        var { str } = this.props;
        if (str.advertisement.length && str.advertisement !== this.state.ads) {
            this.setState({ ads: str.advertisement });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onbeforeunload);
    }

    onbeforeunload(event) {
        this.leaveSession();
    }

    joinSession() {
        this.OV = new OpenVidu();
        this.setState(
            {
                loading: true,
                session: this.OV.initSession(),
            },
            () => {
                var mySession = this.state.session;

                // --- 3) Specify the actions when events take place in the session ---

                // On every new Stream received...
                mySession.on('streamCreated', (event) => {
                    // Subscribe to the Stream to receive it. Second parameter is undefined
                    // so OpenVidu doesn't create an HTML video by its own
                });
                // On every Stream destroyed...
                mySession.on('streamDestroyed', (event) => {
                    // Remove the stream from 'subscribers' array
                });
                // On every asynchronous exception...
                mySession.on('exception', (exception) => {
                    console.warn(exception);
                });
                // --- 4) Connect to the session with a valid user token ---
                // Get a token from the OpenVidu deployment
                this.getToken().then((token) => {
                    // First param is the token got from the OpenVidu deployment. Second param can be retrieved by every user on event
                    // 'streamCreated' (property Stream.connection.data), and will be appended to DOM as the user's nickname
                    mySession.connect(token, { clientData: this.props.user.name })
                        .then(async () => {
                            // --- 5) Get your own camera stream ---
                            // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
                            // element: we will manage it on our own) and with the desired properties
                            let publisher = await this.OV.initPublisherAsync(undefined, {
                                audioSource: undefined, // The source of audio. If undefined default microphone
                                videoSource: undefined, // The source of video. If undefined default webcam
                                publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                                publishVideo: true, // Whether you want to start publishing with your video enabled or not
                                resolution: '1280x720', // The resolution of your video
                                frameRate: 30, // The frame rate of your video
                                insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                                mirror: false, // Whether to mirror your local video or not
                            });

                            // --- 6) Publish your stream ---

                            mySession.publish(publisher);

                            // Obtain the current video device in use
                            var devices = await this.OV.getDevices();
                            var videoDevices = devices.filter(device => device.kind === 'videoinput');
                            var currentVideoDeviceId = publisher.stream.getMediaStream().getVideoTracks()[0].getSettings().deviceId;
                            var currentVideoDevice = videoDevices.find(device => device.deviceId === currentVideoDeviceId);
                            // Set the main video in the page to display our webcam and store our Publisher
                            this.setState({
                                loading: false,
                                deviceList: videoDevices,
                                currentVideoDevice: currentVideoDevice,
                                mainStreamManager: publisher
                            });
                            await postWithToken('/api/stream_status/' + this.props.str.id, this.props.token, { status: 1 });
                        })
                        .catch((error) => {
                            console.log('There was an error connecting to the session:', error.code, error.message);
                        });
                });
            },
        );
    }

    async leaveSession() {
        const mySession = this.state.session;
        if (mySession) {
            mySession.disconnect();
        }
        await postWithToken('/api/stream_status/' + this.props.str.id, this.props.token, { status: 2 });
        this.OV = null;
        this.setState({
            session: undefined,
            mainStreamManager: undefined,
        });
    }
    pauseSession() {
        const mySession = this.state.session;
        const publisher = this.state.mainStreamManager;
        if (mySession) {
            if (this.state.ads.length > this.state.playAds) {
                var ad = this.state.ads[this.state.playAds];
                this.setState({ currAd: ad })
            }
            mySession.unpublish(publisher);
            this.setState({ pause: true });
        }
    }

    playSession() {
        const mySession = this.state.session;
        const publisher = this.state.mainStreamManager;
        if (mySession) {
            mySession.publish(publisher);
            this.setState({ pause: false });
        }
    }
    updateAds() {
        var pa = this.state.playAds;
        this.setState({
            playAds: pa + 1,
            currAd: null
        })
        this.playSession()
    }
    async switchCamera(e) {
        try {
            var videoDevices = this.state.deviceList;
            if (videoDevices && videoDevices.length > 1) {
                var newVideoDevice = videoDevices.find(device => device.deviceId === e.target.value);
                if (Object.keys(newVideoDevice).length > 0) {
                    var newPublisher = this.OV.initPublisher(undefined, {
                        videoSource: newVideoDevice.deviceId,
                        publishAudio: true,
                        publishVideo: true,
                        mirror: true
                    });
                    await this.state.session.unpublish(this.state.mainStreamManager)
                    await this.state.session.publish(newPublisher)
                    this.setState({
                        currentVideoDevice: newVideoDevice,
                        mainStreamManager: newPublisher,
                    });
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { str } = this.props;
        const { session, mainStreamManager, ads, playAds, currAd, loading, pause, deviceList, currentVideoDevice } = this.state;
        if (loading) {
            return <Loader />
        }
        return (
            <>
                {session === undefined ? (
                    <div className="playadd mt-4">
                        <Image src={getUrl(str.trailer_thumbnail_url)} />
                    </div>
                ) : null}

                {session !== undefined && mainStreamManager !== undefined && (
                    <div id="main-video" className="playadd mt-4">
                        {pause ? <div className='ads_video text-center'>
                            {currAd ? <video src={getUrl(currAd.video_url)} autoPlay={true} onEnded={() => this.updateAds()} />
                                : <Image src={getUrl(str.trailer_thumbnail_url)} />}
                        </div> :
                            <div className="streamcomponent text-center">
                                <OpenViduVideoComponent streamManager={mainStreamManager} />
                            </div>}
                    </div>
                )}
                <div className="startstream mt-5">
                    {session === undefined ?
                        <Button className="starStrem" onClick={() => this.joinSession()}>{"Start Stream"}</Button>
                        : (<>
                            {ads.length && ads.length - playAds && (
                                <Button className="playAdd">{ads.length - playAds} in Queue <Tooltip title="Pause video to play Ad">
                                    <IconButton>
                                        <HelpOutline />
                                    </IconButton>
                                </Tooltip></Button>
                            )}
                            {pause ? <Button className="starStrem" onClick={() => this.playSession()}>{"Play Stream"}</Button> :
                                <Button className="starStrem" onClick={() => this.pauseSession()}>{"Pause Stream"}</Button>
                            }
                            {deviceList.length > 0 && (
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Video devices</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={currentVideoDevice.deviceId}
                                        label="Video devices"
                                        onChange={this.switchCamera}
                                    >
                                        {deviceList.map((device, i) => (
                                            <MenuItem key={i} value={device.deviceId}>{device.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            <Button className="starStrem" onClick={() => this.leaveSession()}>{"End Stream"}</Button>
                        </>)}
                </div>
            </>
        );
    }
    async getToken() {
        var sessionId = null;
        sessionId = await this.getSession(this.state.mySessionId);
        if (sessionId === null) {
            sessionId = await this.createSession(this.state.mySessionId);
        }
        return await this.createToken(sessionId);
    }

    async getSession(sessionId) {
        const response = await getStream('sessions/' + sessionId)
        if (response) {
            return response.id; // The sessionId
        }
        return response;
    }

    async createSession(sessionId) {
        const response = await postStream('sessions', { customSessionId: sessionId })
        return response.id; // The sessionId
    }

    async createToken(sessionId) {
        const response = await postStream('sessions/' + sessionId + '/connection', {})
        return response.token; // The token
    }
}

export default LiveStream;