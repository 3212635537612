import React, { useRef, useEffect } from 'react';
import SliderItem from "../SliderItem"
import SliderContext from "./context"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft, faUndo } from '@fortawesome/free-solid-svg-icons'
import useSlider from "../../hooks/useSlide"
import useWindowDimensions from '../../variables/useWindowDimensions';

function Slider({ data, fun }) {
    const { width } = useWindowDimensions();
    const ref = useRef(null)
    const {
        moveSection,
        scaleTiles,
        resetSize,
        sliderPages,
        slideProps,
        hasPrev,
        hasNext,
        content,
        currentSlide,
        paginationIndicator
    } = useSlider(width, ref, 2, data);
    const contextValue = {
        currentSlide
    };

    useEffect(() => { }, [width])
    return (
        <SliderContext.Provider value={contextValue}>
            <div className="sliderBox">
                <div className="slider-container">
                    <div className="slider">
                        <ul className="pagination-indicator">
                            {sliderPages > 0 ? (paginationIndicator(sliderPages)) : ""}
                        </ul>

                        <div className="sliderMask showPeek">
                            <div className={`slider-content ${currentSlide ? "open" : ""}`} ref={ref} {...slideProps} >
                                {content && content.map(item => {
                                    return <SliderItem
                                        key={item.id}
                                        title={item.id}
                                        data={item}
                                        hover={scaleTiles}
                                        reset={resetSize}
                                        transform={item.transform}
                                        origin={item.origin}
                                        fun = {fun}
                                    />
                                })}
                            </div>
                        </div>
                        {hasNext && (<span className="handle handleNext" onClick={() => moveSection("right")}>
                            <strong><FontAwesomeIcon icon={faChevronRight} /></strong>
                        </span>)}
                        {hasPrev && (<span className="handle handlePrev" onClick={() => moveSection("left")}>
                            <strong><FontAwesomeIcon icon={faChevronLeft} /></strong>
                        </span>)}
                        {hasPrev && hasNext === false ? (<span className="handle handleNext" onClick={() => moveSection("reset")}>
                            <strong><FontAwesomeIcon icon={faUndo} /></strong>
                        </span>) : null}
                    </div>
                </div>
            </div>
        </SliderContext.Provider>
    );
}

export default Slider;
