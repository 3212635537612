import React from "react";



function StreamerFooter(props) {
    const currentYear = new Date().getFullYear();
    return (
        
            <div className="advertiser-copyright"><div className="container"><p>Copyright © {currentYear}  <a href="/">Giant Slayer</a>. All Rights Reserved.</p></div></div>
       
    );
}

export default StreamerFooter