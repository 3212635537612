import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { postWithToken } from '../../variables/apiCall'
import { DafaultCurrency, PaypalClientId } from '../../variables/general'
import Paypal from '../Payment/Paypal'

export default function PaypalModal({ show, amnt, pc, plan, token, type, vType, vId }) {
    const [spin, setSpin] = useState(false);
    const paySuccess = async (detail) => {
        var reqData = {
            plan_id: plan,
            plan_type: type,
            transaction_id: detail.purchase_units[0].payments.captures[0].id
        };
        if (vType !== null) {
            if (vType === "video") {
                reqData.video_id = vId;
            } else {
                reqData.stream_id = vId;
            }
        }
        var res = await postWithToken("/api/viewer/add_transaction", token, reqData);
        if (res.status === 200) {
            pc(true);
        } else {
            pc();
        }
    }
    useEffect(() => {
        setSpin(show);
    }, [show])

    const onhide = (e) => {
        setSpin(false);
        pc();
    }
    return (
        <Modal show={show} onHide={onhide} animation={false} size="lg" centered >
            <Modal.Body className='modal-position'>
                <div style={{ maxWidth: "750px", minHeight: "200px" }}>
                    <PayPalScriptProvider
                        options={{
                            "client-id": PaypalClientId,
                            components: "buttons",
                            currency: DafaultCurrency
                        }}
                    >
                        <Paypal
                            success={paySuccess}
                            currency={DafaultCurrency}
                            showSpinner={spin}
                            amount={amnt}
                        />
                    </PayPalScriptProvider>
                </div>
            </Modal.Body>
        </Modal>
    )
}
