import Container from 'react-bootstrap/Container';
import Image from "react-bootstrap/Image";
import Logo from "./../../assets/img/logo.png";
import { Link } from "react-router-dom";

function AuthHeader() {
	return (
		<div className="login-header">
			<Container>
				<Link to={"/"}> <Image src={Logo} /></Link>
			</Container>
		</div>
	);
}

export default AuthHeader;