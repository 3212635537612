import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from "react-bootstrap/Image";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from "./../../assets/img/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Form, Offcanvas } from "react-bootstrap";
import menuToggle from './../../assets/img/menu.png'
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/Auth";
import { VerifyToken } from "../../auth/RequireAuth";
import userprofle from '../../assets/img/user-profile.png';
import Notifications from "../Notifications";


function HomeHeader() {
    var navigate = useNavigate();
    const [scroll, setScroll] = useState(false)
    const [search, setSearch] = useState(false);
    var auth = useAuth();
    var imgSrc = userprofle;
    if (auth.user && auth.user.userprofile) {
        imgSrc = auth.user.userprofile;
    }
    const storedToken = localStorage.getItem("token") ?? null;
    if (storedToken && !auth.id) {
        VerifyToken(storedToken);
    }
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50)
        })
    }, [])
    const showSearchBox = (e) => {
        var value = !search;
        setSearch(value);
    }

    const [show, setShow] = useState(false);
    const navigateToProfile = (e) => {
        e.preventDefault();
        var type = auth.user.user_type;
        switch (type) {
            case "1":
                navigate("/str/profile");
                break;
            case "2":
                navigate("/adv/profile");
                break;
            default:
                navigate("/view/profile");
                break;
        }
    }
    const goHome = (e) => {
        e.preventDefault();
        if (auth.id && auth.user.user_type === "3") {
            navigate("/view/home");
        } else {
            navigate("/");
        }
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className={scroll ? "header-top scrolled" : "header-top "} >
            <div className="desktop-header">
                <Navbar collapseOnSelect expand="lg" variant="dark">
                    <Container fluid>
                        <Navbar.Brand onClick={(e) => { e.preventDefault(); navigate("/") }} href={"/"}><Image src={Logo} /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="right-header">
                            <Nav>
                                <Nav.Link onClick={goHome} href="#" className="active"

                                >Home</Nav.Link>
                                <Nav.Link href="/all/movie" >Movie</Nav.Link>
                                <Nav.Link href="/all/tv_show">Tv Shows</Nav.Link>
                                <Nav.Link href="/page/about">About</Nav.Link>
                                {/* <Nav.Link href="/page/services">Services</Nav.Link> */}
                                <Nav.Link href="/page/contact_us">Contact Us</Nav.Link>
                                <div className="search-bar">
                                    <Nav.Link href="#deets" className="searchicon" >
                                        <div onClick={showSearchBox}>
                                            {search ? <FontAwesomeIcon icon={faTimes} className="searchiconsize" /> : <FontAwesomeIcon icon={faSearch} className="searchiconsize" />}
                                        </div>
                                        {search && (
                                            <div className="search-icon-dropdwon">
                                                <Form>
                                                    <div className="search-input">
                                                        <input name='name' type='text' placeholder='Name' className="form-control" />
                                                        <FontAwesomeIcon icon={faSearch} />
                                                    </div>
                                                </Form>
                                            </div>)}
                                    </Nav.Link>
                                    {auth.id && (<Nav className="nav-link">
                                        <Notifications />
                                        <p className="userNmae">Welcome  {auth.user && auth.user.name}</p>

                                        <NavDropdown className="user-profile" style={{ backgroundImage: 'url(' + imgSrc + ')' }}>

                                            <NavDropdown.Item onClick={navigateToProfile} href={"/view/profile"}>Profile</NavDropdown.Item>
                                            {auth.user && auth.user.user_type === "3" && (
                                                <>
                                                    <NavDropdown.Item onClick={(e) => { e.preventDefault(); navigate("/view/save-ads") }} href={"/view/save-ads"}>Saved Ads</NavDropdown.Item>
                                                    <NavDropdown.Item onClick={(e) => { e.preventDefault(); navigate("/view/transactions") }} href={"/view/transactions"}>Transactions</NavDropdown.Item>
                                                </>
                                            )}
                                            <NavDropdown.Item onClick={(e) => { e.preventDefault(); auth.logout() }} href="/"> Logout</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                        </NavDropdown>
                                    </Nav>)}
                                </div>
                                {!auth.id && <Nav.Link onClick={(e) => { e.preventDefault(); navigate("/auth/signin") }} href={"/auth/signin"} className=" submit-button header-lgoin">Log in</Nav.Link>}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <Container fluid>
                <div className="mobile-desktop">
                    <div className="mobile-logo">
                        <Link to={"/"}> <Image src={Logo} /> </Link>
                    </div>
                    <div className="left-header">
                        <div className="right-header">
                            <div className="search-bar">
                                <Nav.Link href="#deets" className="searchicon" >
                                    <div onClick={showSearchBox}>
                                        {search ? <FontAwesomeIcon icon={faTimes} className="searchiconsize" /> : <FontAwesomeIcon icon={faSearch} className="searchiconsize" />}
                                    </div>
                                    {search && (
                                        <div className="search-icon-dropdwon">
                                            <Form>
                                                <div className="search-input">
                                                    <input name='name' type='text' placeholder='Name' className="form-control" />
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </div>
                                            </Form>
                                        </div>)}
                                </Nav.Link>
                                <Nav.Link href="#deets"> <FontAwesomeIcon icon={faBell} className="bellicon" /></Nav.Link>
                                {auth.id && (<Nav className="nav-link">
                                    <p className="userNmae">Welcome  {auth.user && auth.user.name}</p>

                                    <NavDropdown className="user-profile" style={{ backgroundImage: 'url(' + userprofle + ')' }}>

                                        <NavDropdown.Item onClick={navigateToProfile} href={"/view/profile"}>Profile</NavDropdown.Item>
                                        {auth.user && auth.user.user_type === "3" && (
                                            <>
                                                <NavDropdown.Item onClick={(e) => { e.preventDefault(); navigate("/view/save-ads") }} href={"/view/save-ads"}>Saved Ads</NavDropdown.Item>
                                                <NavDropdown.Item onClick={(e) => { e.preventDefault(); navigate("/view/transactions") }} href={"/view/transactions"}>Transactions</NavDropdown.Item>
                                            </>
                                        )}
                                        <NavDropdown.Item onClick={(e) => { e.preventDefault(); auth.logout() }} href="/"> Logout</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                    </NavDropdown>
                                </Nav>)}
                            </div>

                        </div>
                        {!auth.id && <Nav.Link onClick={(e) => { e.preventDefault(); navigate("/auth/signin") }} href={"/auth/signin"} className=" submit-button header-lgoin me-2">Log in</Nav.Link>}

                        <div onClick={handleShow} className="mobile-menu" >
                            <Image src={menuToggle} />
                        </div>

                    </div>
                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header>
                            <Offcanvas.Title> <Image src={Logo} />
                                <div className="offcanvas-close" onClick={handleClose}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <ul>
                                <li >
                                    <Link onClick={goHome} >
                                        Home
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/all/movie">
                                        Movie
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/all/tv_show">Tv Shows
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/page/about">About
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/page/contact_us">Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
            </Container>
        </div>
    );
}

export default HomeHeader;