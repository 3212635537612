import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BaseUrl } from "../../variables/general";
import VideoJS from "./VideoJS";
import { getLength, getLengthTxt } from "../../Helper";
import ShowAdsModal from "../modal/ShowAdsModal";
import { postWithToken } from "../../variables/apiCall";


function Video({ video, vs, ads, token }) {
    const [show, setShow] = useState(false);
    const [length, setLength] = useState(null);
    const [adsVideo, setAdsVideo] = useState([]);
    const [vt, setVt] = useState("avod");
    const [ip, setIp] = useState(false);
    const [currAd, setCurrAd] = useState([]);
    const [adTime, setAdTime] = useState(0);
    const playerRef = React.useRef(null);
    const videoJsOptions = {
        autoplay: false,
        controls: vs,
        responsive: true,
        fluid: true,
        poster: BaseUrl + "/" + video.main_thumbnail_url,
        sources: [{
            src: BaseUrl + "/" + video.main_video_url
        }]
    };

    const handleClose = () => {
        playerRef.current.play();
        setShow(false)
    }
    const handlePlayerReady = async (player) => {
        playerRef.current = player;
        var reqData = {
            view_id: video.id,
            view_type: "video"
        };
        await postWithToken("/api/user_view", token, reqData);
        // You can handle player events here, for example:
        // player.on('waiting', () => {
        //     console.log('player is waiting');
        // });
        player.on(['playing', 'pause'], () => {
            setIp(playerRef.current.paused());
        });
        // player.on("timeupdate", () => {
        //     console.log(player.currentTime());
        // });
        // player.on('dispose', () => {
        //     console.log('player will dispose');
        // });
    };
    useEffect(() => {
        const fetchData = async () => {
            if (ads) {
                if (video.adDetails.length > 0) {
                    var adsAr = [];
                    video.adDetails.map(ad => {
                        for (let index = 0; index < ad.ad_slots; index++) {
                            adsAr.push(ad);
                        }
                        setAdsVideo(adsAr);
                        if (length) {
                            var a = adsAr.length + 1
                            var dt = length / a;
                            setAdTime(dt);
                        }
                        return false;
                    })
                }
            }
            setVt(video.plan_type);
            if (video.main_video_url) {
                var time = await getLength(BaseUrl + "/" + video.main_video_url);
                setLength(time);
            }
        }
        fetchData();
    }, [video, ads])

    const playAds = () => {
        if (adsVideo.length > 0) {
            var arr = adsVideo;
            var option = Math.floor(Math.random() * arr.length);
            var removed = arr.splice(option, 1);
            setAdsVideo(arr);
            setCurrAd(removed);
            setShow(true);
        }
        setAdTime(adTime + adTime);
    }
    useEffect(() => {
        if (show && !ip) {
            playerRef.current.pause();
        }
    }, [show, ip])
    return (
        <div className="watchmovie">
            <Container fluid>
                <div className="playvideo mx-15">
                    <VideoJS options={videoJsOptions} playerRef={playerRef} adTime={adTime} vt={vt} playAds={playAds} onReady={handlePlayerReady} />
                </div>
                <div className="watchmovie-name mx-15">
                    <div className="watchmovie-heading">
                        <h2 variant="primary">{video.title}</h2>
                        <h5>{getLengthTxt(length)}</h5>
                        <p>{video.tagline}</p>
                    </div>
                    <div className="watchmovie-heading">
                        <h5 className="mb-2">Description</h5>
                        <Row>
                            <Col md={8}>
                                <p>{video.description}</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <ShowAdsModal show={show} data={currAd} handleClose={handleClose} />
        </div>
    );
}

export default Video;