const devtools = {
    isOpen: false,
};
const threshold = 170;
const emitEvent = (isOpen) => {
    window.dispatchEvent(new window.CustomEvent('devtoolschange', {
        detail: {
            isOpen,
        },
    }));
};
const main = ({
    emitEvents = true
} = {}) => {
    const widthThreshold = window.outerWidth - window.innerWidth > threshold;
    const heightThreshold = window.outerHeight - window.innerHeight > threshold;
    if (!(heightThreshold && widthThreshold) && ((window.Firebug && window.Firebug.chrome && window
        .Firebug.chrome.isInitialized) || widthThreshold || heightThreshold)) {
        if ((!devtools.isOpen) && emitEvents) {
            emitEvent(true);
        }
        devtools.isOpen = true;
    } else {
        if (devtools.isOpen && emitEvents) {
            emitEvent(false);
        }
        devtools.isOpen = false;
    }
};
main({
    emitEvents: false
});
setInterval(main, 500);
export default devtools;