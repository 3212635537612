import React, { useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = (props) => {
    const videoRef = React.useRef(null);
    const playerRef = props.playerRef;
    const [currentTime, setCurrentTime] = useState(null);
    const { options, onReady, adTime } = props;

    React.useEffect(() => {
        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
            const videoElement = document.createElement("video-js");

            videoElement.className = 'videojs-big-play-centered';
            videoRef.current.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, options, () => {
                videojs.log('player is ready');
                onReady && onReady(player);
            });
            player.on("timeupdate", () => {
                setCurrentTime(player.currentTime());
            });

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            const player = playerRef.current;
            if (player.controls() !== options.controls) {
                player.controls(options.controls);
                player.autoplay(options.autoplay);
                player.src(options.sources);
            }
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;
        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    React.useEffect(() => {
        if (props.vt === "avod" && currentTime > adTime) {
            const player = playerRef.current;
            if (player.isFullscreen()) {
                player.exitFullscreen();
            }
            props.playAds();
            // player.pause();
        }
    }, [currentTime]);

    return (
        <div data-vjs-player className='videdojss'>
            <div ref={videoRef} />
        </div>
    );
}

export default VideoJS;