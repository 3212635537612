import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import BreadCrumb from '../../components/Breadcrumb/breadcrumb';
import withProfileCheck from '../../components/HOC/withProfileCheck';
import Loader from '../../components/Loader';
import { getUrl } from '../../Helper';
import { getWithToken } from '../../variables/apiCall';

function SavedAds() {
    const auth = useAuth();
    let token = auth.getToken();
    const [loading, setLoading] = useState(true);
    const [adData, setAdData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            var res = await getWithToken('/api/user_saved_ads_list', token);
            setLoading(false);
            if (res.status === 200) {
                setAdData(res.data);
            }
        }
        fetchData();
    }, [token])
    if (loading) {
        return <Loader />
    }
    return (
        <div className="watchmovie">
            <BreadCrumb title="Saved Ads" />
            <Container>
                <Row className='mt-5 mb-5 pt-4'>
                    {adData && adData.map(el => (
                        <Col key={el.id} md="4" className='mb-4'>
                            <div className='saved-image'>
                                <Link to={"/ad/watch/" + btoa(el.id)}>
                                    <Image src={getUrl(el.thumbnail_url)} className="img-fluid" />
                                    <div className='title-name'>
                                        <h5>{el.title}</h5>
                                    </div>
                                </Link>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}

export default withProfileCheck(SavedAds);