import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Row, Col, Image, Spinner } from 'react-bootstrap';
import Upload from "./../../assets/img/upload.png"
import UploadVideo from "./../../assets/img/video.png"
import closeicon from "../../assets/img/cloase-icon.png";
import { useState } from 'react';
import { AdShortSchema } from '../../variables/validation_schema';
import { postWithToken } from '../../variables/apiCall';
import { useAuth } from '../../auth/Auth';
import { getBase64, getLength } from '../../Helper';
import swal from 'sweetalert';
import { BaseUrl } from '../../variables/general';

export default function AdsModal({ handleclose, show, rowdata }) {
    const { register, handleSubmit, setValue, setError, formState: { errors }, } = useForm({
        resolver: yupResolver(AdShortSchema),
    });
    const auth = useAuth();
    const [currentVideo, doSetCurrentVideo] = useState(null);
    const [load, setLoad] = useState();
    const [editData, setEditData] = useState({})

    const onVideoSelected = ev => {
        const video = URL.createObjectURL(ev.target.files[0]);
        doSetCurrentVideo(video);
    };
    const modalClose = () => {
        doSetCurrentVideo(null);
        handleclose();
    }
    const onSubmit = async (data) => {
        // setLoad(true);
        if (data.video_url.length > 0) {
            var value = URL.createObjectURL(data.video_url[0]);
            var len = await getLength(value)
            var allowlen = [10, 15, 30, 45, 60];
            if (allowlen.includes(len)) {
                data.video_url = {
                    base64: await getBase64(data.video_url[0])
                }
            } else {
                setError('video_url', { type: 'custom', message: 'The ad can only be 10 sec., 15 sec., 30 sec. 45 sec., or 60 sec.' });
                setLoad(false);
                return
            }
        } else
            delete data.video_url;
        if (data.thumbnail_url.length > 0) {
            data.thumbnail_url = {
                base64: await getBase64(data.thumbnail_url[0])
            }
        } else
            delete data.thumbnail_url
        if (Object.keys(rowdata).length > 0) {
            data.id = rowdata.id;
            var result = await postWithToken("/api/update_placed_add", auth.getToken(), data);
            setLoad(false);
            if (result.status === 200) {
                swal("Good job!", result.message, "success");
            }
        } else {
            var result = await postWithToken("/api/place_add", auth.getToken(), data);
            setLoad(false);
            if (result.status === 200) {
                swal("Good job!", result.message, "success");
            }
        }
        modalClose();
    }

    if (rowdata && Object.keys(rowdata).length > 0) {
        if (editData !== rowdata) {
            setEditData(rowdata);
            var vurl = BaseUrl + "/" + rowdata.video_url;
            if (currentVideo !== vurl)
                doSetCurrentVideo(vurl);
            setValue("title", rowdata.title)
        }
    }

    return (
        <Modal centered
            size='md'
            show={show} onHide={modalClose} animation={false}>
            <p onClick={modalClose} className="closeiconall">
                <Image src={closeicon}></Image>
            </p>
            <Modal.Body className='login-form mt-0 '>
                <h6 className='tittle-heading mb-md-5 mb-4'>Upload An Advertisement</h6>
                {load ?
                    <Spinner animation="border" variant="warning" className="justify-content-center" />
                    :
                    <Form onSubmit={handleSubmit(onSubmit)} encType={"multipart/form-data"}>
                        <Row>
                            <Col lg={12} className="mx-auto">
                                <Form.Group className="mb-4 upload-profile">
                                    <Form.Control type="file" accept="video/mp4,video/*" {...register('video_url', { onChange: (e) => { onVideoSelected(e) } })} ></Form.Control>
                                    {currentVideo ? (<video src={currentVideo} width="300" controls />) : (
                                        <>
                                            <Image src={UploadVideo} className="img-fluid upload_img" />
                                            <div className="upload-banner">
                                                <p><Image src={Upload} />Upload Video</p>
                                            </div>
                                        </>
                                    )}
                                    {errors.video_url && <p className='form-error'>{errors.video_url.message}</p>}
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label>Enter Title</Form.Label>
                                    <Form.Control type='hidden' {...register('advertiser_id')} value={auth.id}></Form.Control>
                                    <Form.Control type="text" {...register('title')}></Form.Control>
                                    {errors.title && <p className='form-error'>{errors.title.message}</p>}
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label>Choose Thumbnail</Form.Label>
                                    <Form.Control accept="image/png, image/jpeg" type="file" placeholder='Upload Thumbnail' className='custochoose' {...register('thumbnail_url')}></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <button type="submit" className=" mt-4 upload-appoved" >Ask for Approval</button>
                    </Form>
                }
            </Modal.Body>
        </Modal>
    )
}