import React from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import Editprofile from './../../assets/img/editprofile.png';
import { EditProfileAdvSchema } from "../../variables/validation_schema";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useAuth } from "../../auth/Auth";
import { getWithToken, postWithToken } from "../../variables/apiCall";
import { getBase64 } from "../../Helper";
import swal from "sweetalert";
import Loader from "../../components/Loader";
import ResetPassword from "../../components/Form/ResetPassword";


function AdvertiserProfile() {
    var auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [photo, setPhoto] = useState("");
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [resError, setResError] = useState("");
    const { register, handleSubmit, setValue, formState: { errors }, } = useForm({
        resolver: yupResolver(EditProfileAdvSchema),
    });
    var token = auth.getToken();
    const onSubmit = async (rData) => {
        if (rData.profile_image.length > 0) {
            rData.profile_image = {
                base64: await getBase64(rData.profile_image[0])
            }
        } else {
            delete rData.profile_image;
        }
        setLoading(true);
        var reqData = {
            role: data.role,
            user_type: data.user_type,
            ...rData
        }
        var res = await postWithToken('/api/updateProfile', token, reqData);
        setLoading(false);
        if (res.status === 200) {
            setResError("");
            swal("Good job!", res.message, "success");
        } else {
            setResError(res.message);
        }
    }
    const getState = async (cid) => {
        var response = await getWithToken('/api/countryStateList/' + cid, token);
        if (response.status === 200) {
            setStates(response.data);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                var response = await getWithToken('/api/user_profile/' + auth.id, token);
                if (response.status === 200) {
                    var user = response.data;
                    setData(user);
                    setValue("name", user.name);
                    setValue("mobile", user.mobile);
                    if (user.get_user_details !== "") {
                        getState(user.get_user_details.country)
                        setValue("location", user.get_user_details.location);
                        setValue("country", user.get_user_details.country);
                        setValue("state", user.get_user_details.state);
                        setValue("business_description", user.get_user_details.business_description);
                        setValue("business_address", user.get_user_details.business_address);
                        setPhoto(user.get_user_details.profile_image_path);
                    }
                }
                var conRes = await getWithToken('/api/countryList', token);
                if (conRes.status === 200) {
                    setCountries(conRes.data)
                }
                setLoading(false)
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [token])
    return (
        <div className="addvertiser-header">
            {loading && <Loader />}
            <div className="advertiser-content">
                <Container fluid>
                    <div className="advertiser_profile">
                        <div className="advertiser_heading">
                            <h4>Profile</h4>
                        </div>
                        <Row>
                            <Col md={10}>
                                <div className="profile-bg">
                                    <Row>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <div>
                                                <Form.Group className="mb-4 edit-profile">
                                                    <Form.Control accept="image/png, image/jpeg" {...register('profile_image', { onChange: (e) => { setPhoto(URL.createObjectURL(e.target.files[0])) } })} type="file"></Form.Control>
                                                    <Image src={photo ? photo : Editprofile} className="img-fluid" />
                                                    <div className="edit-icon">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            <div className="profile-form login-form">
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Enter Business Name</Form.Label>
                                                            <Form.Control {...register('name')} type="text"></Form.Control>
                                                            {errors.name && <p className='form-error'>{errors.name.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Contact Number</Form.Label>
                                                            <Form.Control {...register('mobile')} type="text" maxLength={10}></Form.Control>
                                                            {errors.mobile && <p className='form-error'>{errors.mobile.message}</p>}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Select Country</Form.Label>
                                                            <Form.Select {...register('country', { onChange: (e) => { getState(e.target.value) } })} aria-label="Default select example" >
                                                                <option value={0}>Select Your Country</option>
                                                                {countries && countries.map(i => (
                                                                    <option key={i.id} selected={data && data.get_user_details && parseInt(data.get_user_details.country) === i.id ? true : false} value={i.id}>{i.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                            {errors.country && <p className='form-error'>{errors.country.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Select State</Form.Label>
                                                            <Form.Select {...register('state')} aria-label="Default select example" >
                                                                <option value={0}>Select Your State</option>
                                                                {states && states.map(i => (
                                                                    <option key={i.id} selected={data && data.get_user_details && parseInt(data.get_user_details.state) === i.id ? true : false} value={i.id}>{i.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                            {errors.state && <p className='form-error'>{errors.state.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Enter Location</Form.Label>
                                                            <Form.Control {...register('location')} type="text" maxLength={100}></Form.Control>
                                                            {/* <div className="advertiser-add-location">
                                                                <p>Add more <span>
                                                                    <FontAwesomeIcon icon={faPlus} />
                                                                </span></p>
                                                            </div> */}
                                                            {errors.location && <p className='form-error'>{errors.location.message}</p>}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={12}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Business Description</Form.Label>
                                                            <FloatingLabel controlId="floatingTextarea2">
                                                                <Form.Control
                                                                    {...register('business_description')}
                                                                    as="textarea"
                                                                    placeholder="Leave a comment here"
                                                                    style={{ height: '100px' }}
                                                                    maxLength={260}
                                                                />
                                                            </FloatingLabel>
                                                            {errors.business_description && <p className='form-error'>{errors.business_description.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label>Business Address</Form.Label>
                                                            <FloatingLabel controlId="floatingTextarea2">
                                                                <Form.Control
                                                                    {...register('business_address')}
                                                                    as="textarea"
                                                                    placeholder="Leave a comment here"
                                                                    style={{ height: '100px' }}
                                                                    maxLength={260}
                                                                />
                                                            </FloatingLabel>
                                                            {errors.business_address && <p className='form-error'>{errors.business_address.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        {resError && <p className='form-error'>{resError}</p>}
                                                        <button type="submit" className="login-submit-button mt-5 btn btn-primary">Save</button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <div className="advertiser_heading">
                            <h4>Password</h4>
                        </div>
                        <Row>
                            <Col md={10}>
                                <div className="reset-pass">
                                    <ResetPassword token={token} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}


export default AdvertiserProfile;