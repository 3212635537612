import React from "react";
import { Col, Container, Form, Row, Modal, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Navigate } from "react-router-dom";
import { post } from "../../variables/apiCall";
import success from "./../../assets/img/success.png";


class ResetPassowrd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            email: "",
            resError: "",
            modalShow: false,
            redirect: false
        };
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        var res = await post('/api/forgotPassword', { email: this.state.email });
        if (res.status === 200) {
            this.setState({
                modalShow: true,
                resError: ""
            });
        } else {
            this.setState({
                resError: res.message
            })
        }
        console.log(this.state.email);
    }
    render() {
        if (this.state.redirect) {
            return <Navigate to="/auth/signin" replace={true} />;
        }
        const { email, modalShow, resError } = this.state;
        return (
            <div className="login-form-layout">
                <Container>
                    <Row>
                        <Col md={8} sm={10} xs={12} xl={5} lg={6} className="mx-auto">
                            <div className="login">
                                <div className="login-heading">
                                    <h4>Reset Password</h4>
                                    <p>Enter your email address and we’ll send you an email with<br></br> instructions to reset your password</p>
                                </div>
                                <div className="login-form">
                                    {resError && <p className="form-error">{resError}</p>}
                                    <Form onSubmit={this.handleSubmit}>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group className=" mb-3">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control value={email} onChange={(e) => { this.setState({ email: e.target.value }) }} type="email" required />
                                                </Form.Group >
                                            </Col>
                                            <Col md={12}>
                                                <Button type="submit" className="login-submit-button mt-5">Reset</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    show={modalShow}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="success-modal"
                >
                    <Modal.Body>
                        <div className="login-heading">
                            <div className="success-img mb-3">
                                <Image src={success} />
                            </div>
                            <h4>Check Your Email</h4>
                            <p>A email has been send to your email@domain.com. Please check for an email from company and click on the included link to reset your password.</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => { this.setState({ modalShow: false, redirect: true }) }} className="banner-button mt-3">Back To Home</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

export default ResetPassowrd;