import React from "react";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useAuth } from "./Auth";
import { postWithToken } from "../variables/apiCall";

export const RequireAuth = ({ children }) => {
    const storedToken = localStorage.getItem("token");
    if (VerifyToken(storedToken)) {
        return children;
    } else {
        return <Navigate to={'/auth/signin'} replace />
    }
}

export const VerifyToken = (storedToken) => {
    const auth = useAuth();
    if (storedToken !== "undefined" && storedToken !== null && storedToken !== "") {
        let decodedData = jwt_decode(storedToken);
        let id = decodedData.sub;
        auth.setUserId(id);
        let expirationDate = decodedData.exp;
        var current_time = Date.now() / 1000;
        if (expirationDate < current_time) {
            localStorage.clear();
            return false;
        } else {
            if (!auth.user) {
                postWithToken('/api/get_user', storedToken).then(res => {
                    auth.login(res);
                })
            }
            return true;
        }
    }
    return false;
}