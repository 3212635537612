import React, { useEffect, useState } from "react";
import Video from "../../components/Movie/video";
import UpcomingTv from "../../components/Home/upcoming-live";
import { useAuth } from "../../auth/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import { getWithToken } from "../../variables/apiCall";
import Loader from "../../components/Loader";
import PpvModal from "../../components/modal/PpvModal";
import ShowOption from "../../components/modal/ShowOption";
import Notification from "../../components/Notification";
import withProfileCheck from "../../components/HOC/withProfileCheck";

function Watchingmovie() {
    const auth = useAuth();
    let token = auth.getToken();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(null);
    const location = useLocation();
    const [vs, setVs] = useState(false);
    const [ads, setAds] = useState(false);
    const [ppvShow, setPpvShow] = useState(false);
    const [avodShow, setAvodShow] = useState(false);
    const [videoData, setVideoData] = useState({});
    let { vid } = location.state;
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                var res = await getWithToken('/api/viewer/video_details/' + vid, token);
                var sts = false;
                if (res.status === 200) {
                    var video = res.data;
                    if (video.plan_type === "ppv") {
                        if (video.access) {
                            sts = true;
                        } else {
                            setPpvShow(true);
                        }
                    } else {
                        if (video.plan_type === "svod") {
                            if (video.access) {
                                sts = true;
                            } else {
                                navigate("/view/plan");
                            }
                        } else {
                            setAvodShow(true)
                        }
                    }
                    setVs(sts);
                    setVideoData(res.data);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        fetchData();
    }, [vid, token])
    const ppvModalClose = (pay = false) => {
        if (pay) {
            setVs(true);
        } else {
            navigate(-1)
        }
        setPpvShow(false)
    }
    const avodModalClose = () => {
        navigate(-1)
        setAvodShow(false)
    }
    const withAds = () => {
        setAds(true);
        setVs(true);
        setAvodShow(false);
    }
    if (loading) {
        return <Loader />;
    }
    return (
        <div>
            <Video video={videoData} vs={vs} ads={ads} token={token} />
            <UpcomingTv />
            <PpvModal video={videoData} show={ppvShow} modalClose={ppvModalClose} vType={"video"} />
            <ShowOption video={videoData} show={avodShow} modalClose={avodModalClose} withAds={withAds} />
            <Notification />
        </div>
    )
}

export default withProfileCheck(Watchingmovie);
