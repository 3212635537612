import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvide } from "./auth/Auth";
import ViewerLayout from "./layouts/Viewer";
import AuthLayout from "./layouts/Auth";
import Advertiser from "./layouts/Advertiser";
import Streamer from "./layouts/Streamer";
import Home from "./views/home";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import WatchAd from "./views/WatchAd";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Staff from "./layouts/Staff";
import StaticPage from "./views/StaticPage";
import SeeAll from "./views/Viewer/SeeAll";
import Error500 from "./components/Errors/Error500";
function App() {
	useEffect(() => {
		const handleContextmenu = e => {
			e.preventDefault()
		}
		document.addEventListener('contextmenu', handleContextmenu)
		document.onkeydown = function (e) {
			// disable F12 key
			if (e.keyCode === 123) {
				return false;
			}
			// disable I key
			if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
				return false;
			}
			// disable J key
			if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
				return false;
			}
			// disable U key
			if (e.ctrlKey && e.keyCode === 85) {
				return false;
			}
		}
		return function cleanup() {
			document.removeEventListener('contextmenu', handleContextmenu)
		}
	}, [])
	return (
		<AuthProvide>
			<GoogleOAuthProvider clientId="635444541458-q9pjpe706sluih5croievmg7kfurkrfh.apps.googleusercontent.com">
				<BrowserRouter>
					<Routes>
						<Route path={`/auth/*`} element={<AuthLayout />} />
						<Route path={`/view/*`} element={<ViewerLayout />} />
						<Route path={`/adv/*`} element={<Advertiser />} />
						<Route path={`/str/*`} element={<Streamer />} />
						<Route path={`/staff/*`} element={<Staff />} />
						<Route path={`/all/:slug`} element={<SeeAll />} />
						<Route path={`/page/:slug`} element={<StaticPage />} />
						<Route path="/ad/watch/:id" element={<WatchAd />} />
						<Route path={`/`} element={<Home />} />
						<Route path={`/dev`} element={<Error500 />} />
					</Routes>
				</BrowserRouter>
			</GoogleOAuthProvider>
		</AuthProvide>
	);
}

export default App;

