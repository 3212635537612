import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

class Otpinput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { otp1: "", otp2: "", otp3: "", otp4: "", error: "" };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ [name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let { otp1, otp2, otp3, otp4 } = this.state;
        if (otp1 && otp2 && otp3 && otp4) {
            this.setState({
                error: ""
            });
            let code = otp1 + otp2 + otp3 + otp4;
            this.props.verify(code);
        } else {
            this.setState({
                error: "Please provide valid OTP."
            });
        }
    }

    inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            if (next < 5) {
                elmnt.target.form.elements[next].focus()
            }
        }
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col md={12}>
                        <Form.Group className="text-center mb-3 verification-code">
                            <Form.Label>Verification Code</Form.Label>
                            <ul className="justify-content-center">
                                <li className="px-1">
                                    <Form.Control
                                        name="otp1"
                                        type="text"
                                        autoComplete="off"
                                        value={this.state.otp1}
                                        onChange={this.handleChange}
                                        tabIndex="1" maxLength="1" onKeyUp={e => this.inputfocus(e)}
                                    />
                                </li>
                                <li className="px-1">
                                    <Form.Control
                                        name="otp2"
                                        type="text"
                                        autoComplete="off"
                                        value={this.state.otp2}
                                        onChange={this.handleChange}
                                        tabIndex="2" maxLength="1" onKeyUp={e => this.inputfocus(e)}
                                    />
                                </li>
                                <li className="px-1">
                                    <Form.Control
                                        name="otp3"
                                        type="text"
                                        autoComplete="off"
                                        value={this.state.otp3}
                                        onChange={this.handleChange}
                                        tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)}
                                    />
                                </li>
                                <li className="px-1">
                                    <Form.Control
                                        name="otp4"
                                        type="text"
                                        autoComplete="off"
                                        value={this.state.otp4}
                                        onChange={this.handleChange}
                                        tabIndex="4" maxLength="1" onKeyUp={e => this.inputfocus(e)}
                                    />
                                </li>
                            </ul>
                            {this.state.error && (<p className='form-error'>{this.state.error}</p>)}
                        </Form.Group >
                    </Col>
                    <Col md={12}>
                        <button type="submit" className="login-submit-button mt-5">Verify</button>
                    </Col>
                </Row>
            </Form>
        );
    }
}


export default Otpinput;