import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Image } from "react-bootstrap";
import BreadCrumb from "../../components/Breadcrumb/breadcrumb";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../../components/Loader";
import { get } from "../../variables/apiCall";
import { getTitleName, getUrl } from "../../Helper";
import HomeHeader from "../../components/Header/home_header";
import HomeFooter from "../../components/Footer/home_footer";
import { useAuth } from "../../auth/Auth";
import SliderItem from "../../components/SliderItem";
import SeeAllGrid from "../../components/Slider/seeAllGrid";


function SeeAll() {
    const [pageData, setPageData] = useState([]);
    const params = useParams();
    const navigate = useNavigate();
    const auth = useAuth();
    let { slug } = params;
    useEffect(() => {
        const fetchData = async () => {
            if (slug === undefined || slug === null || slug === "") {
                setPageData({
                    title: "Error 404",
                    description: "Page Not found"
                });
            } else {
                var res = await get("/api/viewer/video_library?item_type=" + slug);
                if (res.status === 200) {
                    setPageData(res.data);
                }
            }
        }
        fetchData();
    }, [slug])
    const clickHandler = (id, e) => {
        e.preventDefault();
        if (auth.id) {
            navigate("/view/watch", { state: { vid: id } });
        } else {
            navigate("/auth/signin")
        }
    }
    if (pageData.length === 0) {
        return <Loader />
    }
    return (
        <div className="overfilo-div">
            <HomeHeader />
            <BreadCrumb title={getTitleName(slug)} />
            <div className="section-slider">
                <Container>
                <div className="outerSeeall">
                <SeeAllGrid data={pageData} fun={clickHandler} />
                </div>
                
                </Container>
            </div>
            <HomeFooter />
        </div>
    );
}

export default SeeAll;