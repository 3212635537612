import * as yup from 'yup';
import "yup-phone";
import { sensitiveWords } from './general';

export const registerSchema = yup.object().shape({
    name: yup.string()
        .required('Name is required.')
        .matches(/^[A-Z][a-z]*(\s[A-Z][a-z]*)*$/, 'Name cannot contain camelCase or special characters.')
        .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers or special characters.")
        .min(4, 'Name must be at least 4 characters long.'),
    email: yup.string().email('Invalid email address').required('Email is required').test(
        'is-lowercase',
        'Email must be in lowercase',
        value => value === value?.toLowerCase()
    ),
    mobile: yup.string()
        .required()
        .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits."),
    user_type: yup.number().moreThan(0, "Please select type.").required(),
    password: yup.string().required().min(8).max(25),
    cpassword: yup.string().required().min(8).max(25).oneOf([yup.ref("password")], "Confirm password is not match."),
    verify: yup.string().required("Please choose one option."),
    streamer_type: yup.number().when('user_type', { is: 1, then: yup.number().required().moreThan(0, "Please select type.") }),
    terms: yup.boolean().required().oneOf([true], 'Please allow terms & condition to signup.')
});

export const ResetPasswordSchema = yup.object().shape({
    current_password: yup.string().required("Password is a required field").min(8, "Password must be at least 8 characters").max(25),
    new_password: yup.string().required("Password is a required field").min(8, "Password must be at least 8 characters").max(25),
    confirm_password: yup.string().required("Password is a required field").min(8, "Password must be at least 8 characters").max(25).oneOf([yup.ref("new_password")], "Confirm password is not match."),
})

export const socialRegisterSchema = yup.object().shape({
    name: yup.string()
        .required('Name is required.')
        .matches(/^[A-Z][a-z]*(\s[A-Z][a-z]*)*$/, 'Name cannot contain camelCase or special characters.')
        .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers or special characters.")
        .min(4, 'Name must be at least 4 characters long.'),
    email: yup.string().email('Invalid email address').required('Email is required').test(
        'is-lowercase',
        'Email must be in lowercase',
        value => value === value?.toLowerCase()
    ),
    mobile: yup.string()
        .required()
        .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits."),
    user_type: yup.number().moreThan(0, "Please select type.").required(),
    password: yup.string().required().min(8).max(25),
    cpassword: yup.string().required().min(8).max(25).oneOf([yup.ref("password")], "Confirm password is not match."),
    streamer_type: yup.number().when('user_type', { is: 1, then: yup.number().required().moreThan(0, "Please select type.") }),
    terms: yup.boolean().required().oneOf([true], 'Please allow terms & condition to signup.')
});
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const loginSchema = yup.object().shape({
    email: yup.string()
        .matches(emailRegex, 'Email contains invalid characters.')
        .email('Invalid email format.')
        .required('Email is required.')
        .max(100, 'Email is too long'),
    password: yup.string()
        .required('Password is required.')
        .min(6, 'Password must be at least 6 characters long.')
        .max(15, 'Password cannot be more than 15 characters long.'),
});

export const AdShortSchema = yup.object().shape({
    title: yup.string().required(),
    video_url: yup.mixed().required(),
    thumbnail_url: yup.mixed().required(),
});

export const EditProfileAdvSchema = yup.object().shape({
    name: yup.string()
        .required('Name is required.')
        .matches(/^[A-Za-z\s]+$/, 'Name cannot contain numbers or special characters.')
        .min(4, 'Name is very short.'),
    mobile: yup.string()
        .required()
        .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits."),
    country: yup.number().moreThan(0, "Please select Country.").required(),
    state: yup.number().moreThan(0, "Please select State.").required(),
    location: yup.string().required().min(4, "Location is very short"),
    // business_description: yup.string().required("Please enter Business Description"),
    // business_address: yup.string().required("Please enter Business Address"),
    profile_image: yup.mixed().nullable(true)
});

export const EditProfileStrSchema = yup.object().shape({
    name: yup.string()
        .required('Name is required.')
        .matches(/^[A-Za-z\s]+$/, 'Name cannot contain numbers or special characters.')
        .min(4, 'Name is very short.'),
    mobile: yup.string()
        .required()
        .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits."),
    country: yup.number().moreThan(0, "Please select Country.").required(),
    state: yup.number().moreThan(0, "Please select State.").required(),
    location: yup.string().required(),
    total_earnings: yup.number().required("Please enter earnings"),
    // business_description: yup.string().required("Please enter Business Description"),
    profile_image: yup.mixed().nullable(true)
});

export const EditProfileViewerSchema = yup.object().shape({
    name: yup.string()
        .required('Name is required.')
        .matches(/^[A-Za-z\s]+$/, 'Name cannot contain numbers or special characters.')
        .min(4, 'Name is very short.'),
    mobile: yup.string()
        .required()
        .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits."),
    country: yup.number().moreThan(0, "Please select Country.").required(),
    state: yup.number().moreThan(0, "Please select State.").required(),
    total_earnings: yup.number().required("Please enter earnings"),
    location: yup.string().required(),
    age: yup.number().moreThan(10, "Age between in 10 to 100.").required(),
    gender: yup.string().required(),
    marital_status: yup.string().required("Please select marital status."),
    student_working: yup.string().required("Please select Working Type"),
    salary: yup.number().moreThan(0, "Please enter your salary.").required(),
    // religion: yup.string().required(),
    address: yup.string().required("Please enter Address"),
    profile_image: yup.mixed().nullable(true)
});

export const BlastSchema = yup.object().shape({
    message: yup.string().required(),
    location: yup.string().required(),
    gender: yup.string().required(),
    marital_status: yup.string().required("Please select marital status."),
    religion: yup.string().required(),
    student_working: yup.string().required("Please select Working Type"),
});
export const VideoUpload = yup.object().shape({
    trailer_video_url: yup.mixed(),
    trailer_thumbnail_url: yup.mixed(),
    main_video_url: yup.mixed(),
    main_thumbnail_url: yup.mixed(),
    description: yup.string()
        .required('Description is required')
        .test(
            'no-special-chars',
            'Description cannot contain special characters',
            value => /^[A-Za-z0-9\s]*$/.test(value)
        ),
    genre: yup.string().required("Please choose minimum one genre"),
    tagline: yup.string()
        .required('Tagline is required.')
        .matches(/^[A-Za-z0-9\s]+$/, 'Tagline cannot contain special characters.').test('not-offensive', 'Tagline contains offensive or violent or discriminatory or drug-related words.', value => {
            if (!value) return true;
            return !sensitiveWords.some(word => value.toLowerCase() === word);
        }),
    title: yup.string()
        .required('Title is required.')
        .matches(/^[A-Za-z0-9\s]+$/, 'Title cannot contain special characters.'),
    // .test('not-offensive', 'Tagline contains offensive words.', value => {
    //     if (!value) return true;
    //     return !sensitiveWords.some(word => value.toLowerCase().includes(word));
    // }),
});

export const VideoUpload1 = yup.object().shape({
    ad_slots: yup.string().required(),
    plan_type: yup.string(),
    ppv_plan_type: yup.number().when('plan_type', { is: "ppv", then: yup.number().moreThan(0, "Please select One Plan.").required("Please select One Plan") }),
    plan_title: yup.string().when('plan_type', { is: "ppv", then: yup.string().required("Title is required.") }),
    plan_price: yup.number("price must be in number format.").when('plan_type', { is: "ppv", then: yup.number("price must be in number format.").required("this field is required") }),
});

export const StreamUpload = yup.object().shape({
    trailer_video_url: yup.mixed(),
    trailer_thumbnail_url: yup.mixed(),
    description: yup.string().required(),
    genre: yup.string().required("Please choose minimum one genre"),
    date: yup.date().required().typeError("please enter a valid date"),
    tagline: yup.string()
        .required('Tagline is required.')
        .matches(/^[A-Za-z0-9\s]+$/, 'Tagline cannot contain special characters.').test('not-offensive', 'Tagline contains offensive or violent or discriminatory or drug-related words.', value => {
            if (!value) return true;
            return !sensitiveWords.some(word => value.toLowerCase() === word);
        }),
    title: yup.string()
        .required('Title is required.')
        .matches(/^[A-Za-z0-9\s]+$/, 'Title cannot contain special characters.'),
    ad_slots: yup.string().required(),
    plan_type: yup.string().required(),
    plan_title: yup.string().when('plan_type', { is: "ppv", then: yup.string().required("Title is required.") }),
    plan_price: yup.number()
        .typeError('Price must be in number format.')
        .when('plan_type', {
            is: 'ppv',
            then: yup.number()
                .required('Price is required.')
                .typeError('Price must be in number format.'),
        }),
});

export const StaffSchema = yup.object().shape({
    name: yup.string()
        .required()
        .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers or special characters."),
    mobile: yup.string()
        .required()
        .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits."),
    email: yup.string().email().required(),
    password: yup.string().nullable().transform((o, c) => o === "" ? null : c).min(8).max(25),
    cpassword: yup.string().nullable().transform((o, c) => o === "" ? null : c).min(8).max(25).oneOf([yup.ref("password")], "Confirm password is not match."),
});

export const PlaceAdsStepTwo = yup.object().shape({
    location: yup.string().required(),
    gender: yup.string().required(),
    marital_status: yup.string().required("Please select marital status."),
    religion: yup.string().required(),
    student_working: yup.string().required("Please select Working Type"),
    industry: yup.string().required(),
    parental_status: yup.number().moreThan(0, "Please select Parential Status.").required(),
});