// import
import SignIn from "./views/Auth/signIn";
import SignUp from "./views/Auth/signUp";
import Verification from "./views/Auth/verification";
import ResetPassowrd from "./views/Auth/resetpassword";
import Watchingmovie from "./views/Viewer/watchingmovie";
import BasicPlan from "./views/Viewer/basicplan";
import Profile from "./views/Viewer/profile";
import ViewerHomePage from "./views/Viewer/viewerhomepage";
import AdvertiserProfile from "./views/Advertiser/AdvertiserProfile";
import PlaceAds from "./views/Advertiser/placeAds";
import Analytic from "./views/Advertiser/analytic";
import Blast from "./views/Advertiser/blast";
import PlaceList from "./views/Advertiser/placeadlist";
import PlaceAdsStep from "./views/Advertiser/placeadstep";
import Track from "./views/Advertiser/track";
import StreamerProfile from "./views/Streamer/streamerProfile";
import StreamerUpload from "./views/Streamer/streamerUpload";
import Videolibrary from "./views/Streamer/video-library";
import Stream from "./views/Streamer/stream";
import StreamTable from "./views/Streamer/streamtable";
import StreamVideo from "./views/Streamer/streamingvideo";
import ViewLiveStream from "./views/Viewer/ViewLiveStream";
import SavedAds from "./views/Viewer/SavedAds";
import Transaction from "./views/Viewer/Transaction";
import Staff from "./views/Streamer/Staff";
import LoginSocial from "./views/Auth/LoginSocial";
import AllNotifications from "./components/Notifications/AllNotifications";

var dashRoutes = [
    {
        path: "/signin",
        component: <SignIn />,
        layout: "/auth"
    },
    {
        path: "/signup",
        component: <SignUp />,
        layout: "/auth"
    },
    {
        path: "/social",
        component: <LoginSocial />,
        layout: "/auth"
    },
    {
        path: "/verification",
        component: <Verification />,
        layout: "/auth"
    },
    {
        path: "/resetpassword",
        component: <ResetPassowrd />,
        layout: "/auth"
    },
    {
        path: "/watch",
        component: <Watchingmovie />,
        layout: "/viewer"
    },
    {
        path: "/live",
        component: <ViewLiveStream />,
        layout: "/viewer"
    },
    {
        path: "/plan",
        component: <BasicPlan />,
        layout: "/viewer"
    },
    {
        path: "/profile",
        component: <Profile />,
        layout: "/viewer"
    },
    {
        path: "/home",
        component: <ViewerHomePage />,
        layout: "/viewer"
    },
    {
        path: "/save-ads",
        component: <SavedAds />,
        layout: "/viewer"
    },
    {
        path: "/transactions",
        component: <Transaction />,
        layout: "/viewer"
    },
    {
        path: "/notifications",
        component: <AllNotifications />,
        layout: "/viewer"
    },
    {
        path: "/notifications",
        component: <AllNotifications />,
        layout: "/adv"
    },
    {
        path: "/notifications",
        component: <AllNotifications />,
        layout: "/str"
    },
    {
        path: "/profile",
        component: <AdvertiserProfile />,
        layout: "/adv"
    },
    {
        path: "/placeads",
        component: <PlaceAds />,
        layout: "/adv"
    },
    {
        path: "/placeadstep",
        component: <PlaceAdsStep />,
        layout: "/adv"
    },
    {
        path: "/analytic",
        component: <Analytic />,
        layout: "/adv"
    },
    {
        path: "/placeadlist",
        component: <PlaceList />,
        layout: "/adv"
    },
    {
        path: "/blast",
        component: <Blast />,
        layout: "/adv"
    },
    {
        path: "/track",
        component: <Track />,
        layout: "/adv"
    },
    {
        path: "/profile",
        component: <StreamerProfile />,
        layout: "/str"
    },
    {
        path: "/upload",
        component: <StreamerUpload />,
        layout: "/str"
    },
    {
        path: "/library",
        component: <Videolibrary />,
        layout: "/str"
    },
    {
        path: "/streams",
        component: <Stream />,
        layout: "/str"
    },
    {
        path: "/stream-list",
        component: <StreamTable />,
        layout: "/str"
    },
    {
        path: "/start-stream",
        component: <StreamVideo />,
        layout: "/str"
    },
    {
        path: "/staff-list",
        component: <Staff />,
        layout: "/str"
    },
    {
        path: "/streams",
        component: <StreamTable />,
        layout: "/staff"
    },
    {
        path: "/start-stream",
        component: <StreamVideo />,
        layout: "/staff"
    }

];
export default dashRoutes;
